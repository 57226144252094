import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import Lottie from "react-lottie";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
function VariantMaster() {
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [variantName, setVariantName] = useState("");
  const [variants, setVariants] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredVariants, setFilteredVariants] = useState([]);
  const [editVariant, setEditVariant] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const variantNameInputRef = useRef(null);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    Getdata()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (searchQuery === "") {
      setFilteredVariants(variants);
    } else {
      const filtered = variants.filter((variant) =>
        variant.variant_name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredVariants(filtered);
    }
  }, [searchQuery, variants]);

  const handleCloseModal = () => {
    setShowModal(false);
    setEditModal(false);
    setEditVariant(null);
    setVariantName("");
  };
  const sortedFilter = [...filteredVariants].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };
  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleEditModal = (variant) => {
    setEditModal(true);
    setEditVariant(variant);
    setVariantName(variant.variant_name);
  };

  const Getdata = async () => {
    const data = await fetch("https://falconsquare.in/e_commerce/variantmaster.php?");
    const response = await data.json();
    setVariants(response);
    setFilteredVariants(response);
  };

  const handleInsert = async () => {
    if (!variantName.trim()) {
      swal("warning", "Please enter variant name", "warning");
      return;
    }
  
    const formData = new FormData();
    formData.append("variant_name", variantName);
  
    fetch("https://falconsquare.in/e_commerce/variantmaster.php?", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((data) => {
        console.log(data);
        Getdata(); // Refresh the variants after inserting
        // Clear the fields
        setVariantName("");
        setShowModal(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  
  
  const handleUpdate = async () => {
    try {
      const data = {
        id: editVariant.id,
        variant_name: variantName,
      };

      const response = await fetch(
        `https://falconsquare.in/e_commerce/variantmaster.php?id=${editVariant.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.status === 200) {
        console.log("Variant updated successfully!");

        handleCloseModal();
        Getdata();
      } else {
        console.error("Failed to update variant");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  
  useEffect(() => {
    if (showModal) {
      variantNameInputRef.current.focus();
    }
  }, [showModal]);

  const handleDelete = async (id) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this variant!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });

      if (shouldDelete) {
        const response = await fetch(
          `https://falconsquare.in/e_commerce/variantmaster.php?id=${id}`,
          {
            method: "DELETE",
          }
        );

        if (response.status === 200) {
          console.log("Variant deleted successfully!");
          Getdata(); // Refresh the variants after deleting
        } else {
          console.error("Failed to delete variant");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="main-container mt-3">
      <div className=" ">
        <div className="flex-container pt-5">
          <h3 className="head-fon-mv"> Variant Master</h3>
        </div>

        <div className="card ">
          <div className="cat-left">
            <h4>Variant Master</h4>
            <button className="add-category-button" onClick={handleShowModal}>
              Add Variant Master
            </button>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="row">
              <div className="d-flex align-items-center">
                <label className="me-2">Show</label>
                <select
                  className="form-select me-2"
                  value={itemsPerPage}
                  onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
                >
                  <option value="20">20</option>
                  <option value="40">40</option>
                  <option value="60">60</option>
                  <option value="80">80</option>
                </select>
                <label>entries</label>
              </div>
            </div>
            <div className="search-left">
              <div className="searchbar">
                <input
                  type="text"
                  className="search"
                  placeholder="Search"
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <FaSearch className="search-icon" />
              </div>
            </div>
          </div>

          <div className="table-container text-end mt-3">
            {loading ? (
              <div>
                <Lottie
                  height={250}
                  width={250}
                  options={{
                    loop: true,
                    animationData: require("../Assets/Animation - 1716197548567.json"),
                    autoplay: true,
                  }}
                />
              </div>
            ) : (
              <table id="table-to-xls" className="text-center">
                <thead>
                  <tr>
                    <th style={{ width: "4%" }}>S.No</th>
                    <th>Variant Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPageData.map((variant, index) => (
                    <tr key={variant.id}>
                     <td>{startIndex + index + 1}</td>
                      <td>{variant.variant_name}</td>
                      <td>
                        <button
                          className="btn-edit"
                          onClick={() => handleEditModal(variant)}
                        >
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </button>
                        <button
                          className="btn-delete"
                          onClick={() => handleDelete(variant.id)}
                        >
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div
          className="dataTables_paginate paging_simple_numbers p-2"
          id="dtBasicExample_paginate"
        >
          <ul className="pagination">
            <li
              className={`paginate_button page-item ${
                currentPage === 1 ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage > 1 ? prevPage - 1 : prevPage
                  )
                }
                className="page-link"
              >
                Previous
              </button>
            </li>
            {generatePaginationNumbers().map((page, index) => (
              <li
                key={index}
                className={`paginate_button page-item ${
                  page === currentPage ? "active" : ""
                }`}
              >
                <button
                  onClick={() => setCurrentPage(page)}
                  className="page-link"
                >
                  {page}
                </button>
              </li>
            ))}
            <li
              className={`paginate_button page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage < totalPages ? prevPage + 1 : prevPage
                  )
                }
                className="page-link"
              >
                Next
              </button>
            </li>
          </ul>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <header className="modal-header">
          <h5 className="modal-title">Add Variant Master</h5>
          <button
            type="button"
            aria-label="Close"
            className="close"
            onClick={handleCloseModal}
          >
            ×
          </button>
        </header>

        <Modal.Body>
          <div>
            <label>Variant Name</label>
            <input
              className="form-control"
              placeholder="Enter Variant Name"
              value={variantName}
              ref={variantNameInputRef}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (/^[a-zA-Z\s]*$/.test(inputValue)) {
                  setVariantName(e.target.value);
                }
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleInsert}>
            Save
          </Button>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={editModal} onHide={handleCloseModal}>
        <header className="modal-header">
          <h5 className="modal-title">Edit Variant</h5>
          <button
            type="button"
            aria-label="Close"
            className="close"
            onClick={handleCloseModal}
          >
            ×
          </button>
        </header>
        <Modal.Body>
          <div>
            <label>Variant Name</label>
            <input
              className="form-control"
              value={variantName}
              onChange={(e) => setVariantName(e.target.value)}
              placeholder="Enter Variant Name"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleUpdate}>
            Update
          </Button>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default VariantMaster;
