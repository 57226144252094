import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Addproduct.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { IoAdd } from "react-icons/io5";
import { Button } from "react-bootstrap";
import axios from "axios";
import { event } from "jquery";

function Addproduct() {
  const Navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("limited");
  const [categoriesload, setCategoriesload] = useState([]);
  const [brandload, setBrandload] = useState([]);
  const [unitload, setunitload] = useState([]);
  const [options, setOptions] = useState([]);
  const [processorspeed, setProcessorspeed] = useState([]);
  const [gps, setgps] = useState([]);
  const [cameraresolution, setCameraresolution] = useState([]);
  const [phoneresolution, setPhoneresolution] = useState([]);
  const [refreshrate, setRefreshrate] = useState([]);
  const [ramsize, setRamsize] = useState([]);
  const [sensorresolution, setSensorresolution] = useState([]);
  const [cellulartechnology, setCellulartechnology] = useState([]);
  const [simcardsize, setSimcardsize] = useState([]);
  const [shooingmode, setShootingmood] = useState([]);
  const [os, setOs] = useState([]);
  const [interfaceinput, setInterfaseinput] = useState([]);
  const [cellularphone, setCellularphone] = useState([]);
  const [videocapture, setVideocapture] = useState([]);
  const [itemweight, setItemweight] = useState([]);
  const [includedcomponents, setIncludedcomponents] = useState([]);
  const [biometricsecurityfeature, setBiometricsecurityfeature] = useState([]);
  const [connectortype, setConnectortype] = useState([]);
  const [screensize, setScreensize] = useState([]);
  const [simcardslotcount, setSimcardslotcount] = useState([]);
  const [devicefeatures, setDevicefeatures] = useState([]);
  const [displaytype, setDisplaytype] = useState([]);
  const [connectivitytechnology, setConnectivitytechnology] = useState([]);
  const [madeinload, setmadeinload] = useState([]);
  const [proinfo, setproinfo] = useState(false);
  const [variantions, setVariantions] = useState([]);
  const [sizeOptions, setSizeOptions] = useState([
    "Medium",
    "Large",
    "Small",
    "Oversize",
  ]);

  const [colorOptions, setColorOptions] = useState([
    "Red",
    "Blue",
    "Yellow",
    "White",
  ]);
  const [categories, setCategories] = useState([]);
  const [productName, setProductName] = useState("");
  const [tax, setTax] = useState("");
  const [productType, setProductType] = useState("");
  const [madeIn, setMadeIn] = useState("");
  const [tags, setTags] = useState("");
  const [brand, setBrand] = useState("");
  const [price, setPrice] = useState("");
  const [productStock, setProductStock] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [productStatus, setProductStatus] = useState("Approved");
  const [isReturnable, setIsReturnable] = useState(true);
  const [isCancelable, setIsCancelable] = useState(true);
  const [isCODAllowed, setIsCODAllowed] = useState(true);
  const [totalAllowedQuantity, setTotalAllowedQuantity] = useState("");
  const [productInfo, setProductInfo] = useState({
    name: "",
    value: "",
  });
  const [description, setDescription] = useState("");
  const [productFeatures, setProductFeatures] = useState("");
  const [mainImage, setMainImage] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedcellstorage, setSelectedcellstorage] = useState(null);
  const [selectedprocessorspeed, setSelectedprocessorspeed] = useState(null);
  const [selectedgps, setSelectedgps] = useState(null);
  const [selectedcameraresolution, setSelectedcameraresolution] =
    useState(null);
  const [selectedphoneresolution, setSelectedphoneresolution] = useState(null);
  const [selectedrefreshrate, setSelectedrefreshrate] = useState(null);
  const [selectedramsize, setSelectedramsize] = useState(null);
  const [selectedsensorresolution, setSelectedsensorresolution] =
    useState(null);
  const [selectedshootingmodes, setSelectedshootingmodes] = useState(null);
  const [selectedcellulartechnology, setSelectedcellulartechnology] =
    useState(null);
  const [selectedsimcardsize, setSelectedsimcardsize] = useState(null);
  const [selectedoperatingsystem, setSelectedoperatingsystem] = useState(null);
  const [selectedphonehumaninterface, setSelectedphonehumaninterface] =
    useState(null);
  const [selectedphotosensorresolution, setSelectedphotosensorresolution] =
    useState(null);
  const [selectedvideocapture, setSelectedvideocapture] = useState(null);
  const [selecteditemweight, setSelecteditemweight] = useState(null);
  const [selectedincludedcomponents, setSelectedincludedcomponentst] =
    useState(null);

  const [selectedbiometricsecurity, setSelectedbiometricsecurity] =
    useState(null);
  const [selectedconnectortype, setSelectedconnectortype] = useState(null);
  const [selectedconnectivitytechnology, setSelectedconnectivitytechnology] =
    useState(null);
  const [selectedscreensize, setSelectedscreensize] = useState(null);
  const [selecteddisplaytype, setSelecteddisplaytype] = useState(null);
  const [selecteddevicefeatures, setSelecteddevicefeatures] = useState(null);
  const [selectedsimcardslotcount, setSelectedsimcardslotcount] = useState(null);
  const [variants, setVariants] = useState([]);
  const [variantValues, setVariantValues] = useState({});
  const [tableData, setTableData] = useState([]);
  const [taxOptions, setTaxOptions] = useState([]);
  const [showVariations, setShowVariations] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState("");
  const [variantOptions, setVariantOptions] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [variantType, setVariantType] = useState("");
  const [variantSize, setVariantSize] = useState("");
  const [variantImage, setVariantImage] = useState("");
  const [variantStock, setVariantStock] = useState("");
  const [variantStatus, setVariantStatus] = useState("");
  const [offerStatus, setOfferStatus] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState("");
  const [instockText, setInstockText] = useState("");
  const [variantStar, setVariantStar] = useState("");
  const [reviewCount, setReviewCount] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [productId, setProductId] = useState("");
  const [colour, setColour] = useState("");
  const [storage, setStorage] = useState("");
  const [product, setProduct] = useState(false);
  const [varientId, setVarientId] = useState("");
  const [varprice, setVarPrice] = useState("");
  const [varientImages, setVarientImages] = useState([]);
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      variantType,
      variantSize,
      variantImage,
      variantStock,
      variantStatus,
      offerStatus,
      discountedPrice,
      price,
      instockText,
      variantStar,
      reviewCount,
      createdAt,
    };
  };
  const handleImageUpload = (e) => {
    setVarientImages(e.target.files);
  };
  const handleRadioChange = (event) => {
    setShowVariations(event.target.value === "yes");
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const addVariant = () => {
    setVariants([...variants, {}]);
  };

  const handleRemoveVariant = (indexToRemove) => {
    setVariants((prevVariants) =>
      prevVariants.filter((_, index) => index !== indexToRemove)
    );
  };
  useEffect(() => {
    Dataget();
    Brandget();
    Unitget();
    Madeinget();
  }, []);
  const Dataget = async () => {
    const data = await fetch(
      "https://falconsquare.in/e_commerce/categoriesmaster.php?"
    );
    const response = await data.json();
    setCategoriesload(response);
  };

  const Unitget = async () => {
    const data = await fetch("https://falconsquare.in/e_commerce/addunit.php?");
    const response = await data.json();
    setunitload(response);
  };
  const Brandget = async () => {
    const data = await fetch("https://falconsquare.in/e_commerce/brand.php?");
    const response = await data.json();
    setBrandload(response);
  };
  const Madeinget = async () => {
    const data = await fetch(
      "https://restcountries.com/v3.1/independent?status=true"
    );
    const response = await data.json();
    setmadeinload(response);
  };
  const handleInfo = () => {
    setproinfo(!proinfo);
  };

  useEffect(() => {
    fetch("https://falconsquare.in/e_commerce/addtax.php")
      .then((response) => response.json())
      .then((data) => {
        setTaxOptions(data);
      })
      .catch((error) => {
        console.error("Error fetching tax options:", error);
      });
  }, []);
  useEffect(() => {
    fetchCatData();
  }, []);

  const fetchOptions = async () => {
    try {
      const response = await axios.get(
        "https://falconsquare.in/e_commerce/cellularmemorystoragecapacity.php"
      );
      setOptions(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchProcessorspeed = async () => {
    try {
      const response = await axios.get(
        "https://falconsquare.in/e_commerce/processorspeed.php"
      );
      setProcessorspeed(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchgps = async () => {
    try {
      const response = await axios.get("https://falconsquare.in/e_commerce/gps.php");
      setgps(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchcameraresolution = async () => {
    try {
      const response = await axios.get(
        "https://falconsquare.in/e_commerce/cameraresolution.php"
      );
      setCameraresolution(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchphoneresolution = async () => {
    try {
      const response = await axios.get(
        "https://falconsquare.in/e_commerce/phoneresolution.php"
      );
      setPhoneresolution(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchrefreshrate = async () => {
    try {
      const response = await axios.get(
        "https://falconsquare.in/e_commerce/refreshrate.php"
      );
      setRefreshrate(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchramsize = async () => {
    try {
      const response = await axios.get(
        "https://falconsquare.in/e_commerce/ramsize.php"
      );
      setRamsize(response.data);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const fetchsensorresolution = async () => {
    try {
      const response = await axios.get(
        "https://falconsquare.in/e_commerce/sensorresolution.php"
      );
      setSensorresolution(response.data);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const fetchshootingmood = async () => {
    try {
      const response = await axios.get(
        "https://falconsquare.in/e_commerce/shootingmodes.php"
      );
      setShootingmood(response.data);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const fetchcellulartechnology = async () => {
    try {
      const response = await axios.get(
        "https://falconsquare.in/e_commerce/Cellulartechnology.php"
      );
      setCellulartechnology(response.data);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const fetchsimcardsize = async () => {
    try {
      const response = await axios.get(
        "https://falconsquare.in/e_commerce/simcard.php"
      );
      setSimcardsize(response.data);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const fetchos = async () => {
    try {
      const response = await axios.get("https://falconsquare.in/e_commerce/os.php");
      setOs(response.data);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const fetchinterfaceinput = async () => {
    try {
      const response = await axios.get(
        "https://falconsquare.in/e_commerce/interfaceinput.php"
      );
      setInterfaseinput(response.data);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const fetchcellularphone = async () => {
    try {
      const response = await axios.get(
        "https://falconsquare.in/e_commerce/cellularphone.php"
      );
      setCellularphone(response.data);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const fetchvideocapture = async () => {
    try {
      const response = await axios.get(
        "https://falconsquare.in/e_commerce/videocapture.php"
      );
      setVideocapture(response.data);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const fetchitemweight = async () => {
    try {
      const response = await axios.get(
        "https://falconsquare.in/e_commerce/itemweight.php"
      );
      setItemweight(response.data);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const fetchincludedcomponents = async () => {
    try {
      const response = await axios.get(
        "https://falconsquare.in/e_commerce/includedcomponents.php"
      );
      setIncludedcomponents(response.data);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const fetchbiometricsecurityfeature = async () => {
    try {
      const response = await axios.get(
        "https://falconsquare.in/e_commerce/biometric_securityfeature.php"
      );
      setBiometricsecurityfeature(response.data);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const fetchconnectortypes = async () => {
    try {
      const response = await axios.get(
        "https://falconsquare.in/e_commerce/connectortype.php"
      );
      setConnectortype(response.data);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const fetchscreensize = async () => {
    try {
      const response = await axios.get(
        "https://falconsquare.in/e_commerce/screensize.php"
      );
      setScreensize(response.data);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const fetchconnectivitytechnology = async () => {
    try {
      const response = await axios.get(
        "https://falconsquare.in/e_commerce/connectivitytechnology.php"
      );
      setConnectivitytechnology(response.data);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const fetchdisplaytype = async () => {
    try {
      const response = await axios.get(
        "https://falconsquare.in/e_commerce/displaytype.php"
      );
      setDisplaytype(response.data);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const fetchdevicefeatures = async () => {
    try {
      const response = await axios.get(
        "https://falconsquare.in/e_commerce/device_features.php"
      );
      setDevicefeatures(response.data);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const fetchsimcardslotcount = async () => {
    try {
      const response = await axios.get(
        "https://falconsquare.in/e_commerce/simcard_slotcount.php"
      );
      setSimcardslotcount(response.data);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchgps();
    fetchramsize();
    fetchphoneresolution();
    fetchcameraresolution();
    fetchProcessorspeed();
    fetchshootingmood();
    fetchOptions();
    fetchsimcardsize();
    fetchrefreshrate();
    fetchcellulartechnology();
    fetchsensorresolution();
    fetchinterfaceinput();
    fetchos();
    fetchcellularphone();
    fetchvideocapture();
    fetchitemweight();
    fetchconnectivitytechnology();
    fetchincludedcomponents();
    fetchbiometricsecurityfeature();
    fetchconnectortypes();
    fetchscreensize();
    fetchdisplaytype();
    fetchdevicefeatures();
    fetchsimcardslotcount();
  }, []);

  const fetchCatData = async () => {
    try {
      const response = await fetch(
        "https://falconsquare.in/e_commerce/categoriesmaster.php"
      );
      const data = await response.json();
      setCategories(data);

      setSelectedVariant(data);
    } catch (error) {
      console.error("Error", error);
    }
  };

  const handleCategoryChange = (e) => {
    const categoryId = e.target.value;
    setSelectedCategory(categoryId);
    const selectedCategory = categories.find(
      (category) => category.id === categoryId
    );

    fetch(
      `https://falconsquare.in/e_commerce/categories.php?parent_category=${categoryId}`
    )
      .then((response) => response.json())
      .then((data) => {
        setSubCategories(data);
      })
      .catch((error) => {
        console.error("Error fetching sub-categories:", error);
      });

    const categoryVariants = selectedCategory?.variants.split(",");
    setVariants(categoryVariants || []);

    // Resetting variantValues when category changes
    setVariantValues({});
    setSelectedCategory(categoryId);

    categoryVariants.forEach((variant, index) => {
      const [cl, sz] = variant.split(",");
      console.log("cl", cl); // Corrected to use `cl` instead of `variant` in the log message

      fetch(`https://falconsquare.in/e_commerce/${cl}_master.php`)
        .then((response) => response.json())
        .then((data) => {
          // console.log("Fetched Data for cl:", data);
          setVariantOptions((prevState) => ({
            ...prevState,
            [`${cl}`]: data, // Use a unique key for each variant
          }));
        })
        .catch((error) => {
          console.error(`Error fetching ${cl} options:`, error);
        });

      fetch(`https://falconsquare.in/e_commerce/${sz}_master.php`)
        .then((response) => response.json())
        .then((data) => {
          console.log("Fetched Data for sz:", data);
          setVariantOptions((prevState) => ({
            ...prevState,
            [`${sz}`]: data, // Use a unique key for each variant
          }));
        })
        .catch((error) => {
          console.error(`Error fetching ${sz} options:`, error);
        });
    });
  };

  const handleSubCategoryChange = (e) => {
    setSelectedSubCategory(e.target.value);
  };

  const handleVariantChange = (variantName, value) => {
    console.log(variantName);
    console.log(value);
    if (variantName == "colour" && value == 1) {
      setColour("red");
    } else if (variantName == "colour" && value == 2) {
      setColour("White");
    } else if (variantName == "size" && value == 1) {
      setStorage("6gb");
    } else if (variantName == "size" && value == 2) {
      setStorage("red");
    }

    setSelectedVariant((prevState) => ({
      ...prevState,
      [variantName]: value,
    }));

    // console.log("Updated selectedVariant:", selectedVariant);
    console.log(colour);
    console.log(storage);
  };

  const handleDescriptionChange = (content) => {
    const strippedContent = content.replace(/<\/?p>/g, "");
    setDescription(strippedContent);
  };

  const handleProductFeaturesChange = (content) => {
    setProductFeatures(content);
  };

  const handleMainImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setMainImage(file.name);
    } else {
      setMainImage("");
    }
  };

  const handleInputInfoChange = (e) => {
    const { name, value } = e.target;
    setProductInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handleRadioChangeCod = (e) => {
    setIsCODAllowed(e.target.value === "Yes");
  };
  const handleRadioChangeCan = (e) => {
    setIsCancelable(e.target.value === "Yes");
  };
  const handleRadioChangeRe = (e) => {
    setIsReturnable(e.target.value === "Yes");
  };
  const handleInputChange = (event) => {
    setTotalAllowedQuantity(event.target.value);
  };

  const handleRadioChangeapp = (e) => {
    setIsReturnable(e.target.value === "Approved");
  };
  const handleProductStatusChange = (event) => {
    setProductStatus(event.target.value);
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
    // console.log(event.target.value)
  };
  const handleInputInfoChangecell = (e) => {
    const cell = e.target.value;
    setSelectedcellstorage(cell);
  };
  const handleInputInfoChangeprocessorspeed = (e) => {
    const cell = e.target.value;
    setSelectedprocessorspeed(cell);
  };
  const handleInputInfoChangegps = (e) => {
    const cell = e.target.value;
    setSelectedgps(cell);
  };

  const handleInputInfoChangecameraresolution = (e) => {
    const cell = e.target.value;
    setSelectedcameraresolution(cell);
  };

  const handleInputInfoChangephoneresolution = (e) => {
    const cell = e.target.value;
    setSelectedphoneresolution(cell);
  };

  const handleInputInfoChangerefreshrate = (e) => {
    const cell = e.target.value;
    setSelectedrefreshrate(cell);
  };
  const handleInputInfoChangeramsize = (e) => {
    const cell = e.target.value;
    setSelectedramsize(cell);
  };
  const handleInputInfoChangesensorresolution = (e) => {
    const cell = e.target.value;
    setSelectedsensorresolution(cell);
  };
  const handleInputInfoChangeshootingmodes = (e) => {
    const cell = e.target.value;
    setSelectedshootingmodes(cell);
  };
  const handleInputInfoChangecellulartechnology = (e) => {
    const cell = e.target.value;
    setSelectedcellulartechnology(cell);
  };

  const handleInputInfoChangesimcardsize = (e) => {
    const cell = e.target.value;
    setSelectedsimcardsize(cell);
  };

  const handleInputInfoChangeoperatingsystem = (e) => {
    const cell = e.target.value;
    setSelectedoperatingsystem(cell);
  };
  const handleInputInfoChangephonehumaninterface = (e) => {
    const cell = e.target.value;
    setSelectedphonehumaninterface(cell);
  };
  const handleInputInfoChangephotosensorresolution = (e) => {
    const cell = e.target.value;
    setSelectedphotosensorresolution(cell);
  };

  const handleInputInfoChangevideocapture = (e) => {
    const cell = e.target.value;
    setSelectedvideocapture(cell);
  };

  const handleInputInfoChangeitemweight = (e) => {
    const cell = e.target.value;
    setSelecteditemweight(cell);
  };
  const handleInputInfoChangeincludedcomponents = (e) => {
    const cell = e.target.value;
    setSelectedincludedcomponentst(cell);
  };

  const handleInputInfoChangebiometricsecurity = (e) => {
    const cell = e.target.value;
    setSelectedbiometricsecurity(cell);
  };
  const handleInputInfoChangeconnectortype = (e) => {
    const cell = e.target.value;
    setSelectedconnectortype(cell);
  };

  const handleInputInfoChangeconnectivitytechnologye = (e) => {
    const cell = e.target.value;
    setSelectedconnectivitytechnology(cell);
  };
  const handleInputInfoChangescreensize = (e) => {
    const cell = e.target.value;
    setSelectedscreensize(cell);
  };
  const handleInputInfoChangedisplaytypee = (e) => {
    const cell = e.target.value;
    setSelecteddisplaytype(cell);
  };

  const handleInputInfoChangedevicefeatures = (e) => {
    const cell = e.target.value;
    setSelecteddevicefeatures(cell);
  };

  const handleInputInfoChangesimcardslotcount = (e) => {
    const cell = e.target.value;
    setSelectedsimcardslotcount(cell);
  };


  function generateRandomProductCode(productName) {
    const randomNumbers = Math.floor(Math.random() * 1000);
    const productCode = `${productName.toUpperCase()}${randomNumbers}`;
    return productCode;
  }

  const productCode = generateRandomProductCode(productName);
  const handleInsertProduct = () => {
    const formData = new FormData();
    formData.append("product_type", productType);
    formData.append("product_name", productName);
    formData.append("product_code", productCode);
    formData.append("tags", tags);
    formData.append("pro_price", price);
    formData.append("brand", brand);
    formData.append("description", description);
    formData.append("made_in", selectedCountry);
    formData.append("category", selectedCategory);
    formData.append("category_id", 3);
    formData.append("tax", tax);
    formData.append("product_information", JSON.stringify(productInfo));
    formData.append("product_image", mainImage);
    formData.append("returnable", isReturnable ? "Yes" : "No");
    formData.append("cancelable", isCancelable ? "Yes" : "No");
    formData.append("cod", isCODAllowed ? "Yes" : "No");
    formData.append("storage_capacity", selectedcellstorage);
    formData.append("processor_speed", selectedprocessorspeed);
    formData.append("gps", selectedgps);
    formData.append("phoneresolution", selectedphoneresolution);
    formData.append("refreshrate", selectedrefreshrate);
    formData.append("ramsize", selectedramsize);
    formData.append("sensorresolution", selectedsensorresolution);
    formData.append("shootingmodes", selectedshootingmodes);
    formData.append("cellulartechnology", selectedcellulartechnology);
    formData.append("simcardsize", selectedsimcardsize);
    formData.append("operatingsystem", selectedoperatingsystem);
    formData.append("phonehumaninterface", selectedphonehumaninterface);
    formData.append("photosensorresolution", selectedphotosensorresolution);
    formData.append("videocapture", selectedvideocapture);
    formData.append("itemweight", selecteditemweight);
    formData.append("includedcomponents", selectedincludedcomponents);
    formData.append("biometricsecurity", selectedbiometricsecurity);
    formData.append("connectortype", selectedconnectortype);
    formData.append("connectivitytechnology", selectedconnectivitytechnology);
    formData.append("screensize", selectedscreensize);
    formData.append("displaytype", selecteddisplaytype);
    formData.append("devicefeatures", selecteddevicefeatures);
    formData.append("simcardslotcount", selectedsimcardslotcount);
    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }

    fetch("https://falconsquare.in/e_commerce/product.php", {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("Failed to insert");
        }
      })
      .then((data) => {
        console.log("Response:", data);
        const lastInsertedId = data.last_id; // Get the last inserted ID from the response
        alert("Inserted successfully");
        setProductId(lastInsertedId);
        setProduct(true);
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("An error occurred while sending data: " + error.message);
      });
  };

  const handleVariantInsert = async () => {
    // try {
    //   const newRow = {
    //     variantStock,
    //     varprice,
    //   };

    //   // Loop through variants to get other selected values
    //   variants.forEach((variant) => {
    //     const selectedOption = variantOptions[variant]?.find(
    //       option => option.id === selectedVariant[variant]
    //     );
    //     newRow[variant] = selectedOption ? selectedOption[variant] : "";
    //   });

    //   // Update table data state
    //   setTableData(prevData => [...prevData, newRow]);
    try {
      const newRow = {
        variantStock,
        varprice,
        images: [], // Initialize an array to store image URLs
      };

      // Loop through variants to get other selected values
      variants.forEach((variant) => {
        const selectedOption = variantOptions[variant]?.find(
          (option) => option.id === selectedVariant[variant]
        );
        newRow[variant] = selectedOption ? selectedOption[variant] : "";
      });

      // Loop through uploaded images and push their URLs into the images array
      Array.from(varientImages).forEach((imageFile) => {
        const imageUrl = URL.createObjectURL(imageFile);
        newRow.images.push(imageUrl);
      });

      // Update table data state
      setTableData((prevData) => [...prevData, newRow]);

      // Clear all the input fields
      setVariantStock("");
      setVarPrice("");
      setVarientImages([]);
      setSelectedVariant({});

      const formData = new FormData();

      formData.append("product_id", productId);
      formData.append("category_id", selectedCategory);
      formData.append("variant_type", variantType || "defaultType");
      formData.append("variant_size", variantSize || "3");
      const fileNames = [];

      for (let i = 0; i < varientImages.length; i++) {
        fileNames.push(varientImages[i]);
      }
      const fileNamesString = fileNames.join(",");
      console.log({varientImages:varientImages});
      formData.append("variant_image", varientImages || "default.jpg");

      formData.append("variant_stock", variantStock);
      formData.append("variant_status", variantStatus || "Active");
      formData.append("offer_status", offerStatus || "No Offer");
      formData.append("discount_price", discountedPrice || 0);
      formData.append("colour", colour || "Not Specified");
      formData.append("storage", storage || "24GB");
      formData.append("price", varprice);
      formData.append("instock_text", instockText || "In Stock");
      formData.append("variant_star", variantStar || 0);
      formData.append("review_count", reviewCount || 0);
      console.log(fileNames);
      const response = await fetch(
        "https://falconsquare.in/e_commerce/variant.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(
          `Failed to insert variant: ${response.status} - ${response.statusText}`
        );
      }

      const data = await response.json();

      if (data.status === "success") {
        // Variant added successfully
        setVarientId(data.last_id);
        console.log("Variant added successfully");
        console.log("Last inserted ID:", data.last_id);
      } else {
        // Failed to add variant
        console.error("Failed to add variant:", data.message);
        // Handle the error, show message to the user, etc.
      }
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
    }
  };

  const handleInsert = async () => {
    try {
      await handleVariantInsert();
      await stockInsert();
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const stockInsert = async () => {
    const formData = new FormData();
    const id = productName.slice(0, 4) + productId + colour;

    formData.append("product_id", productId);
    formData.append("product_name", productName);
    formData.append("variant_id", id);
    formData.append("variant_stock", variantStock);
    formData.append("price", varprice);

    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }

    try {
      const response = await fetch(
        "https://falconsquare.in/e_commerce/stock.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(
          `Failed to insert stock: ${response.status} - ${response.statusText}`
        );
      }

      const data = await response.json();

      if (data.status === "success") {
        console.log("Stock inserted successfully");
      } else {
        console.error("Failed to insert stock:", data.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleAddClick = () => {
    handleInsert();
  };
  const handleAllDetails = () => {
    setProductName("");
    setTags("");
    setPrice("");
    setBrand("");
    setDescription("");
    setMadeIn("");
    setTax("");
    setMainImage(null);
    setIsReturnable(true);
    setIsCancelable(true);
    setIsCODAllowed(true);
    setProductFeatures("");
    setTags("");
    setProductType("");
    setSelectedCountry("");
    setSelectedCategory("");
    setSelectedSubCategory("");
    setBrand(null);
    setProductStock("");
    setProductStatus("");
    setMainImage("");
    setTableData([]);
    // setBrandload([]);
    setTotalAllowedQuantity("");
    setSubCategories("");
    setProduct(false);
    // subCategories([]);
    setProductInfo({
      name: "",
      value: "",
    });
  };

  return (
    <div className="main-container mt-3">
      <div className=" ">
        <div className="flex-container pt-5">
          <h3 className="head-fon-mv">Add Product</h3>
          <div class="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              class="breadcrumb-header float-start float-lg-end"
            >
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a onClick={() => Navigate("/dashboard")}>Dashboard</a>
                </li>{" "}
                <li class="breadcrumb-item">
                  <a onClick={() => Navigate("/manageproduct")}>
                    Manage Products
                  </a>
                </li>{" "}
                <li aria-current="page" class="breadcrumb-item active">
                  Create Product
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div className="card ">
          <div className="cat-left">
            <h4>Create Product</h4>
          </div>
          <div className="form-container">
            <div className="form-column">
              <label>
                Product Name <i className="text-danger">*</i>
              </label>
              <input
                className="form-control"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
              />
              <label>Tax</label>
              <select
                className="form-select"
                value={tax}
                onChange={(e) => setTax(e.target.value)}
              >
                <option value="">Select Tax</option>
                {taxOptions.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.percentage}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-column">
              <label>
                Product Type <i className="text-danger">*</i>
              </label>
              <input
                className="form-control"
                value={productType}
                onChange={(e) => setProductType(e.target.value)}
              />
              {/* <select className="form-select" /> */}
              <label>Made In</label>
              <select
                className="form-select"
                value={selectedCountry}
                onChange={handleCountryChange}
              >
                <option value="">Select Country</option>
                {madeinload &&
                  madeinload.map(
                    (madein) =>
                      madein &&
                      madein.name &&
                      madein.name.common && (
                        <option key={madein.id} value={madein.name.common}>
                          {madein.name.common}
                        </option>
                      )
                  )}
              </select>
            </div>
          </div>
          <div className="form-container">
            <div className="form-column">
              <label>
                Tags<i className="text-danger">*</i>
              </label>
              <input
                className="form-control"
                value={tags}
                onChange={(e) => setTags(e.target.value)}
              />
              <label>Brand</label>
              <select
                className="form-select"
                onChange={(e) => setBrand(e.target.value)}
              >
                <option>Select Brand Name</option>
                {brandload &&
                  brandload.map((brand) => (
                    <option key={brand.id} value={brand.id}>
                      {brand.brand_name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="form-column">
              <label>Price</label>
              <input
                type="number"
                className="form-control"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />

              <label>Category</label>
              <select
                className="form-select"
                value={selectedCategory}
                onChange={handleCategoryChange}
              >
                <option value="">Select Category Name</option>
                {categories &&
                  categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.category_name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="form-new" style={{ display: "flex", gap: "10px" }}>
            <div style={{ flex: 1 }}>
              <label>Sub Category</label>
              <select
                className="form-select"
                value={selectedSubCategory}
                onChange={handleSubCategoryChange}
              >
                <option value="">Select Sub Category</option>
                {subCategories &&
                  subCategories.map((subCategory) => (
                    <option key={subCategory.id} value={subCategory.id}>
                      {subCategory.category_name}
                    </option>
                  ))}
              </select>
            </div>
            <div style={{ flex: 1 }}>
              <label>Product Stock</label>
              <input
                type="number"
                className="form-control"
                value={productStock}
                onChange={(e) => setProductStock(e.target.value)}
              />
            </div>
          </div>

          <div className="form-container">
            <div className="form-column">
              <div className="input-group d-flex justify-content-between">
                <label>
                  Is Returnable?
                  <div
                    role="radiogroup"
                    tabIndex="-1"
                    className="btn-group-toggle btn-group bv-no-focus-ring"
                  >
                    <label
                      className={`btn btn-outline-primary ${
                        isReturnable ? "active" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        className=""
                        value="Yes"
                        checked={isReturnable}
                        onChange={(e) =>
                          handleRadioChangeRe(e, setIsReturnable)
                        }
                      />
                      <span>Yes</span>
                    </label>
                    <label
                      className={`btn btn-outline-primary ${
                        !isReturnable ? "active" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        className=""
                        value="No"
                        checked={!isReturnable}
                        onChange={(e) =>
                          handleRadioChangeRe(e, setIsReturnable)
                        }
                      />
                      <span>No</span>
                    </label>
                  </div>
                </label>
                <label>
                  Is Cancelable?
                  <div
                    role="radiogroup"
                    tabIndex="-1"
                    className="btn-group-toggle btn-group bv-no-focus-ring"
                  >
                    <label
                      className={`btn btn-outline-primary ${
                        isCancelable ? "active" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        className=""
                        value="Yes"
                        checked={isCancelable}
                        onChange={(e) =>
                          handleRadioChangeCan(e, setIsCancelable)
                        }
                      />
                      <span>Yes</span>
                    </label>
                    <label
                      className={`btn btn-outline-primary ${
                        !isCancelable ? "active" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        className=""
                        value="No"
                        checked={!isCancelable}
                        onChange={(e) =>
                          handleRadioChangeCan(e, setIsCancelable)
                        }
                      />
                      <span>No</span>
                    </label>
                  </div>
                </label>
                <label>
                  Is COD Allowed?
                  <div
                    role="radiogroup"
                    tabIndex="-1"
                    className="btn-group-toggle btn-group bv-no-focus-ring"
                  >
                    <label
                      className={`btn btn-outline-primary ${
                        isCODAllowed ? "active" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        className=""
                        value="Yes"
                        checked={isCODAllowed}
                        onChange={(e) =>
                          handleRadioChangeCod(e, setIsCODAllowed)
                        }
                      />
                      <span>Yes</span>
                    </label>
                    <label
                      className={`btn btn-outline-primary ${
                        !isCODAllowed ? "active" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        className=""
                        value="No"
                        checked={!isCODAllowed}
                        onChange={(e) =>
                          handleRadioChangeCod(e, setIsCODAllowed)
                        }
                      />
                      <span>No</span>
                    </label>
                  </div>
                </label>
                <label>
                  Total Allowed Quantity
                  <input
                    type="number"
                    className="form-control"
                    value={totalAllowedQuantity}
                    onChange={handleInputChange}
                  />
                </label>
                <label>
                  Product Status
                  <div
                    role="radiogroup"
                    tabIndex="-1"
                    className="btn-group-toggle btn-group bv-no-focus-ring"
                  >
                    <label
                      className={`btn btn-outline-primary ${
                        productStatus === "Approved" ? "active" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        className=""
                        value="Approved"
                        checked={productStatus === "Approved"}
                        onChange={(e) =>
                          handleRadioChangeapp(e, setProductStatus)
                        }
                      />
                      <span>Approved</span>
                    </label>
                    <label
                      className={`btn btn-outline-primary ${
                        productStatus === "Not-Approved" ? "active" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        className=""
                        value="Not-Approved"
                        checked={productStatus === "Not-Approved"}
                        onChange={(e) => setProductStatus(e.target.value)}
                      />
                      <span>Not Approved</span>
                    </label>
                  </div>
                </label>
              </div>

              <div className="input-group"></div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-4 d-flex flex-column">
                  <label>Cellular Memory Storage Capacity</label>
                  <select
                    className="form-select"
                    name="value"
                    value={selectedcellstorage}
                    onChange={handleInputInfoChangecell}
                  >
                    <option value="">
                      Select Cellular Memory Storage Capacity
                    </option>
                    {options.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.storagecapacity}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <label>processor Speed</label>
                  <select
                    className="form-select"
                    name="value"
                    value={selectedprocessorspeed}
                    onChange={handleInputInfoChangeprocessorspeed}
                  >
                    <option value="">Select processor Speed</option>
                    {processorspeed.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.processor_speed}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <label>Gps</label>
                  <select
                    className="form-select"
                    name="value"
                    value={selectedgps}
                    onChange={handleInputInfoChangegps}
                  >
                    <option value="">Select Gps</option>
                    {gps.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.gps}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <label>Camera Resolution</label>
                  <select
                    className="form-select"
                    name="value"
                    value={selectedcameraresolution}
                    onChange={handleInputInfoChangecameraresolution}
                  >
                    <option value="">Select camera resolution</option>
                    {cameraresolution.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.camera_resolution}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <label>Phone Resolution</label>
                  <select
                    className="form-select"
                    name="value"
                    value={selectedphoneresolution}
                    onChange={handleInputInfoChangephoneresolution}
                  >
                    <option value="">Select phone resolution</option>
                    {phoneresolution.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.phone_resolution}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <label>Refresh Rate</label>
                  <select
                    className="form-select"
                    name="value"
                    value={selectedrefreshrate}
                    onChange={handleInputInfoChangerefreshrate}
                  >
                    <option value="">Select refresh rate</option>
                    {refreshrate.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.refresh_rate}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <label>Ram Size</label>
                  <select
                    className="form-select"
                    name="value"
                    value={selectedramsize}
                    onChange={handleInputInfoChangeramsize}
                  >
                    <option value="">Select Ram Size</option>
                    {ramsize.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.ram_size}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <label>Sensor Resolution</label>
                  <select
                    className="form-select"
                    name="value"
                    value={selectedsensorresolution}
                    onChange={handleInputInfoChangesensorresolution}
                  >
                    <option value="">Select Sensor Resolution</option>
                    {sensorresolution.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.sensor_resolution}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <label>Shooting Modes</label>
                  <select
                    className="form-select"
                    name="value"
                    value={selectedshootingmodes}
                    onChange={handleInputInfoChangeshootingmodes}
                  >
                    <option value="">Select Shooting Modes</option>
                    {shooingmode.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.shooting_modes}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <label>Cellular Technology</label>
                  <select
                    className="form-select"
                    name="value"
                    value={selectedcellulartechnology}
                    onChange={handleInputInfoChangecellulartechnology}
                  >
                    <option value="">Select cellular technology</option>
                    {cellulartechnology.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.Cellular_technology}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <label>Sim Card Size</label>
                  <select
                    className="form-select"
                    name="value"
                    value={selectedsimcardsize}
                    onChange={handleInputInfoChangesimcardsize}
                  >
                    <option value="">Select sim card size</option>
                    {simcardsize.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.sim_card}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <label>Operating System</label>
                  <select
                    className="form-select"
                    name="value"
                    value={selectedoperatingsystem}
                    onChange={handleInputInfoChangeoperatingsystem}
                  >
                    <option value="">Select Operating System</option>
                    {os.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.os}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <label>Mobile Phone Human Interface Input</label>
                  <select
                    className="form-select"
                    name="value"
                    value={selectedphonehumaninterface}
                    onChange={handleInputInfoChangephonehumaninterface}
                  >
                    <option value="">Select Interface Input</option>
                    {interfaceinput.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.interface_input}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <label>Cellular Phone Photo Sensor Resolution</label>
                  <select
                    className="form-select"
                    name="value"
                    value={selectedphotosensorresolution}
                    onChange={handleInputInfoChangephotosensorresolution}
                  >
                    <option value="">Select Photo Sensor Resolution</option>
                    {cellularphone.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.cellularphone}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <label>Video Capture Resolution</label>
                  <select
                    className="form-select"
                    name="value"
                    value={selectedvideocapture}
                    onChange={handleInputInfoChangevideocapture}
                  >
                    <option value="">Select Video Capture</option>
                    {videocapture.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.video_capture}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <label>Item Weight</label>
                  <select
                    className="form-select"
                    name="value"
                    value={selecteditemweight}
                    onChange={handleInputInfoChangeitemweight}
                  >
                    <option value="">Select Item Weight</option>
                    {itemweight.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.item_weight}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <label>Included Components</label>
                  <select
                    className="form-select"
                    name="value"
                    value={selectedincludedcomponents}
                    onChange={handleInputInfoChangeincludedcomponents}
                  >
                    <option value="">Select Included Components</option>
                    {includedcomponents.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.included_components}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <label>Mobile Phone Biometric Security Feature</label>
                  <select
                    className="form-select"
                    name="value"
                    value={selectedbiometricsecurity}
                    onChange={handleInputInfoChangebiometricsecurity}
                  >
                    <option value="">Select Biometric securityfeature</option>
                    {biometricsecurityfeature.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.biometric_securityfeature}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <label>Cell Phone Connector Type</label>
                  <select
                    className="form-select"
                    name="value"
                    value={selectedconnectortype}
                    onChange={handleInputInfoChangeconnectortype}
                  >
                    <option value="">Select Connector Type</option>
                    {connectortype.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.connector_type}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <label>Mobile Phone Connectivity Technology</label>
                  <select
                    className="form-select"
                    name="value"
                    value={selectedconnectivitytechnology}
                    onChange={handleInputInfoChangeconnectivitytechnologye}
                  >
                    <option value="">Select Phone Connectivity</option>
                    {connectivitytechnology.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.phone_connectivity}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <label>Screen Size</label>
                  <select
                    className="form-select"
                    name="value"
                    value={selectedscreensize}
                    onChange={handleInputInfoChangescreensize}
                  >
                    <option value="">Select Screen Size</option>
                    {screensize.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.screen_size}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <label>Display Type</label>
                  <select
                    className="form-select"
                    name="value"
                    value={selecteddisplaytype}
                    onChange={handleInputInfoChangedisplaytypee}
                  >
                    <option value="">Select Display Type</option>
                    {displaytype.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.display_type}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <label>Device Features</label>
                  <select
                    className="form-select"
                    name="value"
                    value={selecteddevicefeatures}
                    onChange={handleInputInfoChangedevicefeatures}
                  >
                    <option value="">Select Device Features</option>
                    {devicefeatures.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.device_features}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <label>Sim Card Slot Count</label>
                  <select
                    className="form-select"
                    name="value"
                    value={selectedsimcardsize}
                    onChange={handleInputInfoChangesimcardslotcount}
                  >
                    <option value="">Select Simcard Slotcount</option>
                    {simcardslotcount.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.simcard_slotcount}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <h4>
              Product Information <IoAdd onClick={handleInfo} className="add" />{" "}
            </h4>
            {proinfo && (
              <div className="pr-info">
                <div className="pr-name">
                  <label>Name</label>
                  <input
                    className="form-control"
                    name="name"
                    value={productInfo.name}
                    onChange={handleInputInfoChange}
                  />
                </div>
                <div className="pr-name">
                  <label>Value</label>
                  <input
                    className="form-control"
                    name="value"
                    value={productInfo.value}
                    onChange={handleInputInfoChange}
                  />
                </div>
              </div>
            )}
          </div>

          <div>
            <label>Description</label>
            <ReactQuill
              theme="snow"
              value={description}
              onChange={handleDescriptionChange}
            />
          </div>
          <div>
            <label>
              Product Features<i className="text-danger">*</i>
            </label>
            <ReactQuill
              theme="snow"
              value={productFeatures}
              onChange={handleProductFeaturesChange}
            />
          </div>
          <div>
            <label>
              Main Image <i className="text-danger">*</i>
            </label>
            <input
              className="form-control"
              type="file"
              onChange={handleMainImageChange}
            />
          </div>

          <button onClick={handleInsertProduct} className="btn btn-success m-2">
            Save
          </button>
        </div>
        <div>
          {selectedCategory && product && (
            <div>
              <div className="form-container2">
                <form onSubmit={handleSubmit}>
                  <div>
                    {/* Existing select inputs for variants */}
                    {variants.map((variant, index) => (
                      <div key={index} style={{ marginBottom: "10px" }}>
                        <label>{variant}</label>
                        <select
                          className="form-select"
                          onChange={(e) =>
                            handleVariantChange(variant, e.target.value)
                          }
                          value={selectedVariant[variant] || ""}
                        >
                          <option value="">Select {variant}</option>
                          {variantOptions[variant]?.map(
                            (option, optionIndex) => (
                              <option key={optionIndex} value={option.id}>
                                {option.hasOwnProperty(variant)
                                  ? option[variant]
                                  : ""}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    ))}

                    <label className="form-label">
                      Variant Stock:
                      <input
                        className="form-input"
                        type="number"
                        placeholder="Variant Stock"
                        value={variantStock}
                        onChange={(e) => setVariantStock(e.target.value)}
                      />
                    </label>

                    <label className="form-label">
                      Price:
                      <input
                        className="form-input"
                        type="number"
                        placeholder="Price"
                        value={varprice}
                        onChange={(e) => setVarPrice(e.target.value)}
                      />
                    </label>
                    <label className="form-label">
                      Images:
                      <input
                        multiple
                        type="file"
                        onChange={(e) => handleImageUpload(e)}
                      />
                    </label>

                    <button
                      onClick={handleAddClick}
                      style={{
                        padding: "5px 10px",
                        backgroundColor: "#007bff",
                        color: "#fff",
                        border: "none",
                        borderRadius: "3px",
                        cursor: "pointer",
                      }}
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>

              <div>
                <h2>Table Data</h2>
                <table border="1">
                  <thead>
                    <tr>
                      {variants.map((variant, index) => (
                        <th key={index}>{variant}</th>
                      ))}
                      <th>Variant Stock</th>
                      <th>Price</th>
                      <th>images</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((rowData, index) => (
                      <tr key={index}>
                        {variants.map((variant, index) => (
                          <td key={index}>{rowData[variant]}</td>
                        ))}
                        <td>{rowData.variantStock}</td>
                        <td>{rowData.varprice}</td>
                        <td>
                          {rowData.images.map((imageUrl, idx) => (
                            <img
                              key={idx}
                              src={imageUrl}
                              alt={`Image ${idx}`}
                              style={{
                                width: "100px",
                                height: "100px",
                                objectFit: "cover",
                              }}
                            />
                          ))}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <button
                onClick={handleAllDetails}
                className="btn btn-success m-2"
              >
                Save all varients
              </button>
            </div>
          )}
        </div>
      </div>
    </div>

    // </div>
  );
}

export default Addproduct;
