import React, { useState,useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import Lottie from "react-lottie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import swal from "sweetalert";
function SizeMaster() {
  const [showModal, setShowModal] = useState(false);
  const [sizes, setSizes] = useState([]);
  const [newSizeName, setNewSizeName] = useState("");
  const [filter, setFilter] = useState([]);
  const [query, setQuery] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [EditshowModal, setEditShowModal] = useState(false);
  const [editSize, setEditSize] = useState({ id: "", size: "" });
  const sizeNameInputRef = useRef(null);
  useEffect(() => {
    setLoading(true);
    fetchSizes()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  const fetchSizes = () => {
    return fetch("https://falconsquare.in/e_commerce/size_master.php")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch sizes");
        }
        return response.json();
      })
      .then((data) => {
        setSizes(data);
      })
      .catch((error) => {
        console.error("Error fetching sizes:", error);
      });
  };
  useEffect(() => {
    if (showModal) {
      sizeNameInputRef.current.focus();
    }
  }, [showModal]);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const sortedFilter = [...filter].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };
  const handleInput = (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);
  };

  useEffect(() => {
    const result = sizes.filter((size) => {
      const categoryNameMatch = size.size
        .toLowerCase()
        .includes(query.toLowerCase());
      return categoryNameMatch;
    });
    setFilter(result);
  }, [query, sizes]);

  const handleInsert = () => {
    if (!newSizeName.trim()) {
      swal("warning", "Please enter size name", "warning");
      return;
    }
  
    const formData = new FormData();
    formData.append("size", newSizeName);
  
    fetch("https://falconsquare.in/e_commerce/size_master.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data); // Log the response from the server
        fetchSizes(); // Fetch the updated list of sizes
        handleCloseModal(); // Close the modal
        setNewSizeName("");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  

  const handelEdit = async (id) => {
    // console.log("id", id);
    try {
      const response = await fetch(
        `https://falconsquare.in/e_commerce/size_master.php?id=${id}`
      );

      if (response.ok) {
        const category = await response.json();
        const categoryDetails = category.find((item) => item.id === id);
        if (categoryDetails) {
          setEditSize(categoryDetails);
          setEditShowModal(true);
        } else {
          console.error("Category with id", id, "not found");
        }
      } else {
        console.error("Failed to fetch category");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleUpdate = () => {
    const data = {
      id: editSize.id,
      size: editSize.size,
    };

    fetch(
      `https://falconsquare.in/e_commerce/size_master.php?id=${editSize.id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data); // Log the response from the server
        fetchSizes(); // Fetch the updated list of sizes
        setEditShowModal(false); // Close the edit modal
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleDelete = async (id) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this size master!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });

      if (shouldDelete) {
        const response = await fetch(
          `https://falconsquare.in/e_commerce/size_master.php?id=${id}`,
          {
            method: "DELETE",
          }
        );

        if (response.status === 200) {
          console.log("Operating system deleted successfully!");
          fetchSizes(); // Refresh the list after deleting
        } else {
          console.error("Failed to delete size master");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <div className="main-container mt-3">
      <div className=" ">
        <div className="flex-container pt-5">
          <h3 className="head-fon-mv">Size Master</h3>
        </div>

        <div className="card ">
          <div className="cat-left">
            <h4>Size Master</h4>
            <button className="add-category-button" onClick={handleShowModal}>
              Add Size Master
            </button>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="row">
              <div className="d-flex align-items-center">
                <label className="me-2">Show</label>
                <select
                  className="form-select me-2"
                  value={itemsPerPage}
                  onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
                >
                  <option value="20">20</option>
                  <option value="40">40</option>
                  <option value="60">60</option>
                  <option value="80">80</option>
                </select>
                <label>entries</label>
              </div>
            </div>
            <div className="search-left">
              <div className="searchbar">
                <input
                  type="text"
                  className="search"
                  placeholder="Search"
                  onChange={handleInput}
                />
                <FaSearch className="search-icon" />
              </div>
            </div>
          </div>
          <div className="table-container text-end ">
            {loading ? (
              <div>
                <Lottie
                  height={250}
                  width={250}
                  options={{
                    loop: true,
                    animationData: require("../Assets/Animation - 1716197548567.json"),
                    autoplay: true,
                  }}
                />
              </div>
            ) : (
              <table id="table-to-xls" className="text-center">
                <thead>
                  <tr>
                    <th style={{ width: "4%" }}>ID</th>
                    <th>Size</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPageData.map((size, index) => (
                    <tr key={size.id}>
                     <td>{startIndex + index + 1}</td>
                      <td>{size.size}</td>
                      <td>
                        
                        <button
                          className="btn-edit"
                          onClick={() => handelEdit(size.id)}
                        >
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </button>
                        <button className="btn-delete"   onClick={() => handleDelete(size.id)}> 
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            
          </div>
          <Modal show={showModal} onHide={handleCloseModal}>
            <header className="modal-header">
              <h5 className="modal-title">Add Size</h5>
              <button
                type="button"
                aria-label="Close"
                className="close"
                onClick={handleCloseModal}
              >
                ×
              </button>
            </header>

            <Modal.Body>
              <div>
                <label>Size Name</label>
                <input
                  className="form-control"
                  placeholder="Enter Size Name"
                  value={newSizeName}
                  ref={sizeNameInputRef}
                  onChange={(e) => setNewSizeName(e.target.value)}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="success" onClick={handleInsert}>
                Save
              </Button>
              <Button variant="secondary" onClick={handleCloseModal}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={EditshowModal} onHide={() => setEditShowModal(false)}>
            <header className="modal-header">
              <h5 className="modal-title">Edit Size</h5>
              <button
                type="button"
                aria-label="Close"
                className="close"
                onClick={() => setEditShowModal(false)}
              >
                ×
              </button>
            </header>
            <Modal.Body>
              <div>
                <label>Size Name</label>
                <input
                  className="form-control"
                  value={editSize.size}
                  onChange={(e) =>
                    setEditSize({ ...editSize, size: e.target.value })
                  }
                  placeholder="Enter Size Name"
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="success" onClick={handleUpdate}>
                Update
              </Button>
              <Button
                variant="secondary"
                onClick={() => setEditShowModal(false)}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div
              className="dataTables_paginate paging_simple_numbers p-2"
              id="dtBasicExample_paginate"
            >
              <ul className="pagination">
                <li
                  className={`paginate_button page-item ${
                    currentPage === 1 ? "disabled" : ""
                  }`}
                >
                  <button
                    onClick={() =>
                      setCurrentPage((prevPage) =>
                        prevPage > 1 ? prevPage - 1 : prevPage
                      )
                    }
                    className="page-link"
                  >
                    Previous
                  </button>
                </li>
                {generatePaginationNumbers().map((page, index) => (
                  <li
                    key={index}
                    className={`paginate_button page-item ${
                      page === currentPage ? "active" : ""
                    }`}
                  >
                    <button
                      onClick={() => setCurrentPage(page)}
                      className="page-link"
                    >
                      {page}
                    </button>
                  </li>
                ))}
                <li
                  className={`paginate_button page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <button
                    onClick={() =>
                      setCurrentPage((prevPage) =>
                        prevPage < totalPages ? prevPage + 1 : prevPage
                      )
                    }
                    className="page-link"
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
      </div>
    </div>
  );
}

export default SizeMaster;
