import React, { useState, useEffect } from "react";
import "./Offerpage.css";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Lottie from "react-lottie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import swal from "sweetalert";

function Offerpage() {
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [status, setStatus] = useState("1");
  const [loading, setLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [offerPrices, setOfferPrices] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedVariantId, setSelectedVariantId] = useState("");
  const [products, setProducts] = useState([]);
  const [editShowModal, setEditShowModal] = useState(false);
  const [variantPrice, setVariantPrice] = useState("");
  console.log("variantPrice", variantPrice);
  const [filteredOffers, setFilteredOffers] = useState([]);

  const [newOffer, setNewOffer] = useState({
    product_name: "",
    price: "",
    offerPrice: "",
    fromDate: "",
    toDate: "",
  });
  // console.log("newOffer",newOffer.fromDate)
  const [editCategory, setEditCategory] = useState({
    id: "",
    editproduct_name: "",
    editprice: "",
    editofferPrice: "",
    editfromDate: "",
    edittoDate: "",
  });
  console.log("editCategory", editCategory);
  const Navigate = useNavigate();
  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const sortedFilter = [...filteredOffers].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };
  const handleInput = (e) => {
    const inputValue = e.target.value.toLowerCase();
    setSearchQuery(inputValue);
    const filtered = offerPrices.filter((offer) =>
      Object.values(offer).some(
        (value) => value && value.toString().toLowerCase().includes(inputValue)
      )
    );
    setFilteredOffers(filtered);
  };

  const handleEditShowModal = () => {
    setEditShowModal(true);
  };
  const handleEditCloseModal = () => {
    setEditShowModal(false);
  };

    const calculateStatus = (startDate, endDate) => {
      const currentDate = new Date();
      const formattedStartDate = new Date(startDate);
      const formattedEndDate = new Date(endDate);

      if (currentDate >= formattedStartDate && currentDate.getDate() + 1 <= formattedEndDate) {
          return "Active";
      } else {
          return "Deactive";
      }
  };


  useEffect(() => {
    setLoading(true);
    fetchOfferPrices()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    // Fetch products from the API
    fetch("https://falconsquare.in/e_commerce/productapp.php")
      .then((response) => response.json())
      .then((data) => {
        setProducts(data.categories);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });
  }, []);

  const fetchOfferPrices = async () => {
    setLoading(true);
    try {
      const response = await fetch("https://falconsquare.in/e_commerce/offerprice.php");
      const data = await response.json();
      setOfferPrices(data);
      setFilteredOffers(data); // Set filteredOffers initially with all data
    } catch (error) {
      console.error("Error fetching offer prices:", error);
      setErrorMessage("Failed to load data");
    } finally {
      setLoading(false);
    }
  };

  const handelDelete = async (offerId) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this offer!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });

      if (shouldDelete) {
        const response = await fetch(
          `https://falconsquare.in/e_commerce/offerprice.php?id=${offerId}`,
          {
            method: "DELETE",
          }
        );
        if (response.ok) {
          swal("Deleted!", "Offer has been deleted.", "success");
          fetchOfferPrices(); // Refresh the offer prices
        } else {
          console.error("Failed to delete offer");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleInsert = async () => {
    const formData = new FormData();
    formData.append("product_name", selectedProduct);
    formData.append("price", newOffer.price);
    formData.append("offer_price", newOffer.offerPrice);
    formData.append("from_date", newOffer.fromDate);
    formData.append("to_date", newOffer.toDate);
  
    try {
      const response = await fetch(
        "https://falconsquare.in/e_commerce/offerprice.php",
        {
          method: "POST",
          body: formData,
        }
      );
  
      if (response.ok) {
        const updateData = {
          id: selectedVariantId,
          discount_price: newOffer.offerPrice,
          offer_status: "Active"
        };
        
        try {
          const updateResponse = await fetch(
            `https://falconsquare.in/e_commerce/offerpriceupdate.php?id=${selectedVariantId}`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify(updateData)
            }
          );
        
          if (updateResponse.ok) {
            console.log("Offer price record updated successfully");
          } else {
            console.error("Failed to update offer price record");
          }
        
          // Other code for handling success/failure
        } catch (error) {
          console.error("Error:", error);
        }
        

       
        fetchOfferPrices();
        setShowModal(false);
  
        // Clear the form values
        setNewOffer({
          product_name: "",
          price: "",
          offerPrice: "",
          fromDate: "",
          toDate: "",
        });
        setSelectedProduct("");
        setSelectedVariantId("");
      } else {
        console.error("Failed to insert offer price");
      }
    } catch (error) {
      console.error("Error:", error);
    }
};

  
  


  const handleUpdate = async () => {
    try {
      const data = {
        id: editCategory.id,
        product_name: editCategory.product_name,
        price: editCategory.price,
        offer_price: editCategory.offerPrice,
        from_date: editCategory.fromDate,
        to_date: editCategory.toDate,
      };

      const response = await fetch(
        `https://falconsquare.in/e_commerce/offerprice.php?id=${editCategory.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      console.log("Response status:", response.status);

      if (response.ok) {
        console.log("Offer updated successfully!");
        // Update the offerPrices state to reflect the changes
        setOfferPrices((offerPrices) =>
          offerPrices.map((offer) =>
            offer.id === editCategory.id ? editCategory : offer
          )
        );
        // Optionally, you can fetch the offer prices again to refresh the list
        fetchOfferPrices();
        setEditShowModal(false);
      } else {
        console.error("Failed to update offer");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleVariantChange = (e) => {
    const selectedVariantId = e.target.value;
    setSelectedVariantId(selectedVariantId);

    // Find the selected variant's price
    const selectedVariant = products
      .flatMap((product) => product.variants || [])
      .find((variant) => variant.id === selectedVariantId);
    console.log("selectedVariant", selectedVariant);
    if (selectedVariant) {
      setVariantPrice(selectedVariant.price); // Update the state with the selected variant's price
      setNewOffer({ ...newOffer, price: selectedVariant.price });
    }
  };

  const handleEdit = async (id) => {
    try {
      const response = await fetch(
        `https://falconsquare.in/e_commerce/offerprice.php?id=${id}`
      );

      if (response.ok) {
        const offer = await response.json();
        const offerDetails = offer.find((item) => item.id === id);
        if (offerDetails) {
          setEditCategory({
            id: offerDetails.id, // Set id along with other details
            product_name: offerDetails.product_name,
            price: offerDetails.price,
            offerPrice: offerDetails.offer_price,
            fromDate: offerDetails.from_date,
            toDate: offerDetails.to_date,
          });
          handleEditShowModal();
        } else {
          console.error("Offer with id", id, "not found");
        }
      } else {
        console.error("Failed to fetch offer");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <div className="main-container mt-3">
      <div className="flex-container pt-5">
        <h3 className="head-fon-mv">Offer Page</h3>
        <div class="col-12 col-md-6 order-md-2 order-first">
          <nav
            aria-label="breadcrumb"
            class="breadcrumb-header float-start float-lg-end"
          >
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a onClick={() => Navigate("/dashboard")}>Dashboard</a>
              </li>{" "}
              <li aria-current="page" class="breadcrumb-item active">
                Offer Page
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="card">
        <div className="cat-left">
          <h4>Offer</h4>
          <button className="add-category-button" onClick={handleShowModal}>
            Add Offer
          </button>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="row">
            <div className="d-flex align-items-center">
              <label className="me-2">Show</label>
              <select
                className="form-select me-2"
                value={itemsPerPage}
                onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
              >
                <option value="20">20</option>
                <option value="40">40</option>
                <option value="60">60</option>
                <option value="80">80</option>
              </select>
              <label>entries</label>
            </div>
          </div>
          <div className="search-left">
            <div className="searchbar">
              <input
                type="text"
                className="search"
                placeholder="Search"
                onChange={handleInput}
              />
              <FaSearch className="search-icon" />
            </div>
          </div>
        </div>
        <div className="table-container text-end">
          {loading ? (
            <div>
              <Lottie
                height={250}
                width={250}
                options={{
                  loop: true,
                  animationData: require("../Assets/Animation - 1716197548567.json"),
                  autoplay: true,
                }}
              />
            </div>
          ) : (
            <table id="table-to-xls" className="text-center">
              <thead>
                <tr>
                  <th style={{ width: "4%" }}>S.No</th>
                  <th>product ID</th>
                  <th>Price</th>
                  <th>Offer price</th>
                  <th>From date</th>
                  <th>To Date</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {(searchQuery ? filteredOffers : currentPageData).map(
                  (offer, index) => (
                    <tr key={offer.id}>
                       <td>{startIndex + index + 1}</td>
                      <td>{offer.product_name}</td>
                      <td>{offer.price}</td>
                      <td>{offer.offer_price}</td>
                      <td>{offer.from_date}</td>
                      <td>{offer.to_date}</td>
                      <td>
                        <span
                          className={`status ${
                            calculateStatus(offer.from_date, offer.to_date) ===
                            "Active"
                              ? "active-status"
                              : "deactive-status"
                          }`}
                        >
                          {calculateStatus(offer.from_date, offer.to_date)}
                        </span>
                      </td>
                      <td>
                        <button
                          className="btn-edit"
                          onClick={() => handleEdit(offer.id)}
                        >
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </button>
                        <button
                          className="btn-delete"
                          onClick={() => handelDelete(offer.id)}
                        >
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div
        className="dataTables_paginate paging_simple_numbers p-2"
        id="dtBasicExample_paginate"
      >
        <ul className="pagination">
          <li
            className={`paginate_button page-item ${
              currentPage === 1 ? "disabled" : ""
            }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage > 1 ? prevPage - 1 : prevPage
                )
              }
              className="page-link"
            >
              Previous
            </button>
          </li>
          {generatePaginationNumbers().map((page, index) => (
            <li
              key={index}
              className={`paginate_button page-item ${
                page === currentPage ? "active" : ""
              }`}
            >
              <button
                onClick={() => setCurrentPage(page)}
                className="page-link"
              >
                {page}
              </button>
            </li>
          ))}
          <li
            className={`paginate_button page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage < totalPages ? prevPage + 1 : prevPage
                )
              }
              className="page-link"
            >
              Next
            </button>
          </li>
        </ul>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <header className="modal-header">
          <h5 className="modal-title">Add Offer</h5>
          <button
            type="button"
            aria-label="Close"
            className="close"
            onClick={handleCloseModal}
          >
            ×
          </button>
        </header>

        <Modal.Body>
          <div>
            <label>Product Name</label>
            <select
              className="form-select"
              onChange={(e) => {
                setSelectedProduct(e.target.value);
                handleVariantChange(e);
              }}
            >
              <option value="">Select Product</option>
              {products &&
                products.map(
                  (product) =>
                    product.variants &&
                    product.variants.map((variant) => (
                      <option key={variant.id} value={variant.id}>
                        {product.product_name} - {variant.colour},{" "}
                        {variant.storage}GB Storage, {variant.variant_size} Ram
                      </option>
                    ))
                )}
            </select>
            <label>Price</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Price"
              value={newOffer.price}
              readOnly
            />
            <label>Offer Price</label>
            <input
              className="form-control"
              placeholder="Enter Offer Price"
              value={newOffer.offerPrice}
              onChange={(e) =>
                setNewOffer({ ...newOffer, offerPrice: e.target.value })
              }
            />
            <label>From Date</label>
            <input
              className="form-control"
              placeholder="Enter From Date"
              type="date"
              value={newOffer.fromDate}
              onChange={(e) =>
                setNewOffer({ ...newOffer, fromDate: e.target.value })
              }
            />
            <label>To Date</label>
            <input
              className="form-control"
              placeholder="Enter To Date"
              type="date"
              value={newOffer.toDate}
              onChange={(e) =>
                setNewOffer({ ...newOffer, toDate: e.target.value })
              }
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="success" onClick={handleInsert}>
            Save
          </Button>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={editShowModal} onHide={handleEditCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Update Offer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label>Product Name</label>
              <input
                type="text"
                className="form-control"
                value={editCategory.product_name}
                onChange={(e) =>
                  setEditCategory({
                    ...editCategory,
                    product_name: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group">
              <label>Price</label>
              <input
                type="text"
                className="form-control"
                value={editCategory.price}
                onChange={(e) =>
                  setEditCategory({ ...editCategory, price: e.target.value })
                }
              />
            </div>
            <div className="form-group">
              <label>Offer Price</label>
              <input
                type="text"
                className="form-control"
                value={editCategory.offerPrice}
                onChange={(e) =>
                  setEditCategory({
                    ...editCategory,
                    offerPrice: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group">
              <label>From Date</label>
              <input
                type="date"
                className="form-control"
                value={editCategory.fromDate}
                onChange={(e) =>
                  setEditCategory({ ...editCategory, fromDate: e.target.value })
                }
              />
            </div>
            <div className="form-group">
              <label>To Date</label>
              <input
                type="date"
                className="form-control"
                value={editCategory.toDate}
                onChange={(e) =>
                  setEditCategory({ ...editCategory, toDate: e.target.value })
                }
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleUpdate}>
            update
          </Button>
          <Button variant="secondary" onClick={() => setEditShowModal(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Offerpage;
