import React, { useEffect, useState, useRef } from "react";
import "./Vieworder.css";
import { useNavigate } from "react-router-dom";
import { FaEdit, FaHome, FaShoppingBag, FaUser } from "react-icons/fa";
import { FaShop } from "react-icons/fa6";
import { useLocation } from "react-router-dom";
import destination from "../Assets/Images/destination.png";
import shoppingbag from "../Assets/Images/shopping-bag.png";
import ReactToPrint from "react-to-print";
import Modal from "react-modal";
import falcon from "../Assets/logo.png";
import { toWords } from "number-to-words";
function Vieworder() {
  const Navigate = useNavigate();
  const [orders, setOrders] = useState("");
  const modalRef = useRef(null);
  const [shop, setShop] = useState([]);
  const componentRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orderStatus, setOrderStatus] = useState("0");
  const [taxDetails, setTaxDetails] = useState([]);
  console.log({ orderStatus: orderStatus });
  let grandTotal = 0;

  const [totalFinalPrice, setTotalFinalPrice] = useState(0);
  const amountInWords = toWords(totalFinalPrice);
  const location = useLocation();
  const { order_id, user_id, address_id } = location.state;
  console.log("object", order_id, user_id, address_id);

  const fetchOrders = async () => {
    try {
      const response = await fetch(
        `https://falconsquare.in/e_commerce/admin/admin_single_order.php?order_id=${order_id}&user_id=${user_id}&address_id=${address_id}`
      );
      const data = await response.json();
      console.log({ data: data });
      setOrders(data);
    } catch (error) {
      console.error("Error fetching order data:", error);
    }
  };
  const taxprice =
    orders &&
    orders.product_details.map((v) => {
      return Number(v.Producttax) / 2;
    });

  const getData = async () => {
    try {
      const response = await fetch(
        "https://falconsquare.in/e_commerce/shop.php"
      );
      const data = await response.json();
      setShop(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [order_id, user_id, address_id]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (orders?.product_details) {
      let totalPrice = 0;
      const taxData = [];

      orders.product_details.forEach((product) => {
        const itemPrice = parseFloat(product.ProductAmt);
        const taxRate = parseFloat(product.Producttax);
        const discountAmount = parseFloat(product.ProductDiscount);
        const taxAmount = (itemPrice * taxRate) / 100;
        const finalPrice = itemPrice + taxAmount - discountAmount;

        totalPrice += finalPrice;

        // Collect tax details
        taxData.push({
          taxRate,
          taxAmount,
          itemPrice,
        });
      });

      setTotalFinalPrice(totalPrice);
      setTaxDetails(taxData);
    }
  }, [orders]);

  const openModal = async () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    const savedStatus = localStorage.getItem("order_status");
    if (savedStatus) {
      setOrderStatus(savedStatus);
    }
  }, []);

  const handleStatusChange = async (event) => {
    const newStatus = event.target.value;
    setOrderStatus(newStatus);

    try {
      const formData = new FormData();
      formData.append("order_id", order_id);
      formData.append("order_status", newStatus);
      console.log({ newStatus: newStatus });

      const response = await fetch(
        `https://falconsquare.in/e_commerce/orderdetails.php?id=${order_id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Order status updated:", data);
      localStorage.setItem("order_status", newStatus);
      fetchOrders();
    } catch (error) {
      console.error("Failed to update order status:", error);
    }
  };

  return (
    <div className="main-container mt-3">
      <div className="flex-container pt-5">
        <h3 className="head-fon-mv">View Order</h3>
        <div class="col-12 col-md-6 order-md-2 order-first">
          <nav
            aria-label="breadcrumb"
            class="breadcrumb-header float-start float-lg-end"
          >
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a onClick={() => Navigate("/dashboard")}>Dashboard</a>
              </li>{" "}
              <li aria-current="page" class="breadcrumb-item active">
                Order Details
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="content container-fluid">
        <div class="mb-4">
          <h2 class="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
            Order Details
          </h2>
        </div>
        <div class="row gy-3" id="printableArea">
          <div class="col-lg-8">
            <div class="card h-100">
              <div class="card-body">
                <div class="d-flex flex-wrap flex-md-nowrap gap-10 justify-content-between mb-4">
                  <div class="d-flex flex-column gap-10">
                    <h4 class="text-capitalize">
                      Order ID {orders && orders.order_details.id}
                    </h4>
                    <div class="">
                      {orders && orders.order_details.order_place_date}
                    </div>
                  </div>
                  <div class="text-sm-right flex-grow-1">
                    <div class="d-flex flex-wrap gap-10 justify-content-end">
                      <button
                        class="btn btn-primary"
                        onClick={() => openModal()}
                      >
                        <a>Print Invoice</a>
                      </button>
                    </div>

                    <div class="d-flex flex-column gap-2 mt-3">
                      <div class="order-status d-flex justify-content-sm-end  align-items-center text-capitalize">
                        <span className="title-color">Status: </span>
                        <span className="status-badge">
                          {orders &&
                            orders.order_details.order_status === "2" && (
                              <span className="badge badge-soft-success font-weight-bold radius-50 d-flex align-items-center py-1 px-2">
                                Pending
                              </span>
                            )}
                          {orders &&
                            orders.order_details.order_status === "1" && (
                              <span className="badge badge-soft-success font-weight-bold radius-50 d-flex align-items-center py-1 px-2 ">
                                Confirmed
                              </span>
                            )}
                          {orders &&
                            orders.order_details.order_status === "3" && (
                              <span className="badge badge-soft-warning font-weight-bold radius-50 d-flex align-items-center py-1 px-2">
                                Packaging
                              </span>
                            )}
                          {orders &&
                            orders.order_details.order_status === "4" && (
                              <span className="badge badge-soft-warning font-weight-bold radius-50 d-flex align-items-center py-1 px-2">
                                Out for delivery
                              </span>
                            )}
                          {orders &&
                            orders.order_details.order_status === "5" && (
                              <span className="badge badge-soft-success font-weight-bold radius-50 d-flex align-items-center py-1 px-2">
                                Delivered
                              </span>
                            )}
                          {orders &&
                            orders.order_details.order_status === "6" && (
                              <span className="badge badge-soft-danger font-weight-bold radius-50 d-flex align-items-center py-1 px-2">
                                Returned
                              </span>
                            )}
                          {orders &&
                            orders.order_details.order_status === "7" && (
                              <span className="badge badge-soft-danger font-weight-bold radius-50 d-flex align-items-center py-1 px-2">
                                Failed to Deliver
                              </span>
                            )}
                          {orders &&
                            orders.order_details.order_status === "8" && (
                              <span className="badge badge-soft-danger font-weight-bold radius-50 d-flex align-items-center py-1 px-2">
                                Canceled
                              </span>
                            )}
                        </span>
                      </div>
                      <div class="payment-method d-flex justify-content-sm-end gap-10 text-capitalize">
                        <span class="title-color">Payment Method :</span>
                        <strong className="status-badge ">
                          {/* {orders && orders.order_details.payment_method} */}
                          Cash On Delivery
                        </strong>
                      </div>
                      <div class="payment-status d-flex justify-content-sm-end gap-10">
                        <span className="title-color">Payment Status:</span>
                        <span className="text-danger payment-status-span status-badge font-weight-bold">
                          {orders &&
                          orders.order_details.order_delivered_status === "0"
                            ? "Unpaid"
                            : "Other Status"}
                        </span>
                      </div>
                      <span class="">
                        <b>
                          Order verification code :{" "}
                          {orders && orders.order_details.id}
                        </b>
                      </span>
                    </div>
                  </div>
                  <div >
                    <Modal
                      isOpen={isModalOpen}
                      onRequestClose={closeModal}
                      contentLabel="Print Modal"
                      className="Modal"
                      
                    >
                      <div style={{ textAlign: "center", height: "auto" }}>
                        <div ref={componentRef} className="print-container ">
                          {shop.map((product) => (
                            <div key={product.id} className="shop-item">
                              <div className="row align-items-center m-3">
                                <div className="col-md-3">
                                  <img
                                    src={`https://falconsquare.in/e_commerce/image/shop/${product.logo}`}
                                    className="falcon-logo"
                                    alt={product.shop_name}
                                  />
                                </div>
                                <div className="col-md-9">
                                  <div className="content_order">
                                    <h5>
                                      Tax Invoice/Bill of Supply/Cash Memo
                                    </h5>
                                    <h6>(Original for Recipient)</h6>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-5 m-5">
                                <div className="d-flex flex-row">
                                  <div className="w-50">
                                    <h4>Sold By :</h4>
                                    <h6>
                                      {product.shop_name}
                                      <br />
                                      {product.address}
                                    </h6>
                                    Order ID :
                                    {orders && orders.order_details.id}
                                    <br />
                                    Order Date:{" "}
                                    {orders &&
                                      orders.order_details.order_place_date}
                                  </div>
                                  <div className="d-flex justify-content-end w-50">
                                    <div className="w-100">
                                      <div>
                                        {orders && (
                                          <div className="text-end">
                                            <div className="d-flex  align-items-center justify-content-end ">
                                              <h4 className="d-flex ">
                                                Shipping address :
                                              </h4>
                                            </div>
                                            <div className="d-flex flex-column align-items-end">
                                              <p className="text-end">
                                                {
                                                  orders.address_details
                                                    .full_name
                                                }
                                                ,
                                                {
                                                  orders.address_details
                                                    .mobile_no
                                                }
                                                ,
                                                {orders.address_details.country}
                                                ,{orders.address_details.town},
                                                {orders.address_details.pincode}{" "}
                                                <br />
                                                {orders.address_details.street}
                                                {orders.address_details.town}
                                                {orders.address_details.state}
                                                {orders.address_details.pincode}
                                                {orders.address_details.country}
                                              </p>
                                            </div>
                                          </div>
                                        )}
                                      </div>

                                      {orders && (
                                        <div className="text-end">
                                          <div className="d-flex  align-items-center justify-content-end ">
                                            <h4 className="d-flex ">
                                              Billing address :
                                            </h4>
                                          </div>
                                          <div className="d-flex flex-column align-items-end">
                                            <p className="text-end">
                                              {orders.address_details.full_name}
                                              ,
                                              {orders.address_details.mobile_no}
                                              ,{orders.address_details.country},
                                              {orders.address_details.town},
                                              {orders.address_details.pincode}{" "}
                                              <br />
                                              {orders.address_details.street}
                                              {orders.address_details.town}
                                              {orders.address_details.state}
                                              {orders.address_details.pincode}
                                              {orders.address_details.country}
                                            </p>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="card-table">
                                  <div className="table-responsive datatable-custom">
                                    <div className="table-container">
                                      <table className="m-0 w-100">
                                        <thead className="thead-50 text-capitalize">
                                          <tr>
                                            <th>SL</th>
                                            <th>Item details</th>
                                            <th>Item price</th>
                                            <th>Item discount</th>
                                            <th>Tax</th>
                                            <th>Product Amount</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {orders?.product_details?.map(
                                            (product, index) => {
                                              // Calculate the final price
                                              const itemPrice = parseFloat(
                                                product.ProductAmt
                                              );
                                              const taxAmount =
                                                (itemPrice *
                                                  parseFloat(
                                                    product.Producttax
                                                  )) /
                                                100;
                                              const discountAmount = parseFloat(
                                                product.ProductDiscount
                                              );
                                              const finalPrice =
                                                itemPrice +
                                                taxAmount -
                                                discountAmount;

                                              return (
                                                <tr key={product.ProductId}>
                                                  <td>{index + 1}</td>
                                                  <td>
                                                    <div className="media align-items-center gap-10">
                                                      <div>
                                                        <h6 className="mb-0">
                                                          {product.ProductName}
                                                        </h6>
                                                        <p className="mb-0">
                                                          Qty:{" "}
                                                          {product.ProductQty}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>{product.ProductAmt}</td>
                                                  <td>
                                                    {product.ProductDiscount}
                                                  </td>
                                                  <td>{product.Producttax}%</td>
                                                  <td>
                                                    {finalPrice.toFixed(2)}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}

                                          <tr>
                                            <td colSpan="5">
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                              >
                                                <span className="amt-in-word">
                                                  <b>
                                                    &nbsp;Total Final Price:
                                                  </b>
                                                  <br />
                                                  &nbsp;
                                                  {amountInWords}
                                                  Only
                                                </span>
                                              </div>
                                            </td>
                                            <td>
                                              {totalFinalPrice.toFixed(2)}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>

                                      <table >
                                        <thead>
                                          <tr>
                                            <th rowSpan="2"></th>
                                            <th rowSpan="2">
                                              TAXABLE <br /> VALUE
                                            </th>
                                            <th colSpan="2">Central Tax</th>
                                            <th colSpan="2">State Tax</th>
                                            <th rowSpan="2">
                                              Total Tax <br /> Amount
                                            </th>
                                          </tr>
                                          <tr>
                                            <td>Rate</td>
                                            <td>Amount</td>
                                            <td>Rate</td>
                                            <td>Amount</td>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {taxDetails.map(
                                            (taxDetail, index) => (
                                              <tr key={index}>
                                                <td className="width-test"></td>
                                                <td>
                                                  {taxDetail.itemPrice.toFixed(
                                                    2
                                                  )}
                                                </td>
                                                <td>{taxDetail.taxRate}%</td>
                                                <td>
                                                  {taxDetail.taxAmount.toFixed(
                                                    2
                                                  )}
                                                </td>
                                                <td>{taxDetail.taxRate}%</td>
                                                <td>
                                                  {taxDetail.taxAmount.toFixed(
                                                    2
                                                  )}
                                                </td>
                                                <td>
                                                  {(
                                                    taxDetail.taxAmount * 2
                                                  ).toFixed(2)}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <ReactToPrint
                          trigger={() => (
                            <button className="sub-btn-print">Print</button>
                          )}
                          content={() => componentRef.current}
                          documentTitle="Invoice"
                          pageStyle="@media print { @page { size: A4; margin: 20mm; } }"
                          onAfterPrint={() => console.log("Data printed")}
                          
                        />
                      </div>
                    </Modal>
                  </div>
                </div>
                <div class="table-responsive datatable-custom">
                  <table class="table fz-12 table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100">
                    <thead class="thead-light thead-50 text-capitalize">
                      <tr>
                        <th>SL</th>
                        <th>Item details</th>
                        <th>Item price</th>
                        <th>Tax</th>
                        <th>Item discount</th>
                        <th>Total price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders?.product_details?.map((product, index) => (
                        <tr key={product.ProductId}>
                          <td>{index + 1}</td>
                          <td>
                            <div className="media align-items-center gap-10">
                              {/* <img
                                src={`https://falconsquare.in/e_commerce/images/product/${product.ProductImage}`}
                                alt={product.ProductName}
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  objectFit: "contain",
                                }}
                              /> */}
                              <div>
                                <h6 className="mb-0">{product.ProductName}</h6>
                                <p className="mb-0">
                                  Qty: {product.ProductQty}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>{product.ProductAmt}</td>
                          <td>{product.Producttax}</td>
                          <td>{product.ProductDiscount}</td>
                          <td> {orders && orders.order_details.total_price}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <hr />
                <div class="row justify-content-md-end mb-3">
                  {orders?.product_details?.map((product, index) => (
                    <div class="col-md-9 col-lg-8">
                      <dl class="row gy-1 text-sm-right">
                        <dt class="col-5">Item price</dt>
                        <dd class="col-6 title-color">
                          <strong> {product.ProductAmt}</strong>
                        </dd>
                        <dt class="col-5 text-capitalize">Item discount</dt>
                        <dd class="col-6 title-color">
                          -
                          <strong>
                            {orders && orders.order_details.discount}
                          </strong>
                        </dd>
                        <dt class="col-5 text-capitalize">Sub total</dt>
                        <dd class="col-6 title-color">
                          <strong>{product.subtatal}</strong>
                        </dd>
                        {/* <dt class="col-5 text-nowrap">
                          Coupon discount
                          <br />
                        </dt>
                        <dd class="col-6 title-color">
                          -  <strong>{orders && orders.order_details.discount}</strong>
                        </dd> */}
                        <dt class="col-5 text-uppercase">Vat/Tax</dt>
                        <dd class="col-6 title-color">
                          <strong> {orders && orders.order_details.tax}</strong>
                        </dd>
                        <dt class="col-5 text-capitalize">
                          Delivery fee
                          <br />
                        </dt>
                        <dd class="col-6 title-color">
                          <strong>
                            {orders && orders.order_details.delivery_charge}
                          </strong>
                        </dd>
                        <dt class="col-5">
                          <strong>Total</strong>
                        </dt>
                        <dd class="col-6 title-color">
                          <strong>
                            {orders && orders.order_details.total_price}
                          </strong>
                        </dd>
                      </dl>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 d-flex flex-column gap-3">
            <div class="card">
              <div class="card-body text-capitalize d-flex flex-column gap-4">
                <div class="d-flex flex-column align-items-center gap-2">
                  <h4 class="mb-0 text-center">Order &amp; Shipping Info</h4>
                </div>
                <div class="">
                  <label class="font-weight-bold title-color fz-14">
                    Change order status
                  </label>
                  <select
                    name="order_status"
                    id="order_status"
                    className=" form-select"
                    value={orderStatus}
                    onChange={handleStatusChange}
                  >
                    <option value="2"> Pending</option>
                    <option value="1"> Confirmed</option>
                    <option value="3">Packaging </option>
                    <option class="text-capitalize" value="4">
                      Out for delivery{" "}
                    </option>
                    <option value="5">Delivered </option>
                    <option value="6"> Returned</option>
                    <option value="7">Failed to Deliver </option>
                    <option value="8">Canceled </option>
                  </select>
                </div>
                <div class="d-flex justify-content-between align-items-center gap-10 form-control h-auto flex-wrap">
                  <span class="title-color">Payment status</span>
                  <div class="d-flex justify-content-end min-w-100 align-items-center gap-2">
                    <span class="text--primary font-weight-bold">Unpaid</span>
                    <label class="switcher payment-status-text p-1">
                      <input
                        className="switcher_input payment-status "
                        type="checkbox"
                        name="status"
                        data-id="100208"
                        value="unpaid"
                        id="payment-status-toggle"
                      />
                      <label
                        className="switcher_label"
                        htmlFor="payment-status-toggle"
                      ></label>
                    </label>
                  </div>
                </div>
                <ul class="list-unstyled list-unstyled-py-4">
                  <li>
                    <label class="font-weight-bold title-color fz-14">
                      Shipping Method (Company Vehicle )
                    </label>
                    <select
                      className="form-select "
                      name="delivery_type"
                      id="choose_delivery_type"
                    >
                      <option value="0">Choose delivery type</option>
                      <option value="self_delivery">
                        By self delivery man
                      </option>
                      <option value="third_party_delivery">
                        By third party delivery service
                      </option>
                    </select>
                  </li>
                  <li class="choose_delivery_man">
                    <label class="font-weight-bold title-color fz-14">
                      Delivery man
                    </label>
                    <select
                      className="form-select "
                      name="delivery_man_id"
                      id="addDeliveryMan"
                      data-order-id="100208"
                      data-select2-id="addDeliveryMan"
                      tabindex="-1"
                      aria-hidden="true"
                    >
                      <option value="0" data-select2-id="2">
                        Select Delivery man
                      </option>
                      <option value="1" data-select2-id="3">
                        Demo Deliveryman 1 (0********* )
                      </option>
                      <option value="5" data-select2-id="4">
                        Test test (000000345 )
                      </option>
                      <option value="7" data-select2-id="5">
                        Demo Deliveryman (1********* )
                      </option>
                      <option value="10" data-select2-id="6">
                        Will Smith (1********* )
                      </option>
                    </select>
                  </li>
                </ul>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <div class="d-flex gap-2 align-items-center justify-content-between mb-4">
                  <h4 className="d-flex gap-2">
                    <div className="icon-container">
                      <FaUser className="user-icon" />
                    </div>
                    Customer information
                  </h4>
                </div>
                {orders && (
                  <div class="media flex-wrap gap-3">
                    <div class=""></div>
                    <div class="media-body d-flex flex-column gap-1">
                      <span class="title-color">
                        <strong>{orders.order_details.user_name}</strong>
                      </span>
                      <span class="title-color">
                        {" "}
                        <strong>32</strong> Orders
                      </span>
                      <span class="title-color break-all">
                        <strong>{orders.order_details.user_phone}</strong>
                      </span>
                      <span class="title-color break-all">
                        {orders.order_details.user_email}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <div class="d-flex gap-2 align-items-center justify-content-between mb-4">
                  <h4 class="d-flex gap-2">
                    <div className="icon-container">
                      <FaUser className="user-icon" />
                    </div>
                    Shipping address
                  </h4>
                </div>
                {orders && (
                  <div className="d-flex flex-column gap-2">
                    <div>
                      <span>Name :</span>
                      <strong>{orders.address_details.full_name}</strong>
                    </div>
                    <div>
                      <span>Contact :</span>
                      <strong>{orders.address_details.mobile_no}</strong>
                    </div>
                    <div>
                      <span>Country :</span>
                      <strong>{orders.address_details.country}</strong>
                    </div>
                    <div>
                      <span>City :</span>
                      <strong>{orders.address_details.town}</strong>
                    </div>
                    <div>
                      <span>Zip code :</span>
                      <strong>{orders.address_details.pincode}</strong>
                    </div>
                    <div className="d-flex align-items-start gap-2">
                      <img src={destination} className="img_con-loc" />
                      {`${orders.address_details.street}, ${orders.address_details.town}, ${orders.address_details.state}, ${orders.address_details.pincode}, ${orders.address_details.country}`}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <div class="d-flex gap-2 align-items-center justify-content-between mb-4">
                  <h4 class="d-flex gap-2">
                    <div className="icon-container">
                      <FaUser className="user-icon" />
                    </div>
                    Billing address
                  </h4>
                </div>
                {orders && (
                  <div className="d-flex flex-column gap-2">
                    <div>
                      <span>Name :</span>
                      <strong>{orders.address_details.full_name}</strong>
                    </div>
                    <div>
                      <span>Contact :</span>
                      <strong>{orders.address_details.mobile_no}</strong>
                    </div>
                    <div>
                      <span>Country :</span>
                      <strong>{orders.address_details.country}</strong>
                    </div>
                    <div>
                      <span>City :</span>
                      <strong>{orders.address_details.town}</strong>
                    </div>
                    <div>
                      <span>Zip code :</span>
                      <strong>{orders.address_details.pincode}</strong>
                    </div>
                    <div className="d-flex align-items-start gap-2">
                      <img src={destination} className="img_con-loc" />
                      {`${orders.address_details.street}, ${orders.address_details.town}, ${orders.address_details.state}, ${orders.address_details.pincode}, ${orders.address_details.country}`}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <h4 class="d-flex gap-2 mb-4">
                  <div className="icon-container">
                    <FaShoppingBag className="user-icon" />
                  </div>
                  Shop Information
                </h4>
                <div class="media">
                  <div class="mr-3">
                    <img
                      class="avatar rounded avatar-70 img-fit"
                      src={shoppingbag}
                      className="img_con-loc"
                    />
                  </div>
                  {shop.map((product) => (
                    <div class="media-body d-flex flex-column gap-2">
                      <h5>{product.shop_name}</h5>

                      <span class="title-color">
                        {" "}
                        <strong>{product.phoneno}</strong>
                      </span>
                      <div class="d-flex align-items-start gap-2">
                        <img src={destination} className="img_con-loc" />
                        {product.address}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Vieworder;
