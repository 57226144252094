import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import css from "./Categories.css";
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import swal from "sweetalert";
function Categories() {
  const [showModal, setShowModal] = useState(false);
  const [EditshowModal, setEditShowModal] = useState(false);
  const [parentcategory, setParentcategory] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [image, setImage] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [categories, setCategories] = useState([]);
  const [categoriesload, setCategoriesload] = useState([]);
  const [selectedOption, setSelectedOption] = useState("limited"); // Define selectedOption state
  const [totalItems, setTotalItems] = useState(0);
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState([]);
  const [editCategory, setEditCategory] = useState({
    parent_category: "",
    category_name: "",
    category_subtitle: "",
    image: "",
    status: "",
  });
  const [filename, setFilename] = useState("");
  const Navigate = useNavigate();

  const [CategoryName, setCategoryName] = useState("");
  const [CategorySubtitle, setCategorySubtitle] = useState("");
  const [status, setStatus] = useState("1");
  const [imageFile, setImageFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleShowModal = () => {
    setShowModal(true);
  };
  const handlePerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const sortedFilter = [...filter].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setStatus(event.target.value);
  };
  useEffect(() => {
    setLoading(true);
    GetData()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    setLoading(true);
    const timeout = setTimeout(() => {
      setSelectedOption("limited");
      setLoading(false); // Make sure to set loading to false here
    }, 1000);

    return () => clearTimeout(timeout); // Cleanup the timeout on component unmount
  }, []);

  const GetData = async () => {
    const data = await fetch(
      "https://falconsquare.in/e_commerce/categories.php"
    );
    const response = await data.json();
    setCategories(response);
  };
  useEffect(() => {
    Dataget();
    setFilename(editCategory.image);
  }, []);
  const Dataget = async () => {
    const data = await fetch(
      "https://falconsquare.in/e_commerce/categoriesmaster.php"
    );
    const response = await data.json();
    setCategoriesload(response);
  };

  // const handleFileChange = (event) => {
  //   const selectedFile = event.target.files[0];
  //   setImage(selectedFile);
  // };
  const handleFileChange = (e) => {
    const selectedImage = e.target.files[0];
    const maxSize = 20 * 1024 * 1024; // 20MB in bytes

    if (selectedImage && selectedImage.size <= maxSize) {
      setImage(selectedImage);
      setErrorMessage("");
    } else {
      setErrorMessage("Please select a file smaller than 20MB");
    }
  };
  const handleInputChange = (e) => {
    const { value } = e.target;

    const newValue = value.replace(/[^a-zA-Z ]/g, "");
    setCategoryName(newValue);
  };

  const handleInsert = () => {
    const formData = new FormData();
    formData.append("parent_category", parentcategory);
    formData.append("category_name", CategoryName);
    formData.append("category_subtitle", CategorySubtitle);
    formData.append("status", status);
    console.log("status", status);
    formData.append("image", image);
    fetch("https://falconsquare.in/e_commerce/categories.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((data) => {
        console.log(data);
        GetData();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    setShowModal(false);
  };

  const handelDelete = async (categoryId) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this category!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });

      if (shouldDelete) {
        const response = await fetch(
          `https://falconsquare.in/e_commerce/categories.php?id=${categoryId}`,
          {
            method: "DELETE",
          }
        );
        if (response.ok) {
          setCategories(
            categories.filter((category) => category.id !== categoryId)
          );
          swal("Deleted!", "Category has been deleted.", "success");
        } else {
          console.error("Failed to delete category");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handelEdit = async (id) => {
    // console.log("id", id);
    try {
      const response = await fetch(
        `https://falconsquare.in/e_commerce/categories.php?id=${id}`
      );

      if (response.ok) {
        const category = await response.json();
        const categoryDetails = category.find((item) => item.id === id);
        if (categoryDetails) {
          setEditCategory(categoryDetails);
          setEditShowModal(true);
        } else {
          console.error("Category with id", id, "not found");
        }
      } else {
        console.error("Failed to fetch category");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdate = async () => {
    try {
      const formData = new FormData();
      formData.append("id", editCategory.id);
      formData.append("parent_category", editCategory.parent_category);
      formData.append("category_name", editCategory.category_name);
      formData.append("category_subtitle", editCategory.category_subtitle);
      formData.append("current_image", editCategory.image);
      if (imageFile) {
        formData.append("image", imageFile);
      }
      formData.append("status", editCategory.status);
      console.log(formData);
      const response = await fetch(
        `https://falconsquare.in/e_commerce/categorioesupdate.php?id=${editCategory.id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.status === 200) {
        console.log("Category updated successfully!");
        setCategories(
          categories.map((category) =>
            category.id === editCategory.id ? editCategory : category
          )
        );
        setEditShowModal(false);
        setParentcategory("");
        setCategoryName("");
        setCategorySubtitle("");
        setImage("");
        setStatus("1");
        setShowModal(false);
        setImageFile(null);
        setFilename("");
      } else {
        console.error("Failed to update category");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    GetData();
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    GetData();
  }, []);

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
  };

  useEffect(() => {
    const result = categories.filter((category) => {
      const parentCategoryMatch = category.parent_category
        .toLowerCase()
        .includes(query.toLowerCase());
      const categoryNameMatch = category.category_name
        .toLowerCase()
        .includes(query.toLowerCase());
      const categorySubtitleMatch = category.category_subtitle
        .toLowerCase()
        .includes(query.toLowerCase());
      const imageMatch = category.image
        .toLowerCase()
        .includes(query.toLowerCase());
      const statusMatch = category.status
        .toLowerCase()
        .includes(query.toLowerCase());
      return (
        parentCategoryMatch ||
        categoryNameMatch ||
        categorySubtitleMatch ||
        imageMatch ||
        statusMatch
      );
    });
    setFilter(result);
  }, [query, categories]);

  const handleInput = (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);
  };

  return (
    <div className="main-container mt-3">
      <div className=" ">
        <div className="flex-container pt-5">
          <h3 className="head-fon-mv">Manage Categories</h3>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>{" "}
                <li aria-current="page" className="breadcrumb-item active">
                  Manage Categories
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="card-cat">
          <div className="cat-left">
            <h4>Categories</h4>
            <button className="add-category-button" onClick={handleShowModal}>
              Add Category
            </button>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="row">
              <div className="d-flex align-items-center">
                <label className="me-2">Show</label>
                <select
                  className="form-select me-2"
                  value={itemsPerPage}
                  onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
                >
                  <option value="20">20</option>
                  <option value="40">40</option>
                  <option value="60">60</option>
                  <option value="80">80</option>
                </select>
                <label>entries</label>
              </div>
            </div>
            <div className="search-left">
              <div className="searchbar">
                <input
                  type="text"
                  className="search"
                  placeholder="Search"
                  onChange={handleInput}
                />
                <FaSearch className="search-icon" />
              </div>
            </div>
          </div>

          <div className="table-container text-end">
            {loading ? (
              <div>
                <Lottie
                  height={250}
                  width={250}
                  options={{
                    loop: true,
                    animationData: require("../Assets/Animation - 1716197548567.json"),
                    autoplay: true,
                  }}
                />
              </div>
            ) : (
              <table id="table-to-xls" className="text-center">
                <thead>
                  <tr>
                    <th style={{ width: "4%" }}>S.No</th>
                    <th>Parent Id</th>
                    <th>Name</th>
                    <th>Subtitle</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPageData.map((category, index) => (
                    <tr key={category.id}>
                       <td>{startIndex + index + 1}</td>
                      <td>{category.parent_category}</td>
                      <td>
                        <img
                          src={`https://falconsquare.in/e_commerce/image/categories/${category.image}`}
                          width={40}
                          height={40}
                          style={{ margin: "10px" }}
                          alt={category.category_name}
                        />
                        {category.category_name}
                      </td>
                      <td>{category.category_subtitle}</td>
                      <td>
                        {category.status === "1" ? (
                          <span className="active-item">Activated</span>
                        ) : (
                          <span className="inactive-item">Deactivated</span>
                        )}
                      </td>
                      <td>
                        <button
                          className="btn-edit"
                          onClick={() => handelEdit(category.id)}
                        >
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </button>
                        <button
                          className="btn-delete"
                          onClick={() => handelDelete(category.id)}
                        >
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div
          className="dataTables_paginate paging_simple_numbers p-2"
          id="dtBasicExample_paginate"
        >
          <ul className="pagination">
            <li
              className={`paginate_button page-item ${
                currentPage === 1 ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage > 1 ? prevPage - 1 : prevPage
                  )
                }
                className="page-link"
              >
                Previous
              </button>
            </li>
            {generatePaginationNumbers().map((page, index) => (
              <li
                key={index}
                className={`paginate_button page-item ${
                  page === currentPage ? "active" : ""
                }`}
              >
                <button
                  onClick={() => setCurrentPage(page)}
                  className="page-link"
                >
                  {page}
                </button>
              </li>
            ))}
            <li
              className={`paginate_button page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage < totalPages ? prevPage + 1 : prevPage
                  )
                }
                className="page-link"
              >
                Next
              </button>
            </li>
          </ul>
        </div>
        <Modal show={showModal} onHide={handleCloseModal}>
          <header className="modal-header">
            <h5 className="modal-title">Add Category</h5>
            <button
              type="button"
              aria-label="Close"
              className="close"
              onClick={handleCloseModal}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <div>
              <label>Head Category</label>
              <select
                className="form-select"
                placeholder="Select Parent Category"
                value={parentcategory}
                onChange={(e) => setParentcategory(e.target.value)}
              >
                <option value="">Select Parent Category</option>
                {categoriesload.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.category_name}
                  </option>
                ))}
              </select>
              <label>Sub Category Name</label>
              <input
                className="form-control"
                placeholder="Enter Category Name"
                value={CategoryName}
                onChange={(e) => {
                  setCategoryName(e.target.value);
                  handleInputChange(e);
                }}
              />
              <label>Category Subtitle</label>
              <input
                className="form-control"
                placeholder="Enter Category Subtitle"
                value={CategorySubtitle}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^[a-zA-Z\s]*$/.test(inputValue)) {
                    setCategorySubtitle(e.target.value);
                  }
                }}
              />

              <label>Status</label>
              <div
                role="radiogroup"
                tabIndex="-1"
                className="btn-group-toggle btn-group bv-no-focus-ring"
              >
                <label
                  className={`btn btn-outline-primary ${
                    status === "1" ? "active" : ""
                  }`}
                >
                  <input
                    type="radio"
                    value="1"
                    checked={status === "1"}
                    onChange={handleOptionChange}
                  />
                  <span>Activated</span>
                </label>
                <label
                  className={`btn btn-outline-primary ${
                    status === "0" ? "active" : ""
                  }`}
                >
                  <input
                    type="radio"
                    value="0"
                    checked={status === "0"}
                    onChange={handleOptionChange}
                  />
                  <span>Deactivated</span>
                </label>
              </div>

              <p>Image</p>
              <p>
                Please choose a square image larger than 350px*350px & smaller
                than 550px*550px.
              </p>
              <input
                type="file"
                accept="image/*"
                className="form-control"
                onChange={handleFileChange}
              />
              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleInsert}>
              Save
            </Button>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={EditshowModal} onHide={() => setEditShowModal(false)}>
          <header class="modal-header">
            <h5 class="modal-title">Update Category</h5>
            <button
              type="button"
              aria-label="Close"
              class="close"
              onClick={() => setEditShowModal(false)}
            >
              ×
            </button>
          </header>
          <Modal.Body>
            <div>
              <label>Parent Category</label>
              <select
                className="form-select"
                value={editCategory.parent_category}
                onChange={(e) =>
                  setEditCategory({
                    ...editCategory,
                    parent_category: e.target.value,
                  })
                }
              >
                <option>Select Parent Category</option>
                {categoriesload.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.category_name}
                  </option>
                ))}
              </select>
              <label>Category Name</label>
              <input
                className="form-control"
                value={editCategory.category_name}
                onChange={(e) =>
                  setEditCategory({
                    ...editCategory,
                    category_name: e.target.value,
                  })
                }
                placeholder="Enter Category Name"
              />
              <label>Category Subtitle</label>
              <input
                className="form-control"
                value={editCategory.category_subtitle}
                onChange={(e) =>
                  setEditCategory({
                    ...editCategory,
                    category_subtitle: e.target.value,
                  })
                }
                placeholder="Enter Category Subtitle"
              />
              <label>Status</label>
              <div
                role="radiogroup"
                tabIndex="-1"
                className="btn-group-toggle btn-group bv-no-focus-ring"
              >
                <label
                  className={`btn btn-outline-primary ${
                    status === "1" ? "active" : ""
                  }`}
                >
                  <input
                    type="radio"
                    value="1"
                    checked={status === "1"}
                    onChange={handleOptionChange}
                  />
                  <span>Activated</span>
                </label>
                <label
                  className={`btn btn-outline-primary ${
                    status === "0" ? "active" : ""
                  }`}
                >
                  <input
                    type="radio"
                    value="0"
                    checked={status === "0"}
                    onChange={handleOptionChange}
                  />
                  <span>Deactivated</span>
                </label>
              </div>

              {/* {editCategory.image && (
                 <img src={selectedFile} alt="Chosen file" />
              )}
              <input
                type="file"
                accept="image/*"
                className="file-input"
                onChange={(e) =>
                  setEditCategory({ ...editCategory, image: e.target.files[0] })
                }
              /> */}
              <label>Image</label>
              <div>
                <input
                  className="form-control"
                  style={{ width: "80%" }}
                  type="file"
                  name="file2"
                  id="myFile2"
                  accept=".jpg, .jpeg, .png"
                  onChange={(e) => {
                    handleFileInputChange(e);
                  }}
                />

                <input
                  className="form-control"
                  value={editCategory.image}
                  type=" hidden "
                  name="current_image"
                />
                {/* <span id="file-name">{filename}</span> */}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleUpdate}>
              update
            </Button>
            <Button variant="secondary" onClick={() => setEditShowModal(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default Categories;
