import React, { useState, useRef, useEffect } from "react";
import Lottie from "react-lottie";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaSearch, FaTrash } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import swal from "sweetalert";

function CellularMemoryStorageCapacity() {
  const [showModal, setShowModal] = useState(false);
  const [editShowModal, setEditShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const storagecapacityNameInputRef = useRef(null);
  const [cellularMemoryStorageCapacities, setCellularMemoryStorageCapacities] =
    useState([]);
  const [
    newCellularMemoryStorageCapacity,
    setNewCellularMemoryStorageCapacity,
  ] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [
    editCellularMemoryStorageCapacity,
    setEditCellularMemoryStorageCapacity,
  ] = useState({
    id: "",
    storagecapacity: "",
  });

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setNewCellularMemoryStorageCapacity("");
  };

  const handleShowEditModal = (capacity) => {
    setEditCellularMemoryStorageCapacity(capacity);
    setEditShowModal(true);
  };

  const handleCloseEditModal = () => {
    setEditShowModal(false);
    setEditCellularMemoryStorageCapacity({ id: "", storagecapacity: "" });
  };

  const filteredCapacities = cellularMemoryStorageCapacities.filter(
    (capacity) =>
      capacity.storagecapacity.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const sortedFilter = [...filteredCapacities].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };

  const getData = async () => {
    try {
      const response = await fetch(
        "https://falconsquare.in/e_commerce/cellularmemorystoragecapacity.php"
      );
      const data = await response.json();
      setCellularMemoryStorageCapacities(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleUpdate = async () => {
    try {
      const response = await fetch(
        `https://falconsquare.in/e_commerce/cellularmemorystoragecapacity.php?id=${editCellularMemoryStorageCapacity.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            storagecapacity: editCellularMemoryStorageCapacity.storagecapacity,
          }),
        }
      );

      if (response.ok) {
        console.log("Storage capacity updated successfully!");
        setCellularMemoryStorageCapacities((prevCapacities) =>
          prevCapacities.map((capacity) =>
            capacity.id === editCellularMemoryStorageCapacity.id
              ? editCellularMemoryStorageCapacity
              : capacity
          )
        );
        setEditShowModal(false);
      } else {
        console.error("Failed to update storage capacity");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    if (showModal) {
      storagecapacityNameInputRef.current.focus();
    }
  }, [showModal]);
  useEffect(() => {
    setLoading(true);
    getData()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  const handleDelete = async (id) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this storage capacity!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });

      if (shouldDelete) {
        const response = await fetch(
          `https://falconsquare.in/e_commerce/cellularmemorystoragecapacity.php?id=${id}`,
          {
            method: "DELETE",
          }
        );

        if (response.status === 200) {
          console.log("Storage capacity deleted successfully!");
          getData(); // Refresh the storage capacities after deleting
        } else {
          console.error("Failed to delete storage capacity");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleInsert = async () => {
    if (!newCellularMemoryStorageCapacity.trim()) {
      swal("warning", "Please enter storage capacity", "warning");
      return;
    }

    const formData = new FormData();
    formData.append("storagecapacity", newCellularMemoryStorageCapacity);

    try {
      const response = await fetch(
        "https://falconsquare.in/e_commerce/cellularmemorystoragecapacity.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        getData(); // Refresh the data
        handleCloseModal(); // Close the modal
        setNewCellularMemoryStorageCapacity(""); // Clear the input field
      } else {
        console.error("Failed to insert data");
      }
    } catch (error) {
      console.error("Error inserting data:", error);
    }
  };

  return (
    <div className="main-container mt-3">
      <div className="flex-container pt-5">
        <h3 className="head-fon-mv">Cellular Phone Memory Storage Capacity</h3>
      </div>

      <div className="card">
        <div className="cat-left">
          <h4>Cellular Phone Memory Storage Capacity</h4>
          <button className="add-category-button" onClick={handleShowModal}>
            Add Storage Capacity
          </button>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="row">
            <div className="d-flex align-items-center">
              <label className="me-2">Show</label>
              <select
                className="form-select me-2"
                value={itemsPerPage}
                onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
              >
                <option value="20">20</option>
                <option value="40">40</option>
                <option value="60">60</option>
                <option value="80">80</option>
              </select>
              <label>entries</label>
            </div>
          </div>
          <div className="search-left">
            <div className="searchbar">
              <input
                type="text"
                className="search"
                placeholder="Search"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <FaSearch className="search-icon" />
            </div>
          </div>
        </div>

        <div className="table-container text-end mt-3">
          {loading ? (
            <div>
              <Lottie
                height={250}
                width={250}
                options={{
                  loop: true,
                  animationData: require("../Assets/Animation - 1716197548567.json"),
                  autoplay: true,
                }}
              />
            </div>
          ) : (
            <table id="table-to-xls" className="text-center">
              <thead>
                <tr>
                  <th>S.NO</th>
                  <th>Storage Capacity</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentPageData.map((capacity, index) => (
                  <tr key={capacity.id}>
                    <td>{startIndex + index + 1}</td>
                    <td>{capacity.storagecapacity}</td>
                    <td>
                      <button
                        className="btn-edit"
                        onClick={() => handleShowEditModal(capacity)}
                      >
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </button>
                      <button
                        className="btn-delete"
                        onClick={() => handleDelete(capacity.id)}
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        <Modal show={showModal} onHide={handleCloseModal}>
          <header className="modal-header">
            <h5 className="modal-title">Add Storage Capacity</h5>
            <button
              type="button"
              aria-label="Close"
              className="close"
              onClick={handleCloseModal}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <div>
              <label>Storage Capacity</label>
              <input
                className="form-control"
                placeholder="Enter Storage Capacity"
                type="text"
                ref={storagecapacityNameInputRef}
                value={newCellularMemoryStorageCapacity}
                onChange={(e) =>
                  setNewCellularMemoryStorageCapacity(e.target.value)
                }
              />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="success" onClick={handleInsert}>
              Save
            </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={editShowModal} onHide={handleCloseEditModal}>
          <header className="modal-header">
            <h5 className="modal-title">Edit Storage Capacity</h5>
            <button
              type="button"
              aria-label="Close"
              className="close"
              onClick={handleCloseEditModal}
            >
              ×
            </button>
          </header>
          <Modal.Body>
            <div>
              <label>Storage Capacity</label>
              <input
                className="form-control"
                placeholder="Enter Storage Capacity"
                type="text"
                value={editCellularMemoryStorageCapacity.storagecapacity}
                onChange={(e) =>
                  setEditCellularMemoryStorageCapacity((prev) => ({
                    ...prev,
                    storagecapacity: e.target.value,
                  }))
                }
              />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="success" onClick={handleUpdate}>
              Update
            </Button>
            <Button variant="secondary" onClick={handleCloseEditModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <div
        className="dataTables_paginate paging_simple_numbers p-2"
        id="dtBasicExample_paginate"
      >
        <ul className="pagination">
          <li
            className={`paginate_button page-item ${
              currentPage === 1 ? "disabled" : ""
            }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage > 1 ? prevPage - 1 : prevPage
                )
              }
              className="page-link"
            >
              Previous
            </button>
          </li>
          {generatePaginationNumbers().map((page, index) => (
            <li
              key={index}
              className={`paginate_button page-item ${
                page === currentPage ? "active" : ""
              }`}
            >
              <button
                onClick={() => setCurrentPage(page)}
                className="page-link"
              >
                {page}
              </button>
            </li>
          ))}
          <li
            className={`paginate_button page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage < totalPages ? prevPage + 1 : prevPage
                )
              }
              className="page-link"
            >
              Next
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default CellularMemoryStorageCapacity;
