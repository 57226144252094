import React, { useEffect, useState } from 'react'
import './Managesection.css'
import { LuRefreshCcw } from "react-icons/lu";
import Modal from "react-bootstrap/Modal";
import ModalBody from 'react-bootstrap/esm/ModalBody';
import ModalFooter from 'react-bootstrap/esm/ModalFooter';
import Button from "react-bootstrap/Button";
function Managesection() {
  const [section, setSection] = useState("");
  const [title, setTitle] = useState("");
  const [shortdescription, setShortdescription] = useState("");
  const [producttypes, setProducttypes] = useState("");
  const [productid, setProductid] = useState("");
  const [categoryid, setCategoryid] = useState("");
  const [showModal, setShowModal] = useState(true);
  useEffect(() => {
    GetData();
  }, []);
  const handleInsert = () => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("short_description", shortdescription);
    formData.append("product_types", producttypes);
    formData.append("product_id", productid);
    formData.append("category_id", categoryid)
    fetch("https://falconsquare.in/e_commerce/featured_sections.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((data) => {
        console.log(data);
        // Handle the response from the API
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  const GetData = async () => {
    const data = await fetch("https://falconsquare.in/e_commerce/featured_sections.php");
    const response = await data.json();
    setSection(response);
    console.log(response);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  }
  return (
    <div className='main-container_1'>
      <div className='Order-head'>
        <h3>Featured Section to show products exclusively</h3>
        <p>Dashboard /
          Featured Section to show products exclusively </p>
      </div>

      <div className='card'>
        <div className='od_items_69'>
          <h6>Featured Sections of App
          </h6>
          <button className='o_btn' onClick={() => setShowModal(true)}>Add Sections</button>

        </div>
        <div className='form-fm'>

          <div className='input-md'>
            <label>Search</label>
            <input type='text' placeholder='' className='form-control' />

          </div>
          <div className='input-md'>
            <LuRefreshCcw className='refresh' />
          </div>

        </div>
        <div className="table-container text-end mt-3">
          <table id="table-to-xls" className="text-center">
            <thead>
              <tr>
                <th >O.ID</th>
                <th >Title</th>
                <th >Shortdescription</th>
                <th>Product Types</th>
                <th >Product Id</th>
              </tr>
            </thead>
            <tbody>
              {section && section.map((sections, index) => (
                <tr key={index}>
                  <td >{index + 1}</td>
                  <td >{sections.title}</td>
                  <td >{sections.shortdescription}</td>
                  <td >{sections.producttypes}</td>
                  <td >{sections.productid}</td>
                </tr>
              ))}

            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-between">
          <div className="p-2">
            <label>Per page</label>
            <select className="form-select">
              <option value="20">20</option>
              <option value="40">40</option>
              <option value="60">60</option>
              <option value="80">80</option>
            </select>
          </div>
          <div className="dataTables_paginate paging_simple_numbers p-3">
            <ul className="pagination justify-content-end">
              <li className="page-item">
                <button className="page-link">Previous</button>
              </li>
              <li className="page-item">
                <button className="page-link"></button>
              </li>
              <li className="page-item">
                <button className="page-link">Next</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <header class="modal-header">
          <h5 class="modal-title">Create / Manage featured products section
          </h5>
          <button
            type="button"
            aria-label="Close"
            class="close"
            onClick={handleCloseModal}
          >
            ×
          </button>
        </header>

        <ModalBody>
          <div className='form_1'>
            <label>Title for section</label>
            <input type='text' className='form-control' placeholder='Ex:Featured Products /Products on Sale' />
          </div>
          <div className='form_1'>
            <label>Short Description</label>
            <input type='text' className='form-control' placeholder='Ex:Weekends deal goes here' />
          </div>
          <div className='form_1'>
            <label>Category IDs ( Ex : 100,205, 360 )</label>
            <input type='text' className='form-control' placeholder='Select' />
          </div>
          <div className='form_1'>
            <label>Product Types</label>
            <input type='text' className='form-control' placeholder='Select Product Type' />
          </div>

        </ModalBody>
        <ModalFooter>
          <Button variant="success" onClick={handleInsert}>Save</Button>
          <Button variant="secondary">Cancel</Button>
        </ModalFooter>
      </Modal>

    </div>
  )
}

export default Managesection
