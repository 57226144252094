import React, { useEffect, useState } from 'react'
import './Addsection.css'
import Modal from "react-bootstrap/Modal";
import ModalBody from 'react-bootstrap/esm/ModalBody';
import ModalFooter from 'react-bootstrap/esm/ModalFooter';
import Button from "react-bootstrap/Button";
import Api from '../../Global/Api';
import Select from 'react-select';
function Addsection() {
    const [showModal, setShowModal] = useState(true);
    const [category,setCategory] = useState([]);
    const [product, setProduct] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState([]);
    console.log(product)
    const callApi = new Api();
    const handleCloseModal = () => {
        setShowModal(false)
    };
 
     const handleGetCategory = () => { 
        callApi.adminGetCategory({}).then((cat) => {
            console.log(cat);
            const category = cat.map((cat, catIndex) => ({
              value: cat.id,
              label: cat.category_name,
            }));
            setCategory(category);
          });
     };

     const handleProductGet = async (event) => {
        const id = await event.map((item) => item.value);
            callApi.adminCatProduct({ cat_id: id }).then((prod) => {
            if (prod.Success === 1) {
                const product = prod.data.map((prod, prodIndex) => ({
                value: prod.id,
                label: prod.product_name,
                }));
                setProduct(product);
            } else {
                setProduct({
                value: 0,
                label: "No Items Found",
                });
            }
            });
    };
    const handleProductSelect = async (event) => {
        setSelectedProduct(event);
    }



     useEffect(() => {
        handleGetCategory();
     },[]);


    return (
        <div>
            <Modal show={showModal} onHide={handleCloseModal}>
                <header class="modal-header">
                    <h5 class="modal-title">Create / Manage featured products section
                    </h5>
                    <button
                        type="button"
                        aria-label="Close"
                        class="close"
                        onClick={handleCloseModal}
                    >
                        ×
                    </button>
                </header>

                <ModalBody>
                    <div className='form_1'>
                      <label>Title for section</label>
                      <input type='text' className='form-control' placeholder='Ex:Featured Products /Products on Sale'/>
                    </div> 
                    <div className='form_1'>
                      <label>Short Description</label>
                      <input type='text'  className='form-control' placeholder='Ex:Weekends deal goes here'/>
                    </div> 
                    <div className='form_1'>
                      <label>Banner</label>
                      <input type='text'  className='form-control' placeholder='Ex:Add Images here'/>
                    </div> 
                    <div className='form_1'>
                      <label>Color</label>
                      <input type='text' className='form-control' placeholder='Ex: #f5f'/>
                    </div>
                    <div className='form_1'>
                      <label>Category Name</label>
                      <Select
                        options={category}
                        className="form-control"
                        placeholder="Select"
                        onChange={(e) => handleProductGet(e)}
                        isMulti
                        >
                        </Select>
                    </div>
                    <div className='form_1'>
                      <label>Product</label>
                      <Select options={product}  className='form-control'  placeholder='Select Products' isMulti/>
                    </div>
                   
                </ModalBody>
                <ModalFooter>
                   <Button variant ="success">Save</Button>
                   <Button variant="secondary">Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default Addsection