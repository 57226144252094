import React, { useEffect, useState } from "react";
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Navigate, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import Lottie from "react-lottie";
import swal from 'sweetalert';
function Taxes() {
  const [showModal, setShowModal] = useState(false);
  const [EditshowModal, setEditShowModal] = useState(false);
  const [title, setTitle] = useState("");
  const [tax, setTax] = useState([]);
  const [loading, setLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedOption, setSelectedOption] = useState("limited");
  const [editTax, setEditTax] = useState({
    tax_name: "",
    percentage: "",
    status: "",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [filter, setFilter] = useState([]);

  const [status, setStatus] = useState("1");
  const [percentage, setPercentage] = useState("");
  const navigate = useNavigate();

  const handleShowModal = () => {
    setShowModal(true);
  };

  const sortedFilter = [...filter].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };
  const handlePerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setStatus(event.target.value);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    setLoading(true);
    GetData()
    .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  const handleInput = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    const result = tax.filter((tax) => {
      const nameMatch =
        tax.tax_name &&
        tax.tax_name.toLowerCase().includes(searchQuery.toLowerCase());
      const statusMatch =
        tax.status &&
        tax.status.toString().toLowerCase().includes(searchQuery.toLowerCase());
      return nameMatch || statusMatch;
    });
    setFilter(result);
  }, [searchQuery, tax]);

  const handleInsert = () => {
    const formData = new FormData();
    formData.append("tax_name", title);
    formData.append("percentage", percentage);
    formData.append("status", status);
    fetch("https://falconsquare.in/e_commerce/addtax.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((data) => {
        console.log(data);
        GetData();
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    setShowModal(false);
  };

  const GetData = async () => {
    const data = await fetch("https://falconsquare.in/e_commerce/addtax.php");
    const response = await data.json();
    setTax(response);
  };

  const handleEdit = async (taxesId) => {
    try {
      const response = await fetch(
        `https://falconsquare.in/e_commerce/addtax.php?id=${taxesId}`
      );
      if (response.ok) {
        const taxes = await response.json();
        const categoryDetails = taxes.find((item) => item.id === taxesId);
        setEditTax(categoryDetails);
        setEditShowModal(true);
      } else {
        console.error("Failed to fetch category");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };



  const handelDelete = async (taxesId) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this tax!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });
  
      if (shouldDelete) {
        const response = await fetch(
          `https://falconsquare.in/e_commerce/addtax.php?id=${taxesId}`,
          {
            method: "DELETE",
          }
        );
        if (response.ok) {
          setTax(tax.filter((taxes) => taxes.id !== taxesId));
          swal("Deleted!", "Tax has been deleted.", "success");
        } else {
          console.error("Failed to delete taxes");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  

  const handleUpdate = async () => {
    try {
      const formData = new FormData();
      formData.append("tax_name", editTax.tax_name);
      formData.append("percentage", editTax.percentage);
      formData.append("status", editTax.status);

      const response = await fetch(
        `https://falconsquare.in/e_commerce/addtaxupdate.php?id=${editTax.id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.status === 200) {
        console.log("Category updated successfully!");
        setTax(tax.map((taxes) => (taxes.id === editTax.id ? editTax : taxes)));
        setEditShowModal(false); // Close the edit modal
        GetData(); // Get the updated data
      } else {
        console.error("Failed to update category");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="main-container mt-3">
      <div className=" ">
        <div className="flex-container pt-5">
          <h3 className="head-fon-mv">Taxes</h3>
          <div class="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              class="breadcrumb-header float-start float-lg-end"
            >
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a onClick={() => navigate("/dashboard")}>Dashboard</a>
                </li>{" "}
                <li aria-current="page" class="breadcrumb-item active">
                  Taxes
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div className="card ">
          <div className="cat-left">
            <h4>Taxes</h4>

            <button className="add-category-button" onClick={handleShowModal}>
              Add Taxes
            </button>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="row">
              <div className="d-flex align-items-center">
                <label className="me-2">Show</label>
                <select
                  className="form-select me-2"
                  value={itemsPerPage}
                  onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
                >
                  <option value="20">20</option>
                  <option value="40">40</option>
                  <option value="60">60</option>
                  <option value="80">80</option>
                </select>
                <label>entries</label>
              </div>
            </div>
            <div className="search-left">
              <div className="searchbar">
                <input
                  type="text"
                  className="search"
                  placeholder="Search"
                  onChange={handleInput}
                />
                <FaSearch className="search-icon" />
              </div>
            </div>
          </div>
          
          <div className="table-container text-end ">
          {loading ? (
              <div>
                <Lottie
                  height={250}
                  width={250}
                  options={{
                    loop: true,
                    animationData: require("../Assets/Animation - 1716197548567.json"),
                    autoplay: true,
                  }}
                />
              </div>
            ) : ( 
            <table id="table-to-xls" className="text-center">
              
              <thead>
                <tr>
                  <th style={{ width: "4%" }}>S.No</th>
                  <th>title</th>
                  <th>Percentage</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentPageData &&
                  currentPageData.map((taxes, index) => (
                    <tr key={index}>
                      <td>{startIndex + index + 1}</td>
                      <td>{taxes.tax_name}</td>
                      <td>{taxes.percentage}</td>
                      <td>
                        {taxes.status == "1" ? (
                          <span className="active-item">Activated</span>
                        ) : (
                          <span className="inactive-item">Deactivated</span>
                        )}
                      </td>
                      {/* <td>
                        <button
                          className="btn-edit"
                          onClick={() => handleEdit(taxes.id)}
                        >
                          <FaPen />
                        </button>
                        <button
                          className="btn-delete"
                          onClick={() => handelDelete(taxes.id)}
                        >
                          <FaTrash />
                        </button>
                      </td> */}
                      <td>
                        <button
                          className="btn-edit"
                          onClick={() => handleEdit(taxes.id)}
                        >
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </button>
                        <button
                          className="btn-delete"
                          onClick={() => handelDelete(taxes.id)}
                        >
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
             )}
          </div>
        </div>
        <div
          className="dataTables_paginate paging_simple_numbers p-2"
          id="dtBasicExample_paginate"
        >
          <ul className="pagination">
            <li
              className={`paginate_button page-item ${
                currentPage === 1 ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage > 1 ? prevPage - 1 : prevPage
                  )
                }
                className="page-link"
              >
                Previous
              </button>
            </li>
            {generatePaginationNumbers().map((page, index) => (
              <li
                key={index}
                className={`paginate_button page-item ${
                  page === currentPage ? "active" : ""
                }`}
              >
                <button
                  onClick={() => setCurrentPage(page)}
                  className="page-link"
                >
                  {page}
                </button>
              </li>
            ))}
            <li
              className={`paginate_button page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage < totalPages ? prevPage + 1 : prevPage
                  )
                }
                className="page-link"
              >
                Next
              </button>
            </li>
          </ul>
        </div>
        <Modal show={showModal} onHide={handleCloseModal}>
          <header class="modal-header">
            <h5 class="modal-title">Add Taxes</h5>
            <button
              type="button"
              aria-label="Close"
              class="close"
              onClick={handleCloseModal}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <div>
              <label>Title</label>
              <input
                className="form-control"
                value={title}
                placeholder="Enter tax title"
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const filteredValue = inputValue.replace(/[^a-zA-Z\s]/g, ""); // Remove non-alphabetic characters
                  setTitle(filteredValue);
                }}
              />

              <label>Percentage</label>
              <input
                className="form-control"
                value={percentage}
                placeholder="Enter Percentage"
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const filteredValue = inputValue.replace(/\D/g, ""); // Remove non-numeric characters
                  setPercentage(filteredValue);
                }}
              />

              <label>Status</label>
              <div
                role="radiogroup"
                tabIndex="-1"
                className="btn-group-toggle btn-group bv-no-focus-ring"
              >
                <label
                  className={`btn btn-outline-primary ${
                    status === "1" ? "active" : ""
                  }`}
                >
                  <input
                    type="radio"
                    value="1"
                    checked={status === "1"}
                    onChange={handleOptionChange}
                  />
                  <span>Activated</span>
                </label>
                <label
                  className={`btn btn-outline-primary ${
                    status === "0" ? "active" : ""
                  }`}
                >
                  <input
                    type="radio"
                    value="0"
                    checked={status === "0"}
                    onChange={handleOptionChange}
                  />
                  <span>Deactivated</span>
                </label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleInsert}>
              Save
            </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        {/* editable */}
        <Modal show={EditshowModal} onHide={() => setEditShowModal(false)}>
          <header class="modal-header">
            <h5 class="modal-title">Edit Taxes</h5>
            <button
              type="button"
              aria-label="Close"
              class="close"
              onClick={() => setEditShowModal(false)}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <div>
              <label>Title</label>
              <input
                className="form-control"
                value={editTax.tax_name}
                placeholder="Enter tax title"
                onChange={(e) =>
                  setEditTax({
                    ...editTax,
                    tax_name: e.target.value,
                  })
                }
              />
              <label>Percentage</label>
              <input
                className="form-control"
                value={editTax.percentage}
                placeholder="Enter Percentage"
                onChange={(e) =>
                  setEditTax({
                    ...editTax,
                    percentage: e.target.value,
                  })
                }
              />
              <label>Status</label>
              <div
                role="radiogroup"
                tabIndex="-1"
                className="btn-group-toggle btn-group bv-no-focus-ring"
              >
                <label
                  className={`btn btn-outline-primary ${
                    editTax.status === "1" ? "active" : ""
                  }`}
                >
                  <input
                    type="radio"
                    value="1"
                    checked={editTax.status === "1"}
                    onChange={(e) => {
                      setEditTax({
                        ...editTax,
                        status: e.target.value,
                      });
                      setSelectedOption("1");
                    }}
                  />
                  <span>Activated</span>
                </label>
                <label
                  className={`btn btn-outline-primary ${
                    editTax.status === "0" ? "active" : ""
                  }`}
                >
                  <input
                    type="radio"
                    value="0"
                    checked={editTax.status === "0"}
                    onChange={(e) => {
                      setEditTax({
                        ...editTax,
                        status: e.target.value,
                      });
                      setSelectedOption("0");
                    }}
                  />
                  <span>Deactivated</span>
                </label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleUpdate}>
              Update
            </Button>
            <Button variant="secondary" onClick={() => setEditShowModal(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default Taxes;
