import React, { useEffect, useState } from "react";
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
function Purchase() {
  const Navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [qty, setQty] = useState("");
  const [price, setPrice] = useState("");
  const [overallPrice, setOverallPrice] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState("");
  console.log("object", selectedProduct);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedvariant_id, setSelectedvariant_id] = useState("");
  console.log("selectedvariant_id", selectedvariant_id);
  const [purchaseData, setPurchaseData] = useState([]);
  const [date, setDate] = useState("");
  const handleQtyChange = (e) => {
    const newQty = parseInt(e.target.value);
    setQty(newQty);
    setOverallPrice(newQty * price);
  };
  const [productID, setproductID] = useState("");
  const handlePriceChange = (e) => {
    const newPrice = parseFloat(e.target.value);
    setPrice(newPrice);
    setOverallPrice(qty * newPrice);
  };
  const handleVariantChange = (e) => {
    const variantId = e.target.value;
    setSelectedvariant_id(variantId);
    const filteredProducts = products.filter(
      (product) =>
        product.variants &&
        product.variants.some((variant) => variant.id === variantId)
    );
    setSelectedProduct(filteredProducts.map((product) => product.product_name));
    console.log("lop", variantId);
  };

  const sortedFilter = purchaseData.filter((purchase) =>
    purchase.product_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };

  const getData = () => {
    return fetch("https://falconsquare.in/e_commerce/purchase.php")
      .then((response) => response.json())
      .then((data) => {
        setPurchaseData(data);
      })
      .catch((error) => {
        console.error("Error fetching purchase data:", error);
        throw error; // Propagate the error to the caller
      });
  };

  useEffect(() => {
    setLoading(true);
    getData()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("product_name", selectedProduct);
    formData.append("variant_id", selectedvariant_id);
    formData.append("overall_price", overallPrice);
    formData.append("qty", qty);
    formData.append("price", price);
    formData.append("date", date);

    fetch("https://falconsquare.in/e_commerce/purchase.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        const updateFormData = new FormData();
        updateFormData.append("id", selectedvariant_id);
        updateFormData.append("variant_stock", qty);
        updateFormData.append("price", overallPrice);
        fetch(
          `https://falconsquare.in/e_commerce/purchasetostock.php?id=${selectedvariant_id}`,
          {
            method: "POST",
            body: updateFormData,
          }
        )
          .then((response) => response.text())
          .then((result) => {
            console.log("Variant updated:", result);
            getData();
            setQty("");
            setPrice("");
            setOverallPrice("");
          })
          .catch((error) => {
            console.error("Error updating variant:", error);
          });
        getData();
      })
      .catch((error) => {
        console.error("Error submitting purchase:", error);
      });
    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    // Fetch products from the API
    fetch("https://falconsquare.in/e_commerce/productapp.php")
      .then((response) => response.json())
      .then((data) => {
        setProducts(data.categories);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });
  }, []);

  return (
    <div className="main-container mt-3">
      <div className=" ">
        <div className="flex-container pt-5">
          <h3 className="head-fon-mv">Add Purchase</h3>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a onClick={() => Navigate("/dashboard")}>Dashboard</a>
                </li>{" "}
                <li aria-current="page" className="breadcrumb-item active">
                  Purchase
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="card">
          <div className="cat-left">
            <div className="cat-left">
              <h4>Create Purchase </h4>
            </div>

            <button className="add-category-button" onClick={handleShowModal}>
              Add Purchase
            </button>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="row">
              <div className="d-flex align-items-center">
                <label className="me-2">Show</label>
                <select
                  className="form-select me-2"
                  value={itemsPerPage}
                  onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
                >
                  <option value="20">20</option>
                  <option value="40">40</option>
                  <option value="60">60</option>
                  <option value="80">80</option>
                </select>
                <label>entries</label>
              </div>
            </div>
            <div className="search-left">
              <div className="searchbar">
                <input
                  type="text"
                  className="search"
                  placeholder="Search"
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <FaSearch className="search-icon" />
              </div>
            </div>
          </div>
          <div className="table-container text-end">
            {loading ? (
              <div>
                <Lottie
                  height={250}
                  width={250}
                  options={{
                    loop: true,
                    animationData: require("../Assets/Animation - 1716197548567.json"),  
                    autoplay: true,
                  }}
                />
              </div>
            ) : (
              <table id="table-to-xls" className="text-center">
                <thead>
                  <tr>
                    <th style={{ width: "4%" }}>ID</th>
                    <th>product_name</th>
                    <th>qty</th>
                    <th>price</th>
                    <th>Overall Price</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPageData.map((purchase,index) => (
                    <tr key={purchase.purchase_id}>
                      <td>{startIndex + index + 1}</td>
                      <td>{purchase.product_name}</td>
                      <td>{purchase.qty}</td>
                      <td>{purchase.price}</td>
                      <td>{purchase.overall_price}</td>
                      <td>{purchase.date}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>

          <Modal show={showModal} onHide={handleCloseModal}>
            <header class="modal-header">
              <h5 class="modal-title">Add Purchase</h5>
              <button
                type="button"
                aria-label="Close"
                class="close"
                onClick={handleCloseModal}
              >
                ×
              </button>
            </header>

            <Modal.Body>
              <div className="form-column">
                <label>
                  Product name <i className="text-danger">*</i>
                </label>
                <select
                  className="form-select"
                  onChange={(e) => {
                    setSelectedProduct(e.target.value);
                    handleVariantChange(e);
                  }}
                >
                  <option value="">Select Product</option>
                  {products &&
                    products.map(
                      (product) =>
                        product.variants &&
                        product.variants.map((variant) => (
                          <option key={variant.id} value={variant.id}>
                            {product.product_name} - {variant.colour},{" "}
                            {variant.storage}GB Storage, {variant.variant_size}
                            Ram
                          </option>
                        ))
                    )}
                </select>
                <label>
                  Product Qty <i className="text-danger">*</i>
                </label>
                <input
                  className="form-control"
                  value={qty}
                  onChange={handleQtyChange}
                />

                <label>
                  Product Price <i className="text-danger">*</i>
                </label>
                <input
                  className="form-control"
                  value={price}
                  onChange={handlePriceChange}
                />

                <label>
                  Overall Product Price <i className="text-danger">*</i>
                </label>
                <input className="form-control" value={overallPrice} disabled />
                <label>
                 Current Date<i className="text-danger">*</i>
                </label>
                <input
                    id="date"
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    className="form-control"
                  />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="success" onClick={handleSubmit}>
                Save
              </Button>
              <Button variant="secondary" onClick={handleCloseModal}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div
          className="dataTables_paginate paging_simple_numbers p-2"
          id="dtBasicExample_paginate"
        >
          <ul className="pagination">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => setCurrentPage((prev) => prev - 1)}
              >
                Previous
              </button>
            </li>
            {generatePaginationNumbers().map((page, index) => (
              <li
                key={index}
                className={`page-item ${page === currentPage ? "active" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={() => setCurrentPage(page)}
                >
                  {page}
                </button>
              </li>
            ))}
            <li
              className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => setCurrentPage((prev) => prev + 1)}
              >
                Next
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Purchase;
