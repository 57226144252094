import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import { useNavigate } from "react-router-dom";
import { Store } from "lucide-react";
import order from "../Assets/Images/cargo.png";
import store from "../Assets/Images/store.png";
import product from "../Assets/Images/products.png";
import customer from "../Assets/Images/customer.png";
import pending from "../Assets/Images/pending.png";
import confirm from "../Assets/Images/confirm.png";
import packing from "../Assets/Images/packing.png";
import outfordelivery from "../Assets/Images/outfordelivery.png";
import shipped from "../Assets/Images/shipped.png";
import cancelorder from "../Assets/Images/cancel-order.png";
import orderreturn from "../Assets/Images/return.png";
import failed from "../Assets/Images/failed.png";
import love from "../Assets/Images/love.png";
import star from "../Assets/Images/star.png";
import { FaBusinessTime, FaUserCircle } from "react-icons/fa";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { Button } from "react-bootstrap";
import Api from "../../Global/Api";
function Dashboard() {
  const Navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [productCount, setProductCount] = useState(0);
  const [peningdetails, setpeningdetailsCount] = useState(0);
  const [confirmeddetails, setconfirmeddetailsCount] = useState(0);
  const [Packagingdetails, setPackagingdetailsCount] = useState(0);
  const [outfordeliverydetails, setoutfordeliverydetailsCount] = useState(0);
  console.log({ outfordeliverydetails: outfordeliverydetails });
  const [deliverydetails, setDeliverydetailsCount] = useState(0);
  const [canceledetails, setcanceledetailsCount] = useState(0);
  const [returneddetails, setreturnedCount] = useState(0);
  const [faileddetails, setfaileddetailsCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [topCustomers, setTopCustomers] = useState([]);
  const [mostpopularproducts, setMostpopularproducts] = useState([]);
  const [newOrders, setNewOrders] = useState([]);
  const navigate = useNavigate();
  const callApi = new Api();
  const data = [
    {
      name: "facebook",
      value: 200000000000,
    },
    {
      name: "twitter",
      value: 300000000000,
    },
    {
      name: "facebook",
      value: 400000000000,
    },
    {
      name: "instagram",
      value: 500000000000,
    },
  ];
  useEffect(() => {
    handleNewOrder();
    fetchData();
    fetchProductData();
    fetchUserData();
    fetchpeningdetails();
    fetchPackagingdetails();
    fetchoutfordeliverydetails();
    fetchconfirmeddetails();
    fetchdelivereddetails();
    fetchcanceleddetails();
    fetchreturneddetails();
    fetchfaileddetails();
    fetchMostpopularproducts();
    fetchTopCustomers();
    localStorage.removeItem("order_status");
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch("https://falconsquare.in/e_commerce/orderlist.php");
      const data = await response.json();
      setCount(data.length);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchProductData = async () => {
    try {
      const response = await fetch("https://falconsquare.in/e_commerce/product.php");
      const data = await response.json();
      setProductCount(data.length);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchpeningdetails = async () => {
    try {
      const response = await fetch(
        "https://falconsquare.in/e_commerce/peningdetails.php"
      );
      const data = await response.json();
      setpeningdetailsCount(data.length);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchconfirmeddetails = async () => {
    try {
      const response = await fetch(
        "https://falconsquare.in/e_commerce/confirmeddetails.php"
      );
      const data = await response.json();
      setconfirmeddetailsCount(data.length);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchPackagingdetails = async () => {
    try {
      const response = await fetch(
        "https://falconsquare.in/e_commerce/Packagingdetails.php"
      );
      const data = await response.json();
      setPackagingdetailsCount(data.length);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchoutfordeliverydetails = async () => {
    try {
      const response = await fetch(
        "https://falconsquare.in/e_commerce/outfordelivery.php"
      );
      const data = await response.json();
      setoutfordeliverydetailsCount(data.length);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchdelivereddetails = async () => {
    try {
      const response = await fetch(
        "https://falconsquare.in/e_commerce/deliveredstatus.php"
      );
      const data = await response.json();
      setDeliverydetailsCount(data.length);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchcanceleddetails = async () => {
    try {
      const response = await fetch(
        "https://falconsquare.in/e_commerce/canceleddetails.php"
      );
      const data = await response.json();
      setcanceledetailsCount(data.length);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchreturneddetails = async () => {
    try {
      const response = await fetch(
        "https://falconsquare.in/e_commerce/returneddetails.php"
      );
      const data = await response.json();
      setreturnedCount(data.length);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchfaileddetails = async () => {
    try {
      const response = await fetch(
        "https://falconsquare.in/e_commerce/faileddetails.php"
      );
      const data = await response.json();
      setfaileddetailsCount(data.length);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchTopCustomers = async () => {
    try {
      const response = await fetch(
        "https://falconsquare.in/e_commerce/topcustomer.php"
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setTopCustomers(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchMostpopularproducts = async () => {
    try {
      const response = await fetch(
        "https://falconsquare.in/e_commerce/mostpopularproducts.php"
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setMostpopularproducts(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleOrder = () => {
    // Navigate to the next page
    navigate("/orderlist");
  };

  const handleProduct = () => {
    // Navigate to the next page
    navigate("/manageproduct");
  };

  const handlecustomers = () => {
    // Navigate to the next page
    navigate("/mobileusers");
  };
  const fetchUserData = async () => {
    try {
      const response = await fetch("https://falconsquare.in/e_commerce/appuser.php");
      const data = await response.json();

      setUserCount(data.length);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleNewOrder = () => {
    callApi.adminDashboardOrder({}).then((res) => {
      setNewOrders(res);
    });
  };
  const handleNotify = (id) => {
    try {
      callApi.adminNotifyUpdate({ id: id }).then((res) => {
        console.log(res);
      });
    } catch {}
  };

  return (
    <div class="content container-fluid dash-container">
      <div class="card down-main ">
        <div class="card-body">
          <div class="row flex-between align-items-center g-2 mb-3">
            <div class="col-sm-6">
              <h4 class="d-flex align-items-center text-capitalize gap-10 mb-0">
                <div className="icon-container m-2">
                  <FaBusinessTime className="user-icon" />
                </div>
                Business analytics
              </h4>
            </div>
            <div class="col-sm-6 d-flex justify-content-sm-end">
              <select
                className="custom-select w-auto"
                name="statistics_type"
                id="statistics_type"
              >
                <option value="overall" selected="">
                  Overall statistics
                </option>
                <option value="today">Todays Statistics</option>
                <option value="this_month">This Months Statistics</option>
              </select>
            </div>
          </div>
          <div className="row g-2" id="order_stats">
            <div className="col-sm-6 col-lg-3">
              <a class="business-analytics card" onClick={handleOrder}>
                <h5 class="heading_subtitle">Total order</h5>
                <h2 class="heading_title">{count}</h2>

                <img src={order} className="img_con" />
              </a>
            </div>
            <div class="col-sm-6 col-lg-3">
              <a class="business-analytics card">
                <h5 class="heading_subtitle">Total Stores</h5>
                <h2 class="heading_title">10</h2>
                <img src={store} className="img_con" />
              </a>
            </div>
            <div class="col-sm-6 col-lg-3">
              <a class="business-analytics card" onClick={handleProduct}>
                <h5 class="heading_subtitle">Total Products</h5>
                <h2 class="heading_title">{productCount}</h2>
                <img src={product} className="img_con" />
              </a>
            </div>
            <div class="col-sm-6 col-lg-3">
              <a class="business-analytics card" onClick={handlecustomers}>
                <h5 class="heading_subtitle">Total Customers</h5>
                <h2 class="heading_title">{userCount}</h2>
                <img src={customer} className="img_con" />
              </a>
            </div>
            <div class="col-sm-6 col-lg-3">
              <a class="order-stats order-stats_pending">
                <div class="order-stats__content">
                  <img src={pending} />
                  <h6 class="order-stats__subtitle">Pending</h6>
                </div>
                <span class="order-stats__title">{peningdetails}</span>
              </a>
            </div>
            <div class="col-sm-6 col-lg-3">
              <a class="order-stats order-stats_confirmed">
                <div class="order-stats__content">
                  <img src={confirm} />
                  <h6 class="order-stats__subtitle">Confirmed</h6>
                </div>
                <span class="order-stats__title">{confirmeddetails}</span>
              </a>
            </div>
            <div class="col-sm-6 col-lg-3">
              <a class="order-stats order-stats_packaging">
                <div class="order-stats__content">
                  <img src={packing} />
                  <h6 class="order-stats__subtitle">Packaging</h6>
                </div>
                <span class="order-stats__title">{Packagingdetails}</span>
              </a>
            </div>
            <div class="col-sm-6 col-lg-3">
              <a class="order-stats order-stats_out-for-delivery">
                <div class="order-stats__content">
                  <img src={outfordelivery} />
                  <h6 class="order-stats__subtitle">Out for delivery</h6>
                </div>
                <span class="order-stats__title">{outfordeliverydetails}</span>
              </a>
            </div>
            <div class="col-sm-6 col-lg-3">
              <div class="order-stats order-stats_delivered cursor-pointer get-view-by-onclick">
                <div class="order-stats__content">
                  <img src={shipped} />
                  <h6 class="order-stats__subtitle">Delivered</h6>
                </div>
                <span class="order-stats__title">{deliverydetails}</span>
              </div>
            </div>
            <div class="col-sm-6 col-lg-3">
              <div class="order-stats order-stats_canceled cursor-pointer get-view-by-onclick">
                <div class="order-stats__content">
                  <img src={cancelorder} />
                  <h6 class="order-stats__subtitle">Canceled</h6>
                </div>
                <span class="order-stats__title h3">{canceledetails}</span>
              </div>
            </div>
            <div class="col-sm-6 col-lg-3">
              <div class="order-stats order-stats_returned cursor-pointer get-view-by-onclick">
                <div class="order-stats__content">
                  <img src={orderreturn} />
                  <h6 class="order-stats__subtitle">Returned</h6>
                </div>
                <span class="order-stats__title h3">{returneddetails}</span>
              </div>
            </div>
            <div class="col-sm-6 col-lg-3">
              <div class="order-stats order-stats_failed cursor-pointer get-view-by-onclick">
                <div class="order-stats__content">
                  <img src={failed} />
                  <h6 class="order-stats__subtitle">Failed to delivery</h6>
                </div>
                <span class="order-stats__title h3">{faileddetails}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-xl-4">
          <div class="card h-100 remove-card-shadow">
            <div class="card-header">
              <h4 class="d-flex align-items-center text-capitalize gap-10 mb-0">
                <img src={customer} className="img_con-card" />
                Top customer
              </h4>
            </div>
            <div className="card-body ">
              <div className="grid-card-wrap">
                <div className="row">
                  {topCustomers.map((customer) => (
                    <div
                      key={customer.user_id}
                      className="col-md-6 col-sm-6 col-xs-12 cursor-pointer p-2"
                    >
                      <div className="grid-card basic-box-shadow">
                        <div className="text-center">
                          <div className="rounded-circle  text-white d-inline-block ">
                            <FaUserCircle />
                          </div>
                        </div>
                        <h6 className="txtusername">{customer.user_name}</h6>
                        <div className="orders-count d-flex gap-1">
                          <h6 >Orders: </h6>
                          <h6>{customer.order_count}</h6>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-xl-4">
          <div class="card h-100 remove-card-shadow">
            <div class="card-header">
              <h4 class="d-flex align-items-center text-capitalize gap-10 mb-0">
                <img src={love} className="img_con-card" />
                Most Popular Products
              </h4>
            </div>
            <div class="card-body">
              <div class="grid-card-wrap">
                <div className="row">
                  {mostpopularproducts.map((product) => (
                    <div
                      key={product.user_id}
                      className="col-md-6 col-sm-6 col-xs-12 cursor-pointer"
                    >
                      <div className="grid-card basic-box-shadow" s>
                        <div>
                          <img
                            src={`https://falconsquare.in/e_commerce/image/product/${product.product_image}`}
                            width={40}
                            height={40}
                            style={{ margin: "10px" }}
                            alt={product.product_name}
                          />
                        </div>
                        <div className="fz-12 title-color text-center line--limit-1">
                          {product.product_name}
                        </div>
                        <div className="d-flex align-items-center gap-1 fz-10">
                          <span className="rating-color d-flex align-items-center font-weight-bold gap-1">
                          <img src={star} className="img_star" />
                            {product.product_starts}
                          </span>
                          <span className="d-flex align-items-center gap-10">
                            ({product.review_count} )
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-xl-4">
          <div class="card h-100 remove-card-shadow">
            <div class="card-header">
              <h4 class="d-flex align-items-center text-capitalize gap-10 mb-0">
                <img src={product} className="img_con-card" />
                Top Selling Products
              </h4>
            </div>
            <div class="card-body">
              <div class="grid-card-wrap"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="card mt-5">
        <div className="latest">
          <h5>Latest Orders </h5>
        </div>
        <div className="l_3 ">
          <div className="l_3_1">
            <h6>From & To Date</h6>

            <div className="clear_btn">
              <input
                type="date"
                className="form-control"
                defaultValue={new Date().toISOString().substr(0, 10)} // Set default value to current date
              />
              <Button variant="danger">Clear</Button>
            </div>
          </div>

          {/* <div className="l_3_1">
            <h6>Categories</h6>
            <select className="form-select">
              <option>Categories</option>
              <option>All</option>
            </select>
          </div> */}

          {/* <div className="l_3_1">
            <h6>Status</h6>
            <select className="form-select">
              <option>Pending</option>
              <option>Confirmed</option>
              <option>Packaging</option>
              <option>Out for delivery</option>
              <option>Delivered</option>
              <option>Returned</option>
              <option>Failed to deliver</option>
              <option>Canceled</option>
            </select>
          </div> */}
          {/* <div className="l_3_1">
            <h6>Search</h6>
            <input type="text" placeholder="Search" className="Search" />
          </div> */}
        </div>

        <div className="table-container text-end mt-3">
          <table id="table-to-xls" className="text-center">
            <thead className="thead-light thead-50 text-capitalize">
              <th> O.ID</th>
              <th>O.Item ID </th>
              <th>Name</th>
              {/* <th>Product</th> */}
              {/* <th>Mob</th> */}
              <th>Total</th>
              <th>Payment Method</th>
              <th>Delivery Time</th>
              {/* <th>Status</th> */}
              <th>Actions</th>
            </thead>
            <tbody>
              {newOrders.map((orders, index) => (
                <tr>
                  <td>{(index + 1).toString().padStart(2, "0")}</td>
                  <td>{orders.order_id}</td>
                  <td>{orders.user_name}</td>
                  {/* <td>{orders.order_id}</td> */}
                  {/* <td>{orders.order_id}</td> */}
                  <td>{orders.total_price}</td>
                  <td>Cash on Delivery</td>
                  <td>{orders.order_del_date}</td>
                  {/* <td>{orders.order_id}</td> */}
                  <td>
                    <Button
                      variant="primary"
                      onClick={() => {
                        handleNotify(orders.order_id);
                      console.log({ order_id: orders.address_id, })
                        Navigate("/vieworder", {
                          state: {
                            order_id: orders.order_id,
                            user_id: orders.user_id,
                            address_id: orders.address_id,
                          },
                        });
                      }}
                    >
                      View
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* <div className="d-flex justify-content-between">
          <div className="p-2">
            <label>Per page</label>
            <select className="form-select">
              <option value="20">20</option>
              <option value="40">40</option>
              <option value="60">60</option>
              <option value="80">80</option>
            </select>
          </div>
          <div className="dataTables_paginate paging_simple_numbers p-3">
            <ul className="pagination justify-content-end">
              <li className="page-item">
                <button className="page-link">Previous</button>
              </li>
              <li className="page-item">
                <button className="page-link"></button>
              </li>
              <li className="page-item">
                <button className="page-link">Next</button>
              </li>
            </ul>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Dashboard;
