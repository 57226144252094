import React, { useEffect, useState } from "react";
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from "react-router-dom";
import "./Units.css";
import Lottie from "react-lottie";
import swal from "sweetalert";
function Units() {
  const [showModal, setShowModal] = useState(false);
  const [unitname, setUnitname] = useState("");
  const [units, setUnits] = useState([]);
  const [shortcode, setShortcode] = useState("");
  const [parentunit, setParentunit] = useState("");
  const [conversion, setConversion] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [EditshowModal, setEditShowModal] = useState(false);
  const [editUnit, setEditUnit] = useState({
    unitname: "",
    shortcode: "",
    parentunit: "",
    conversion: "",
  });
  const [query, setQuery] = useState("");
  const [filteredUnits, setFilteredUnits] = useState([]);

  const Navigate = useNavigate();
  const handleShowModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    filterUnits();
  }, [query, units]);

  const handleInput = (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);
  };
  const filterUnits = () => {
    const result = units.filter((unit) => {
      return (
        (unit.unit_name &&
          unit.unit_name.toLowerCase().includes(query.toLowerCase())) ||
        (unit.short_code &&
          unit.short_code.toLowerCase().includes(query.toLowerCase())) ||
        (unit.parent_unit &&
          unit.parent_unit.toLowerCase().includes(query.toLowerCase())) ||
        (unit.conversion &&
          unit.conversion.toLowerCase().includes(query.toLowerCase()))
      );
    });
    setFilteredUnits(result);
  };

  const sortedFilter = [...filteredUnits].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };
  const handlePerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    setLoading(true);
    fetchUnits()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  const fetchUnits = async () => {
    try {
      const response = await fetch("https://falconsquare.in/e_commerce/addunit.php");
      const data = await response.json();
      setUnits(data);
    } catch (error) {
      console.error("Error fetching units:", error);
    }
  };
  const handleInsert = () => {
    const formData = new FormData();
    formData.append("unit_name", unitname);
    formData.append("short_code", shortcode);
    // formData.append("parent_unit", parentunit);
    formData.append("conversion", conversion);

    fetch("https://falconsquare.in/e_commerce/addunit.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((data) => {
        console.log(data);
        fetchUnits();
      });

    setShowModal(false);
  };
  const handelEdit = (id) => {
    const categoryDetails = units.find((item) => item.id === id);
    if (categoryDetails) {
      setEditUnit(categoryDetails);
      setEditShowModal(true);
    } else {
      console.error("Category with id", id, "not found");
    }
  };
  const handleUpdate = async () => {
    try {
      let formData = new FormData();
      formData.append("id", editUnit.id);
      formData.append("parent_category", editUnit.unitname);
      formData.append("category_name", editUnit.shortcode);
      formData.append("category_subtitle", editUnit.parentunit);
      formData.append("status", editUnit.conversion);
      console.log(formData);
      const response = await fetch(
        `https://falconsquare.in/e_commerce/addunit.php?id=${editUnit.id}`,
        {
          method: "PUT",
          body: formData,
        }
      );
      if (response.ok) {
        console.log("ress", response);
        setUnits(
          units.map((unit) => (unit.id === editUnit.id ? editUnit : unit))
        );
        setEditShowModal(false);
      } else {
        console.error("Failed to update category");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handelDelete = async (unitId) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this category!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });

      if (shouldDelete) {
        const response = await fetch(
          `https://falconsquare.in/e_commerce/addunit.php?id=${unitId}`,
          {
            method: "DELETE",
          }
        );
        if (response.ok) {
          setUnits(units.filter((unit) => unit.id !== unitId));
          swal("Deleted!", "Units has been deleted.", "success");
        } else {
          console.error("Failed to delete unit");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <div className="main-container mt-3">
      <div className=" ">
        <div className="flex-container pt-5">
          <h3 className="head-fon-mv">Manage Units</h3>
          <div class="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              class="breadcrumb-header float-start float-lg-end"
            >
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a onClick={() => Navigate("/dashboard")}>Dashboard</a>
                </li>{" "}
                <li class="breadcrumb-item">
                  <a onClick={() => Navigate("/product")}>Product</a>
                </li>{" "}
                <li aria-current="page" class="breadcrumb-item active">
                  Units
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div className="card ">
          <div className="cat-left">
            <h4>Units List</h4>

            <button className="add-category-button" onClick={handleShowModal}>
              Add Units
            </button>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="row">
              <div className="d-flex align-items-center">
                <label className="me-2">Show</label>
                <select
                  className="form-select me-2"
                  value={itemsPerPage}
                  onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
                >
                  <option value="20">20</option>
                  <option value="40">40</option>
                  <option value="60">60</option>
                  <option value="80">80</option>
                </select>
                <label>entries</label>
              </div>
            </div>
            <div className="search-left">
              <div className="searchbar">
                <input
                  type="text"
                  className="search"
                  placeholder="Search"
                  onChange={handleInput}
                />
                <FaSearch className="search-icon" />
              </div>
            </div>
          </div>
          <div className="table-container text-end ">
            {loading ? (
              <div>
                <Lottie
                  height={250}
                  width={250}
                  options={{
                    loop: true,
                    animationData: require("../Assets/Animation - 1716197548567.json"),
                    autoplay: true,
                  }}
                />
              </div>
            ) : (
              <table id="table-to-xls" className="text-center">
                <thead>
                  <tr>
                    <th style={{ width: "4%" }}>S.No</th>
                    <th>Name</th>
                    <th>Short Code</th>

                    <th>Conversion</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPageData.map((unit, index) => (
                    <tr key={index}>
                      <td>{startIndex + index + 1}</td>
                      <td>{unit.unit_name}</td>
                      <td>{unit.short_code}</td>

                      <td>{unit.conversion}</td>
                      <td className="button-container">
                        <button
                          className="btn-edit"
                          onClick={() => handelEdit(unit.id)}
                        >
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </button>
                        <button
                          className="btn-delete"
                          onClick={() => handelDelete(unit.id)}
                        >
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div
          className="dataTables_paginate paging_simple_numbers p-2"
          id="dtBasicExample_paginate"
        >
          <ul className="pagination">
            <li
              className={`paginate_button page-item ${
                currentPage === 1 ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage > 1 ? prevPage - 1 : prevPage
                  )
                }
                className="page-link"
              >
                Previous
              </button>
            </li>
            {generatePaginationNumbers().map((page, index) => (
              <li
                key={index}
                className={`paginate_button page-item ${
                  page === currentPage ? "active" : ""
                }`}
              >
                <button
                  onClick={() => setCurrentPage(page)}
                  className="page-link"
                >
                  {page}
                </button>
              </li>
            ))}
            <li
              className={`paginate_button page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage < totalPages ? prevPage + 1 : prevPage
                  )
                }
                className="page-link"
              >
                Next
              </button>
            </li>
          </ul>
        </div>

        <Modal show={showModal} onHide={handleCloseModal}>
          <header class="modal-header">
            <h5 class="modal-title">Add Unit</h5>
            <button
              type="button"
              aria-label="Close"
              class="close"
              onClick={handleCloseModal}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <div>
              <label>Unit Name</label>
              <input
                className="form-control"
                value={unitname}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^[a-zA-Z\s]*$/.test(inputValue)) {
                    setUnitname(inputValue);
                  }
                }}
                placeholder="Enter Unit Name"
              />

              <label>Short Code</label>
              <input
                className="form-control"
                placeholder="Enter Short Code"
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^[a-zA-Z\s]*$/.test(inputValue)) {
                    setShortcode(inputValue);
                  }
                }}
                value={shortcode}
              />

              <label>Conversion</label>

              <input
                className="form-control"
                value={conversion}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^[a-zA-Z\s]*$/.test(inputValue)) {
                    setConversion(inputValue);
                  }
                }}
                placeholder="Enter Conversion"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleInsert}>
              Save
            </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={EditshowModal} onHide={() => setEditShowModal(false)}>
          <header class="modal-header">
            <h5 class="modal-title">Edit Unit</h5>
            <button
              type="button"
              aria-label="Close"
              class="close"
              onClick={() => setEditShowModal(false)}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <div>
              <label>Unit Name</label>
              <input
                className="form-control"
                value={editUnit.unit_name}
                onChange={(e) =>
                  setEditUnit({
                    ...editUnit,
                    unit_name: e.target.value,
                  })
                }
                placeholder="Enter Unit Name"
              ></input>
              <label>Short Code</label>
              <input
                className="form-control"
                placeholder="Enter Short Code"
                onChange={(e) =>
                  setEditUnit({
                    ...editUnit,
                    short_code: e.target.value,
                  })
                }
                value={editUnit.short_code}
              ></input>
              <label>Parent Unit</label>
              <select
                className="form-select"
                value={editUnit.parent_unit}
                onChange={(e) =>
                  setEditUnit({
                    ...editUnit,
                    parent_unit: e.target.value,
                  })
                }
                placeholder="Enter Parent Unit"
              >
                {units.map((unit) => (
                  <option key={unit.id} value={unit.id}>
                    {unit.unit_name}
                  </option>
                ))}
              </select>
              <label>Conversion</label>

              <input
                className="form-control"
                value={editUnit.conversion}
                onChange={(e) =>
                  setEditUnit({
                    ...editUnit,
                    conversion: e.target.value,
                  })
                }
                placeholder="Enter Conversion "
              ></input>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleUpdate}>
              Update
            </Button>
            <Button variant="secondary" onClick={() => setEditShowModal(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default Units;
