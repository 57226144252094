import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/esm/ModalBody";
import ModalFooter from "react-bootstrap/esm/ModalFooter";
import "./Managepromo.css";
import Button from "react-bootstrap/Button";
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import Lottie from "react-lottie";
function Managepromo() {
  const [showModal, setShowmodal] = useState(false);
  const [promocode, setPromocode] = useState("");
  const [message, setMessage] = useState("");
  const [startdate, setStartdate] = useState("");
  const [enddate, setEnddate] = useState("");
  const [nouser, setNouser] = useState("");
  const [minorderamt, setOrderamnt] = useState("");
  const [distype, setDistype] = useState("");
  const [maxdistype, setMaxdistype] = useState("");
  const [repusage, setRepusage] = useState("");
  const [norepusage, setNorepusage] = useState("");
  const [image, setImage] = useState("");
  const [filter, setFilter] = useState([]);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [addpromo, setAddPromo] = useState([]);
  const [editpromo, setEditpromo] = useState({
    id: "",
    promo_code: "",
    message: "",
    start_date: "",
    end_date: "",
    no_user: "",
    min_orderamt: "",
    dis_type: "",
    max_distype: "",
    rep_usage: "",
    no_of_rep_usage: "",
    image: "",
  });
  console.log("data", editpromo);
  const [filename, setFilename] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [editShowModal, setEditShowModal] = useState(false);
  useEffect(() => {
    setLoading(true);
    GetData()
    .then(() => setLoading(false))
      .catch(() => setLoading(false));
    setFilename(editpromo.image);
  }, []);

  const handleInsert = () => {
    const formData = new FormData();
    formData.append("promo_code", promocode);
    formData.append("message", message);
    formData.append("start_date", startdate);
    formData.append("end_date", enddate);
    formData.append("no_user", nouser);
    formData.append("min_orderamt", minorderamt);
    formData.append("dis_type", distype);
    formData.append("max_distype", maxdistype);
    formData.append("rep_usage", repusage);
    formData.append("no_of_rep_usage", norepusage);
    formData.append("image", image);
    fetch("https://falconsquare.in/e_commerce/promocode.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((data) => {
        console.log(data);
        GetData();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    setShowmodal(false);
  };

  const GetData = async () => {
    const data = await fetch("https://falconsquare.in/e_commerce/promocode.php");
    const response = await data.json();
    setAddPromo(response);
  };
  const handleInput = (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);
    const filteredData = addpromo.filter((promo) =>
      promo.promo_code.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilter(filteredData);
  };

  const handleCloseModal = () => {
    setShowmodal(false);
  };

  const sortedFilter = [...addpromo].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };
  
  const handelDelete = async (addpromosId) => {
    try {
      const response = await fetch(
        `https://falconsquare.in/e_commerce/promocode.php?id=${addpromosId}`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        setAddPromo(
          addpromo.filter((addpromos) => addpromos.id !== addpromosId)
        );
      } else {
        console.error("Failed to delete taxes");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleEdit = async (addpromosId) => {
    try {
      const response = await fetch(
        `https://falconsquare.in/e_commerce/promocode.php?id=${addpromosId}`
      );
      if (response.ok) {
        const addpromos = await response.json();
        const categoryDetails = addpromos.find(
          (item) => item.id === addpromosId
        );
        setEditpromo(categoryDetails);
        setEditShowModal(true);
      } else {
        console.error("Failed to fetch category");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setImage(selectedFile);
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
  };
  const calculateStatus = (startDate, endDate) => {
    const currentDate = new Date();
    const formattedStartDate = new Date(startDate);
    const formattedEndDate = new Date(endDate);

    if (currentDate >= formattedStartDate && currentDate <= formattedEndDate) {
      return "Active";
    } else {
      return "Deactive";
    }
  };

  const handleUpdate = async () => {
    try {
      const formData = new FormData();
      formData.append("promo_code", editpromo.promo_code);
      formData.append("message", editpromo.message);
      formData.append("start_date", editpromo.start_date);
      formData.append("end_date", editpromo.end_date);
      formData.append("no_user", editpromo.no_user);
      formData.append("min_orderamt", editpromo.min_orderamt);
      formData.append("dis_type", editpromo.dis_type);
      formData.append("max_distype", editpromo.max_distype);
      formData.append("rep_usage", editpromo.rep_usage);
      formData.append("no_of_rep_usage", editpromo.no_of_rep_usage);
      formData.append("image", imageFile);
      formData.append("current_image", editpromo.image);

      const response = await fetch(
        `https://falconsquare.in/e_commerce/promocodeupdate.php?id=${editpromo.id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.status === 200) {
        console.log("Category updated successfully!");
        setAddPromo(
          addpromo.map((category) =>
            category.id === editpromo.id ? editpromo : addpromo
          )
        );
        setEditShowModal(false);
      } else {
        console.error("Failed to update category");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    GetData();
  };

  return (
    <div className="main-container_1">
      <div className="Order-head">
        <h3>Manage Promo Code</h3>
        <p>Dashboard / Manage Promo Code</p>
      </div>

      <div className="card">
        <div className="od_items_69">
          <h6>Promo Code</h6>
          <button className="o_btn" onClick={() => setShowmodal(true)}>
            Add Promo Code
          </button>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="row">
            <div className="d-flex align-items-center">
              <label className="me-2">Show</label>
              <select
                className="form-select me-2"
                value={itemsPerPage}
                onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
              >
                <option value="20">20</option>
                <option value="40">40</option>
                <option value="60">60</option>
                <option value="80">80</option>
              </select>
              <label>entries</label>
            </div>
          </div>
          <div className="search-left">
            <div className="searchbar">
              <input
                type="text"
                className="search"
                placeholder="Search"
                onChange={handleInput}
              />
              <FaSearch className="search-icon" />
            </div>
          </div>
        </div>
        <div className="table-container text-end ">
        {loading ? (
              <div>
                <Lottie
                  height={250}
                  width={250}
                  options={{
                    loop: true,
                     animationData: require("../Assets/Animation - 1716197548567.json"),
                    autoplay: true,
                  }}
                />
              </div>
            ) : (
          <table id="table-to-xls" className="text-center">
            <thead>
              <tr>
                <th>O.ID</th>
                <th>Promo Code</th>
                <th>Message</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>no_user</th>
                <th>min_orderamt</th>
                <th>dis_type</th>
                <th>max_distype</th>
                <th>rep_usage</th>
                <th>no_of_rep_usage</th>
                <th>image</th>
                <th>Status</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {currentPageData &&
                currentPageData.map((addpromos, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{addpromos.promo_code}</td>
                    <td>{addpromos.message}</td>
                    <td>{addpromos.start_date}</td>
                    <td>{addpromos.end_date}</td>
                    <td>{addpromos.no_user}</td>
                    <td>{addpromos.min_orderamt}</td>
                    <td>{addpromos.dis_type}</td>
                    <td>{addpromos.max_distype}</td>
                    <td>{addpromos.rep_usage}</td>
                    <td>{addpromos.no_of_rep_usage}</td>

                    <td>
                      <img
                        src={`https://falconsquare.in/e_commerce/image/promoimage/${addpromos.image}`}
                        width={40}
                        height={40}
                      />
                    </td>
                    <td>
                      <span
                        className={`status ${
                          calculateStatus(
                            addpromos.start_date,
                            addpromos.end_date
                          ) === "Active"
                            ? "active-status"
                            : "deactive-status"
                        }`}
                      >
                        {calculateStatus(
                          addpromos.start_date,
                          addpromos.end_date
                        )}
                      </span>
                    </td>
                    <td>
                      <button
                        className="btn-edit"
                        onClick={() => handleEdit(addpromos.id)}
                      >
                        <FaPen />
                      </button>
                    </td>
                    <td>
                      <button
                        className="btn-delete"
                        onClick={() => handelDelete(addpromos.id)}
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
             )}
        </div>
      
      </div>
      <div
          className="dataTables_paginate paging_simple_numbers p-2"
          id="dtBasicExample_paginate"
        >
          <ul className="pagination">
            <li
              className={`paginate_button page-item ${
                currentPage === 1 ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage > 1 ? prevPage - 1 : prevPage
                  )
                }
                className="page-link"
              >
                Previous
              </button>
            </li>
            {generatePaginationNumbers().map((page, index) => (
              <li
                key={index}
                className={`paginate_button page-item ${
                  page === currentPage ? "active" : ""
                }`}
              >
                <button
                  onClick={() => setCurrentPage(page)}
                  className="page-link"
                >
                  {page}
                </button>
              </li>
            ))}
            <li
              className={`paginate_button page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage < totalPages ? prevPage + 1 : prevPage
                  )
                }
                className="page-link"
              >
                Next
              </button>
            </li>
          </ul>
        </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <header class="modal-header">
          <h5 class="modal-title">Add Promo Code</h5>
          <button
            type="button"
            aria-label="Close"
            class="close"
            onClick={handleCloseModal}
          >
            ×
          </button>
        </header>
        <ModalBody>
          <div className="mod_head">
            <div className="mod_h">
              <div className="r_1">
                <label>Promo Code</label>
                <input
                  className="form-control"
                  placeholder="Enter Promo Code"
                  value={promocode}
                  onChange={(e) => setPromocode(e.target.value)}
                ></input>
              </div>
              <div className="r_1">
                <label>Message</label>
                <input
                  className="form-control"
                  placeholder="Enter Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
            </div>
            <div className="mod_h">
              <div className="r_1">
                <label>Start Date</label>
                <input
                  className="form-control"
                  type="date"
                  value={startdate}
                  onChange={(e) => setStartdate(e.target.value)}
                />
              </div>
              <div className="r_1">
                <label>End Date</label>
                <input
                  className="form-control"
                  type="date"
                  value={enddate}
                  onChange={(e) => setEnddate(e.target.value)}
                />
              </div>
            </div>
            <div className="mod_h">
              <div className="r_1">
                <label>No.of.Users</label>
                <input
                  className="form-control"
                  placeholder="Enter no.of users"
                  value={nouser}
                  onChange={(e) => setNouser(e.target.value)}
                />
              </div>
              <div className="r_1">
                <label>Minimum Order Amount</label>
                <input
                  className="form-control"
                  placeholder="Enter minimum order amount"
                  value={minorderamt}
                  onChange={(e) => setOrderamnt(e.target.value)}
                />
              </div>
            </div>
            <div className="mod_h">
              <div className="r_1">
                <label>Discount Type</label>
                <input
                  className="form-control"
                  placeholder="Select discount type"
                  value={distype}
                  onChange={(e) => setDistype(e.target.value)}
                />
              </div>
              <div className="r_1">
                <label>Max Discount Amount</label>
                <input
                  className="form-control"
                  placeholder="Enter max discount amount"
                  value={maxdistype}
                  onChange={(e) => setMaxdistype(e.target.value)}
                />
              </div>
            </div>
            <div>
              <label>Repeat Usage</label>
              <input
                className="form-select"
                placeholder="Select"
                value={repusage}
                onChange={(e) => setRepusage(e.target.value)}
              />
            </div>
            <div>
              <label> No Of Repeat Usage</label>
              <input
                className="form-select"
                placeholder="Select"
                value={norepusage}
                onChange={(e) => setNorepusage(e.target.value)}
              />
            </div>
          </div>
          <p>Image</p>
          <p>
            Please choose square image of larger than 350px*350px & smaller than
            550px*550px.
          </p>
          <input
            type="file"
            accept="image/*"
            className="form-control"
            onChange={handleFileChange}
          />
        </ModalBody>
        <ModalFooter>
          <Button variant="success" onClick={handleInsert}>
            Save
          </Button>
          <Button variant="secondary">Cancel</Button>
        </ModalFooter>
      </Modal>

      <Modal show={editShowModal} onHide={() => setEditShowModal(false)}>
        <header class="modal-header">
          <h5 class="modal-title">Add Promo Code</h5>
          <button
            type="button"
            aria-label="Close"
            class="close"
            onClick={() => setEditShowModal(false)}
          >
            ×
          </button>
        </header>
        <ModalBody>
          <div className="mod_head">
            <div className="mod_h">
              <div className="r_1">
                <label>Promo Code</label>
                <input
                  className="form-control"
                  placeholder="Enter Promo Code"
                  value={editpromo.promo_code}
                  onChange={(e) =>
                    setEditpromo({
                      ...editpromo,
                      promo_code: e.target.value,
                    })
                  }
                />
              </div>
              <div className="r_1">
                <label>Message</label>
                <input
                  className="form-control"
                  placeholder="Enter Message"
                  value={editpromo.message}
                  onChange={(e) =>
                    setEditpromo({
                      ...editpromo,
                      message: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="mod_h">
              <div className="r_1">
                <label>Start Date</label>
                <input
                  className="form-control"
                  type="date"
                  value={editpromo.start_date}
                  onChange={(e) =>
                    setEditpromo({
                      ...editpromo,
                      start_date: e.target.value,
                    })
                  }
                />
              </div>
              <div className="r_1">
                <label>End Date</label>
                <input
                  className="form-control"
                  type="date"
                  value={editpromo.end_date}
                  onChange={(e) =>
                    setEditpromo({
                      ...editpromo,
                      end_date: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="mod_h">
              <div className="r_1">
                <label>No.of.Users</label>
                <input
                  className="form-control"
                  placeholder="Enter no.of users"
                  value={editpromo.no_user}
                  onChange={(e) =>
                    setEditpromo({
                      ...editpromo,
                      no_user: e.target.value,
                    })
                  }
                />
              </div>
              <div className="r_1">
                <label>Minimum Order Amount</label>
                <input
                  className="form-control"
                  placeholder="Enter minimum order amount"
                  value={editpromo.min_orderamt}
                  onChange={(e) =>
                    setEditpromo({
                      ...editpromo,
                      min_orderamt: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="mod_h">
              <div className="r_1">
                <label>Discount Type</label>
                <input
                  className="form-control"
                  placeholder="Select discount type"
                  value={editpromo.dis_type}
                  onChange={(e) =>
                    setEditpromo({
                      ...editpromo,
                      dis_type: e.target.value,
                    })
                  }
                />
              </div>
              <div className="r_1">
                <label>Max Discount Amount</label>
                <input
                  className="form-control"
                  placeholder="Enter max discount amount"
                  value={editpromo.max_distype}
                  onChange={(e) =>
                    setEditpromo({
                      ...editpromo,
                      max_distype: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div>
              <label>Repeat Usage</label>
              <input
                className="form-select"
                placeholder="Select"
                value={editpromo.rep_usage}
                onChange={(e) =>
                  setEditpromo({
                    ...editpromo,
                    rep_usage: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <label> No Of Repeat Usage</label>
              <input
                className="form-select"
                placeholder="Select"
                value={editpromo.no_of_rep_usage}
                onChange={(e) =>
                  setEditpromo({
                    ...editpromo,
                    no_of_rep_usage: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <p>Image</p>
          <p>
            Please choose square image of larger than 350px*350px & smaller than
            550px*550px.
          </p>
          <input
            style={{ width: "80%" }}
            type="file"
            name="file2"
            id="myFile2"
            accept=".jpg, .jpeg, .png"
            onChange={(e) => {
              handleFileInputChange(e);
            }}
          />

          <input value={editpromo.image} type=" hidden " name="current_image" />
          <span id="file-name">{filename}</span>
        </ModalBody>
        <ModalFooter>
          <Button variant="success" onClick={handleUpdate}>
            Save
          </Button>
          <Button variant="secondary" onClick={() => setEditShowModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Managepromo;
