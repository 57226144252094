import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import Lottie from "react-lottie";
import swal from "sweetalert";
function CategoriesMaster() {
  const [showModal, setShowModal] = useState(false);
  const [categoryname, setCategoryname] = useState("");

  const [categoryImage, setCategoryImage] = useState(null);
  const [categories, setCategories] = useState([]);
  const [query, setQuery] = useState("");
  const [EditshowModal, setEditShowModal] = useState(false);
  const [filter, setFilter] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [editCategory, setEditCategory] = useState({
    category_name: "",
    variants: [], // Initialize variants as an empty array
    category_image: "",
  });
  const [image, setImage] = useState(null);
  const [filename, setFilename] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [variantOptions, setVariantOptions] = useState([]);
  const [variant, setVariant] = useState("");
  const [selectedVariants, setSelectedVariants] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const sortedFilter = [...filter].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleShowModal = () => {
    setShowModal(true);
  };
  useEffect(() => {
    GetData();
    setFilename(editCategory.category_image);
  }, [editCategory]);

  const handlePerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const GetData = async () => {
    setLoading(true);
    setError(null);

    try {
      const data = await fetch(
        "https://falconsquare.in/e_commerce/categoriesmaster.php"
      );
      if (!data.ok) {
        throw new Error(`HTTP error! Status: ${data.status}`);
      }
      const response = await data.json();
      setCategories(response);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const result = categories.filter((category) => {
      const categoryNameMatch = category.category_name
        .toLowerCase()
        .includes(query.toLowerCase());
      return categoryNameMatch;
    });
    setFilter(result);
  }, [query, categories]);

  const handleInputChange = (e) => {
    const { value } = e.target;

    // Check if the input value contains only alphabets and spaces
    const newValue = value.replace(/[^a-zA-Z ]/g, "");
    setCategoryname(newValue);
    setEditCategory({
      ...editCategory,
      category_name: newValue,
    });
  };
  const handleFileChange = (e) => {
    const selectedImage = e.target.files[0];
    const maxSize = 20 * 1024 * 1024; // 20MB in bytes

    if (selectedImage && selectedImage.size <= maxSize) {
      setCategoryImage(selectedImage);
      setErrorMessage("");
    } else {
      setErrorMessage("Please select a file smaller than 20MB");
    }
  };
  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };
  const handleInsert = () => {
    if (!categoryImage || !categoryname || selectedVariants.length === 0) {
      alert("Category name, image, and variants are required.");
      return;
    }

    const formData = new FormData();
    formData.append("category_name", categoryname);
    formData.append("category_image", categoryImage);
    formData.append("variants", selectedVariants);
    formData.append("filter", filter);
    console.log({ formData: formData });
    fetch("https://falconsquare.in/e_commerce/categoriesmaster.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((data) => {
        console.log(data);
        GetData();
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    setShowModal(false);
  };

  const handleUpdate = async () => {
    try {
      const formData = new FormData();
      formData.append("id", editCategory.id);
      formData.append("category_name", editCategory.category_name);
      formData.append("current_image", editCategory.category_image);
      if (imageFile) {
        formData.append("image", imageFile);
      }

      console.log("FormData entries:");
      for (let pair of formData.entries()) {
        console.log(pair[0] + ": " + pair[1]);
      }

      const response = await fetch(
        `https://falconsquare.in/e_commerce/categoriesmasterupdate.php?id=${editCategory.id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        console.log("Category updated successfully:", data);
        setCategories(
          categories.map((category) =>
            category.id === editCategory.id
              ? { ...category, ...editCategory }
              : category
          )
        );
        setEditShowModal(false);
        setCategoryname("");
        setCategoryImage(null);
        setEditCategory({
          category_name: "",
          variants: [],
          category_image: "",
        });
        setImageFile(null);
        setFilename("");
        GetData();
      } else {
        const errorData = await response.text();
        console.error("Failed to update category:", errorData);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handelDelete = async (id) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this category!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });

      if (shouldDelete) {
        const response = await fetch(
          `https://falconsquare.in/e_commerce/categories.php?id=${id}`,
          {
            method: "DELETE",
          }
        );
        if (response.ok) {
          setCategories(categories.filter((category) => category.id !== id));
          swal("Deleted!", "Category has been deleted.", "success");
        } else {
          console.error("Failed to delete category");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleInput = (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);
  };

  const handelEdit = async (id) => {
    try {
      const response = await fetch(
        `https://falconsquare.in/e_commerce/categoriesmaster.php?id=${id}`
      );

      if (response.ok) {
        const category = await response.json();
        const categoryDetails = category.find((item) => item.id === id);
        if (categoryDetails) {
          setEditCategory(categoryDetails);
          setEditShowModal(true);
        } else {
          console.error("Category with id", id, "not found");
        }
      } else {
        console.error("Failed to fetch category");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://falconsquare.in/e_commerce/variantmaster.php?"
      );
      const data = await response.json();
      setVariantOptions(data.map((variant) => variant.variant_name));
    };

    fetchData();
  }, []);

  const handleVarint = (e) => {
    setVariant(e.target.value);
  };
  const handleCheckboxChange = (e) => {
    const value = e.target.value;
    if (e.target.checked) {
      setSelectedVariants([...selectedVariants, value]);
    } else {
      setSelectedVariants(
        selectedVariants.filter((variant) => variant !== value)
      );
    }
  };
  return (
    <div className="main-container mt-3">
      <div className=" ">
        <div className="flex-container pt-5">
          <h3 className="head-fon-mv"> Categories</h3>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>{" "}
                <li aria-current="page" className="breadcrumb-item active">
                  Categories
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="card">
          <div className="cat-left">
            <h4>Categories</h4>
            <button className="add-category-button" onClick={handleShowModal}>
              Add Category
            </button>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="row">
              <div className="d-flex align-items-center">
                <label className="me-2">Show</label>
                <select
                  className="form-select me-2"
                  value={itemsPerPage}
                  onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
                >
                  <option value="20">20</option>
                  <option value="40">40</option>
                  <option value="60">60</option>
                  <option value="80">80</option>
                </select>
                <label>entries</label>
              </div>
            </div>
            <div className="search-left">
              <div className="searchbar">
                <input
                  type="text"
                  className="search"
                  placeholder="Search"
                  onChange={handleInput}
                />
                <FaSearch className="search-icon" />
              </div>
            </div>
          </div>

          <div className="table-container text-end">
            {loading ? (
              <div>
                <Lottie
                  height={250}
                  width={250}
                  options={{
                    loop: true,
                    animationData: require("../Assets/Animation - 1716197548567.json"),
                    autoplay: true,
                  }}
                />
              </div>
            ) : (
              <table id="table-to-xls" className="text-center">
                <thead>
                  <tr>
                    <th style={{ width: "4%" }}>S.No</th>
                    <th>Category Name</th>
                    {/* <th>Image</th> */}
                    <th>variants</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPageData.map((category, index) => (
                    <tr key={category.id}>
                      <td>{startIndex + index + 1}</td>
                      <td>
                        <img
                          src={`https://falconsquare.in/e_commerce/image/categories_master/${category.category_image}`}
                          width={40}
                          height={40}
                          style={{ margin: "10px" }}
                        />
                        {category.category_name}
                      </td>

                      <td>{category.variants}</td>
                      <td>
                        <button
                          className="btn-edit"
                          onClick={() => handelEdit(category.id)}
                        >
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </button>
                        <button
                          className="btn-delete"
                          onClick={() => handelDelete(category.id)}
                        >
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div
          className="dataTables_paginate paging_simple_numbers p-2"
          id="dtBasicExample_paginate"
        >
          <ul className="pagination">
            <li
              className={`paginate_button page-item ${
                currentPage === 1 ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage > 1 ? prevPage - 1 : prevPage
                  )
                }
                className="page-link"
              >
                Previous
              </button>
            </li>
            {generatePaginationNumbers().map((page, index) => (
              <li
                key={index}
                className={`paginate_button page-item ${
                  page === currentPage ? "active" : ""
                }`}
              >
                <button
                  onClick={() => setCurrentPage(page)}
                  className="page-link"
                >
                  {page}
                </button>
              </li>
            ))}
            <li
              className={`paginate_button page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage < totalPages ? prevPage + 1 : prevPage
                  )
                }
                className="page-link"
              >
                Next
              </button>
            </li>
          </ul>
        </div>
        <Modal show={showModal} onHide={handleCloseModal}>
          <header className="modal-header">
            <h5 className="modal-title">Add Category</h5>
            <button
              type="button"
              aria-label="Close"
              className="close"
              onClick={handleCloseModal}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <div>
              <label>
                Category Name <i className="text-danger">*</i>
              </label>
              <input
                className="form-control"
                placeholder="Enter Category Name"
                value={categoryname}
                onChange={(e) => {
                  setCategoryname(e.target.value);
                  handleInputChange(e);
                }}
              />

              <label>
                Category Image <i className="text-danger">*</i>
              </label>
              <input
                type="file"
                accept="image/*"
                className="form-control"
                onChange={handleFileChange}
              />
              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
              {image && (
                <img src={URL.createObjectURL(image)} alt="Uploaded Image" />
              )}
              <label>
                Variant <i className="text-danger">*</i>
              </label>
              {variantOptions.map((variant) => (
                <div key={variant} className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={variant}
                    value={variant}
                    checked={selectedVariants.includes(variant)}
                    onChange={handleCheckboxChange}
                  />
                  <label className="form-check-label" htmlFor={variant}>
                    {variant}
                  </label>
                </div>
              ))}
              <div>
                <label>
                  Filter <i className="text-danger">*</i>
                </label>
                <div>
                  <label>
                    <input
                      type="radio"
                      name="filter"
                      value="optional"
                      checked={filter === "optional"}
                      className="m-2"
                      onChange={handleFilterChange}
                    />
                    Optional
                  </label>
                </div>
                <div>
                  <label>
                    <input
                      type="radio"
                      name="filter"
                      value="default"
                      className="m-2"
                      checked={filter === "default"}
                      onChange={handleFilterChange}
                    />
                    Default
                  </label>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleInsert}>
              Save
            </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={EditshowModal} onHide={() => setEditShowModal(false)}>
          <header class="modal-header">
            <h5 class="modal-title">Update Category</h5>
            <button
              type="button"
              aria-label="Close"
              class="close"
              onClick={() => setEditShowModal(false)}
            >
              ×
            </button>
          </header>
          <Modal.Body>
            <div>
              <label>Category Name</label>
              <input
                className="form-control"
                value={editCategory.category_name}
                onChange={(e) => {
                  setEditCategory({
                    ...editCategory,
                    category_name: e.target.value,
                  });
                  handleInputChange(e);
                }}
                placeholder="Enter Category Name"
              />

              <label>
                Category Image <i className="text-danger">*</i>
              </label>
              <div>
                <input
                  className="form-control"
                  style={{ width: "80%" }}
                  type="file"
                  name="file2"
                  id="myFile2"
                  accept=".jpg, .jpeg, .png"
                  onChange={(e) => {
                    handleFileInputChange(e);
                  }}
                />

                <input
                  className="form-control  m-2"
                  value={editCategory.category_image}
                  type="text"
                  name="current_image"
                />
              </div>
              <label>
                Variant <i className="text-danger">*</i>
              </label>
              {variantOptions.map((variant) => (
                <div key={variant} className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={variant}
                    value={variant}
                    checked={editCategory.variants.includes(variant)}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      setEditCategory((prev) => {
                        const updatedVariants = checked
                          ? [...prev.variants, variant]
                          : prev.variants.filter((v) => v !== variant);
                        return { ...prev, variants: updatedVariants };
                      });
                    }}
                  />
                  <label className="form-check-label" htmlFor={variant}>
                    {variant}
                  </label>
                </div>
              ))}
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="success" onClick={handleUpdate}>
              update
            </Button>
            <Button variant="secondary" onClick={() => setEditShowModal(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default CategoriesMaster;
