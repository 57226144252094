import React, { useEffect, useState } from "react";
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import Lottie from "react-lottie";
import "./Stock.css";

function Stock() {
  const [stock, setStock] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState("");
  const [variants, setVariants] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const Navigate = useNavigate();

  const fetchStock = async () => {
    try {
      const response = await fetch("https://falconsquare.in/e_commerce/productapp.php");
      const data = await response.json();
      setStock(data.categories);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchStock()
    .then(() => setLoading(false))
    .catch(() => setLoading(false));
  }, []);

  const handleShowModal = async (id) => {
    setSelectedProductId(id);
    setShowModal(true);

    try {
      const response = await fetch(`https://falconsquare.in/e_commerce/productapp.php`);
      const data = await response.json();

      const variantsData = data.categories.filter(
        (category) => category.id === id
      );

      const allVariants = variantsData.reduce((acc, category) => {
        if (category.variants) {
          acc.push(...category.variants);
        }
        return acc;
      }, []);
      setVariants(allVariants);
    } catch (error) {
      console.error("Error fetching variants data:", error);
    }
  };

  const filteredStock = stock.filter(
    (item) =>
      item.product_name &&
      item.product_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = filteredStock.slice(startIndex, endIndex);

  const totalPages = Math.ceil(filteredStock.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="main-container mt-3">
      <div className=" ">
        <div className="flex-container pt-5">
          <h3 className="head-fon-mv">Manage Stock</h3>
          <div class="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              class="breadcrumb-header float-start float-lg-end"
            >
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a onClick={() => Navigate("/dashboard")}>Dashboard</a>
                </li>{" "}
                <li class="breadcrumb-item">
                  <a onClick={() => Navigate("/product")}>Product</a>
                </li>{" "}
                <li aria-current="page" class="breadcrumb-item active">
                  Stock
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div className="card ">
          <div className="cat-left">
            <h4>Stock List</h4>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="row">
              <div className="d-flex align-items-center">
                <label className="me-2">Show</label>
                <select
                  className="form-select me-2"
                  value={itemsPerPage}
                  onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
                >
                  <option value="20">20</option>
                  <option value="40">40</option>
                  <option value="60">60</option>
                  <option value="80">80</option>
                </select>
                <label>entries</label>
              </div>
            </div>
            <div className="search-left">
              <div className="searchbar">
                <input
                  type="text"
                  className="search"
                  placeholder="Search"
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <FaSearch className="search-icon" />
              </div>
            </div>
          </div>
          <div className="table-container text-end ">
          {loading ? (
              <div>
                <Lottie
                  height={250}
                  width={250}
                  options={{
                    loop: true,
                    animationData: require("../Assets/Animation - 1716197548567.json"),
                    autoplay: true,
                  }}
                />
              </div>
            ) : (
            <table id="table-to-xls" className="text-center">
              <thead>
                <tr>
                  <th>Product_Id</th>
                  <th>Product_Name</th>
                  <th>Unit</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentPageData.map((item, index) => (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td>{item.product_name}</td>
                    <td>{item.unit}</td>
                    <td
                      className="view_c"
                      onClick={() => handleShowModal(item.id)}
                    >
                      <FontAwesomeIcon icon={faEye} className="black" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
              )}
          </div>
          <div
            className="dataTables_paginate paging_simple_numbers p-2"
            id="dtBasicExample_paginate"
          >
            <ul className="pagination">
              <li
                className={`paginate_button page-item ${
                  currentPage === 1 ? "disabled" : ""
                }`}
              >
                <button
                  onClick={() =>
                    setCurrentPage((prevPage) =>
                      prevPage > 1 ? prevPage - 1 : prevPage
                    )
                  }
                  className="page-link"
                >
                  Previous
                </button>
              </li>
              {generatePaginationNumbers().map((page, index) => (
                <li
                  key={index}
                  className={`paginate_button page-item ${
                    page === currentPage ? "active" : ""
                  }`}
                >
                  <button
                    onClick={() => setCurrentPage(page)}
                    className="page-link"
                  >
                    {page}
                  </button>
                </li>
              ))}
              <li
                className={`paginate_button page-item ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              >
                <button
                  onClick={() =>
                    setCurrentPage((prevPage) =>
                      prevPage < totalPages ? prevPage + 1 : prevPage
                    )
                  }
                  className="page-link"
                >
                  Next
                </button>
              </li>
            </ul>
          </div>
        </div>
        <Modal show={showModal} onHide={handleCloseModal}>
          <header class="modal-header">
            <h5 class="modal-title">Stocks</h5>
            <button
              type="button"
              aria-label="Close"
              class="close"
              onClick={handleCloseModal}
            >
              ×
            </button>
          </header>
          <Modal.Body>
            {variants.length > 0 ? (
              variants.map((variant, index) => (
                <div key={index}>
                  <div className="card stock p-3 m-3">
                    <p>Color: {variant.colour}</p>
                    <p>Variant Size: {variant.variant_size}</p>
                    <p>Variant storage: {variant.storage}</p>
                  </div>
                </div>
              ))
            ) : (
              <p>No variants found for this product.</p>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default Stock;
