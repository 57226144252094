import React, { useEffect, useState } from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

function Offerimage() {
  const [showModal, setShowModal] = useState(true);
  const Navigate = useNavigate();
  const handleCloseModal = () => {
    Navigate("/manageofferimage ");
  };
  return (
    <div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <header class="modal-header">
          <h5 class="modal-title">Add New Offers Images here</h5>
          <button
            type="button"
            aria-label="Close"
            class="close"
            onClick={handleCloseModal}
          >
            ×
          </button>
        </header>

        <Modal.Body>
          <div>
            <p>
              Offer Image <i className="text-danger">*</i>
            </p>
            <input
              type="file"
              accept="image/*"
              className="form-control"
            ></input>

            <label>
              Position <i className="text-danger">*</i>
            </label>
            <select
              className="form-select"
              placeholder="Enter Position"
            ></select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleCloseModal}>
            Save
          </Button>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Offerimage;
