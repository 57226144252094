import React, { useState,useRef, useEffect } from "react";
import Lottie from "react-lottie";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaSearch, FaTrash } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import swal from "sweetalert";

function Mobilephonehuman() {
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [interfaceinput, setInterfaceinput] = useState([]);
  const [newInterfaceInput, setNewInterfaceInput] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [editInterfaceInput, setEditInterfaceInput] = useState({
    id: "",
    interface_input: "",
  });
  const interfaceinputNameInputRef = useRef(null);
  const [editShowModal, setEditShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setNewInterfaceInput("");
  };

  const getData = async () => {
    try {
      const response = await fetch("https://falconsquare.in/e_commerce/interfaceinput.php");
      const data = await response.json();
      setInterfaceinput(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    getData()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);
    
  useEffect(() => {
    if (showModal) {
      interfaceinputNameInputRef.current.focus();
    }
  }, [showModal]);

  const handleDelete = async (id) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this interface input!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });

      if (shouldDelete) {
        const response = await fetch(
          `https://falconsquare.in/e_commerce/interfaceinput.php?id=${id}`,
          {
            method: "DELETE",
          }
        );

        if (response.status === 200) {
          console.log("Interface input deleted successfully!");
          getData(); // Refresh the data after deleting
        } else {
          console.error("Failed to delete interface input");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleEdit = async (id) => {
    try {
      const response = await fetch(`https://falconsquare.in/e_commerce/interfaceinput.php?id=${id}`);
      if (response.ok) {
        const data = await response.json();
        const inputDetails = data.find((item) => item.id === id);
        setEditInterfaceInput(inputDetails); // Set both id and interface input
        setEditShowModal(true);
      } else {
        console.error("Failed to fetch interface input");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdate = async () => {
    try {
      const response = await fetch(
        `https://falconsquare.in/e_commerce/interfaceinput.php?id=${editInterfaceInput.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ interface_input: editInterfaceInput.interface_input }),
        }
      );

      if (response.ok) {
        console.log("Interface input updated successfully!");
        setInterfaceinput((prevInterfaceInputs) =>
          prevInterfaceInputs.map((input) =>
            input.id === editInterfaceInput.id ? editInterfaceInput : input
          )
        );
        setEditShowModal(false);
      } else {
        console.error("Failed to update interface input");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleInsert = async () => {
    if (!newInterfaceInput.trim()) {
      swal("warning", "Please enter Interface Input", "warning");
      return;
    }
    const formData = new FormData();
    formData.append("interface_input", newInterfaceInput);

    try {
      const response = await fetch("https://falconsquare.in/e_commerce/interfaceinput.php", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        getData(); // Refresh the data
        handleCloseModal(); // Close the modal
      } else {
        console.error("Failed to insert data");
      }
    } catch (error) {
      console.error("Error inserting data:", error);
    }
  };

  const filteredCapacities = interfaceinput.filter((input) =>
    input.interface_input.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const sortedFilter = [...filteredCapacities].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = sortedFilter.slice(startIndex, endIndex);
  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };

  return (
    <div className="main-container mt-3">
      <div className="flex-container pt-5">
        <h3 className="head-fon-mv">Interface Input</h3>
      </div>

      <div className="card">
        <div className="cat-left">
          <h4>Interface Input</h4>
          <button className="add-category-button" onClick={handleShowModal}>
            Add Interface Input
          </button>
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <div className="row">
            <div className="d-flex align-items-center">
              <label className="me-2">Show</label>
              <select
                className="form-select me-2"
                value={itemsPerPage}
                onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
              >
                <option value="20">20</option>
                <option value="40">40</option>
                <option value="60">60</option>
                <option value="80">80</option>
              </select>
              <label>entries</label>
            </div>
          </div>
          <div className="search-left">
            <div className="searchbar">
              <input
                type="text"
                className="search"
                placeholder="Search"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <FaSearch className="search-icon" />
            </div>
          </div>
        </div>

        <div className="table-container text-end mt-3">
          {loading ? (
            <div>
              <Lottie
                height={250}
                width={250}
                options={{
                  loop: true,
                  animationData: require("../Assets/Animation - 1716197548567.json"),
                  autoplay: true,
                }}
              />
            </div>
          ) : (
            <table id="table-to-xls" className="text-center">
            <thead>
              <tr>
                <th>S.NO</th>
                <th>Interface Input</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentPageData.map((input, index) => (
                <tr key={input.id}>
                 <td>{startIndex + index + 1}</td>
                  <td>{input.interface_input}</td>
                  <td>
                    <button className="btn-edit" onClick={() => handleEdit(input.id)}>
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                    <button className="btn-delete" onClick={() => handleDelete(input.id)}>
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          )}
        </div>

        <Modal show={showModal} onHide={handleCloseModal}>
          <header className="modal-header">
            <h5 className="modal-title">Add Interface Input</h5>
            <button
              type="button"
              aria-label="Close"
              className="close"
              onClick={handleCloseModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </header>
          <Modal.Body>
            <form>
              <div className="form-group">
                <label htmlFor="interfaceInput">Interface Input</label>
                <input
                  type="text"
                  className="form-control"
                  id="interfaceInput"
                  value={newInterfaceInput}
                  ref={interfaceinputNameInputRef}
                  onChange={(e) => setNewInterfaceInput(e.target.value)}
                />
              </div>
             
            </form>
          </Modal.Body>
          <Modal.Footer>
          <Button variant="success" onClick={handleInsert}>
            Save
          </Button>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
        </Modal.Footer>
        </Modal>

        <Modal show={editShowModal} onHide={() => setEditShowModal(false)}>
          <header className="modal-header">
            <h5 className="modal-title">Edit Interface Input</h5>
            <button
              type="button"
              aria-label="Close"
              className="close"
              onClick={() => setEditShowModal(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </header>
          <Modal.Body>
            <form>
              <div className="form-group">
                <label htmlFor="editInterfaceInput">Interface Input</label>
                <input
                  type="text"
                  className="form-control"
                  id="editInterfaceInput"
                  value={editInterfaceInput.interface_input}
                  onChange={(e) =>
                    setEditInterfaceInput({ ...editInterfaceInput, interface_input: e.target.value })
                  }
                />
              </div>
              
            </form>
          </Modal.Body>
          <Modal.Footer>
              <Button variant="success" onClick={handleUpdate}>
                Update
              </Button>
              <Button
                variant="secondary"
                onClick={() => setEditShowModal(false)}
              >
                Cancel
              </Button>
            </Modal.Footer>
        </Modal>

       
      </div>
      <div
          className="dataTables_paginate paging_simple_numbers p-2"
          id="dtBasicExample_paginate"
        >
          <ul className="pagination">
            <li
              className={`paginate_button page-item ${
                currentPage === 1 ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage > 1 ? prevPage - 1 : prevPage
                  )
                }
                className="page-link"
              >
                Previous
              </button>
            </li>
            {generatePaginationNumbers().map((page, index) => (
              <li
                key={index}
                className={`paginate_button page-item ${
                  page === currentPage ? "active" : ""
                }`}
              >
                <button
                  onClick={() => setCurrentPage(page)}
                  className="page-link"
                >
                  {page}
                </button>
              </li>
            ))}
            <li
              className={`paginate_button page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage < totalPages ? prevPage + 1 : prevPage
                  )
                }
                className="page-link"
              >
                Next
              </button>
            </li>
          </ul>
        </div>
    </div>
  );
}

export default Mobilephonehuman;
