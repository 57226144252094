import React, { useState, useEffect } from "react";
import Lottie from "react-lottie";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaSearch, FaTrash } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import swal from "sweetalert";

function Deliverycharge() {
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [deliverycharge, setCellularphone] = useState([]);
  const [newdeliverycharge, setNewdeliverycharge] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [newDeliveryCharge, setNewDeliveryCharge] = useState("");
  const [selectedChargeType, setSelectedChargeType] = useState("");
  const [km, setKm] = useState("");
  const [kmCharges, setKmCharges] = useState("");
  const [value, setValue] = useState("");
  const [valueCharges, setValueCharges] = useState("");
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setNewdeliverycharge("");
  };

  const handleRadioChange = (event) => {
    setSelectedChargeType(event.target.value);
  };
  const Getdata = async () => {
    try {
      const response = await fetch(
        "https://falconsquare.in/e_commerce/deliverycharge.php"
      );
      const data = await response.json();
      setCellularphone(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    Getdata()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  const handleInsert = async () => {
    const deliveryChargeData = {
      distancecharge: selectedChargeType === "distance" ? 1 : 0,
      valuecharge: selectedChargeType === "value" ? 1 : 0,
      distance: km ?? 0,
      chargeperkm: kmCharges ?? 0,
      value: value ?? 0,
      chargepervalueunit: valueCharges ?? 0,
    };
    console.log("Request Payload:", deliveryChargeData); // Log request payload
    try {
      const response = await fetch(
        "https://falconsquare.in/e_commerce/deliverycharge.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(deliveryChargeData),
        }
      );

      if (response.ok) {
        Getdata(); // Refresh the data
        handleCloseModal(); // Close the modal
      } else {
        console.error("Failed to insert data");
      }
    } catch (error) {
      console.error("Error inserting data:", error);
    }
  };


  const handleDelete = async (id) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this variant!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });

      if (shouldDelete) {
        const response = await fetch(
          `https://falconsquare.in/e_commerce/deliverycharge.php?id=${id}`,
          {
            method: "DELETE",
          }
        );

        if (response.status === 200) {
          console.log("deliverycharge deleted successfully!");
          Getdata(); // Refresh the variants after deleting
        } else {
          console.error("Failed to delete variant");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const filteredCapacities = deliverycharge.filter((capacity) =>
    capacity.distancecharge.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const sortedFilter = [...filteredCapacities].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };
  return (
    <div className="main-container mt-3">
      <div className="flex-container pt-5">
        <h3 className="head-fon-mv"> Delivery Charge</h3>
      </div>

      <div className="card">
        <div className="cat-left">
          <h4> Delivery Charge</h4>
          <button className="add-category-button" onClick={handleShowModal}>
            Add Delivery Charge
          </button>
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <div className="row">
            <div className="d-flex align-items-center">
              <label className="me-2">Show</label>
              <select
                className="form-select me-2"
                value={itemsPerPage}
                onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
              >
                <option value="20">20</option>
                <option value="40">40</option>
                <option value="60">60</option>
                <option value="80">80</option>
              </select>
              <label>entries</label>
            </div>
          </div>
          <div className="search-left">
            <div className="searchbar">
              <input
                type="text"
                className="search"
                placeholder="Search"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <FaSearch className="search-icon" />
            </div>
          </div>
        </div>

        <div className="table-container text-end mt-3">
          {loading ? (
            <div>
              <Lottie
                height={250}
                width={250}
                options={{
                  loop: true,
                  animationData: require("../Assets/Animation - 1716197548567.json"),
                  autoplay: true,
                }}
              />
            </div>
          ) : (
            <table id="table-to-xls" className="text-center">
            <thead>
              <tr>
                <th>S.NO</th>
                <th>Distance Charge</th>
                <th>Value Charge</th>
                <th>Distance</th>
                <th>Charge Per Km</th>
                <th>Value</th>
                <th>Charge Per Value Unit</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentPageData.map((capacity, index) => (
                <tr key={capacity.id}>
                  <td>{startIndex + index + 1}</td>
                  <td>{capacity.distancecharge}</td>
                  <td>{capacity.valuecharge}</td>
                  <td>{capacity.distance}</td>
                  <td>{capacity.chargeperkm}</td>
                  <td>{capacity.value}</td>
                  <td>{capacity.chargepervalueunit}</td>
                  <td>
                    <button className="btn-edit">
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                    <button
                      className="btn-delete"
                      onClick={() => handleDelete(capacity.id)}
                    >
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          )}
        </div>

        <Modal show={showModal} onHide={handleCloseModal}>
          <header className="modal-header">
            <h5 className="modal-title">Add Delivery Charge</h5>
            <button
              type="button"
              aria-label="Close"
              className="close"
              onClick={handleCloseModal}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <div>
              <label className="p-2">Distance Charge</label>
              <input
                type="radio"
                id="distance"
                name="location_type"
                value="distance"
                checked={selectedChargeType === "distance"}
                onChange={handleRadioChange}
              />
            </div>
            {selectedChargeType === "distance" && (
              <div>
                <div>
                  <label htmlFor="km" className="p-2">
                    Distance (km)
                  </label>
                  <input
                    type="text"
                    id="km"
                    className="form-control"
                    placeholder="Enter distance in km"
                    value={km}
                    onChange={(e) => setKm(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="kmCharges" className="p-2">
                    Charge per km
                  </label>
                  <input
                    type="text"
                    id="kmCharges"
                    className="form-control"
                    placeholder="Enter charge per km"
                    value={kmCharges}
                    onChange={(e) => setKmCharges(e.target.value)}
                  />
                </div>
              </div>
            )}
            <div>
              <label className="p-2">Value Charge</label>
              <input
                type="radio"
                id="value"
                name="location_type"
                value="value"
                checked={selectedChargeType === "value"}
                onChange={handleRadioChange}
              />
            </div>
            {selectedChargeType === "value" && (
              <div>
                <div>
                  <label htmlFor="value" className="p-2">
                    Value
                  </label>
                  <input
                    type="text"
                    id="value"
                    className="form-control"
                    placeholder="Enter value"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="valueCharges" className="p-2">
                    Charge per value unit
                  </label>
                  <input
                    type="text"
                    id="valueCharges"
                    className="form-control"
                    placeholder="Enter charge per value unit"
                    value={valueCharges}
                    onChange={(e) => setValueCharges(e.target.value)}
                  />
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleInsert}>
              Save
            </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div
        className="dataTables_paginate paging_simple_numbers p-2"
        id="dtBasicExample_paginate"
      >
        <ul className="pagination">
          <li
            className={`paginate_button page-item ${
              currentPage === 1 ? "disabled" : ""
            }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage > 1 ? prevPage - 1 : prevPage
                )
              }
              className="page-link"
            >
              Previous
            </button>
          </li>
          {generatePaginationNumbers().map((page, index) => (
            <li
              key={index}
              className={`paginate_button page-item ${
                page === currentPage ? "active" : ""
              }`}
            >
              <button
                onClick={() => setCurrentPage(page)}
                className="page-link"
              >
                {page}
              </button>
            </li>
          ))}
          <li
            className={`paginate_button page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage < totalPages ? prevPage + 1 : prevPage
                )
              }
              className="page-link"
            >
              Next
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Deliverycharge;
