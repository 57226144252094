import React, { useState, useRef, useEffect } from "react";
import Lottie from "react-lottie";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaSearch, FaTrash } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import swal from "sweetalert";

function Shopmaster() {
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [shop, setShop] = useState([]);
  const [newShop, setNewShop] = useState("");
  const [newGst, setNewGst] = useState("");
  const [newLogo, setNewLogo] = useState(null);
  const [newEmail, setNewEmail] = useState("");
  const [newPhone, setNewPhone] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [editShop, setEditShop] = useState({
    id: "",
    shop_name: "",
    gst: "",
    logo: "",
    email: "",
    phoneno: "",
  });
  const [editShowModal, setEditShowModal] = useState(false);
  const shopNameInputRef = useRef(null);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setNewShop("");
    setNewGst("");
    setNewLogo(null);
    setNewEmail("");
    setNewPhone("");
  };

  const getData = async () => {
    try {
      const response = await fetch("https://falconsquare.in/e_commerce/shop.php");
      const data = await response.json();
      setShop(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    getData()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (showModal) {
      shopNameInputRef.current.focus();
    }
  }, [showModal]);

  const handleInsert = async () => {
    if (!newShop.trim() || !newGst.trim() || !newEmail.trim() || !newPhone.trim()) {
      swal("Warning", "Please fill in all fields", "warning");
      return;
    }
    const formData = new FormData();
    formData.append("shop_name", newShop);
    formData.append("gst", newGst);
    formData.append("logo", newLogo);
    formData.append("email", newEmail);
    formData.append("phoneno", newPhone);

    try {
      const response = await fetch("https://falconsquare.in/e_commerce/shop.php", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        getData(); // Refresh the data
        handleCloseModal(); // Close the modal
      } else {
        console.error("Failed to insert data");
      }
    } catch (error) {
      console.error("Error inserting data:", error);
    }
  };

  const handleEditModal = (id, shop_name, gst, logo, email, phoneno) => {
    setEditShop({ id, shop_name, gst, logo, email, phoneno });
    setEditShowModal(true);
  };

  const handleUpdate = async () => {
    try {
      const response = await fetch(
        `https://falconsquare.in/e_commerce/shop.php?id=${editShop.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            shop_name: editShop.shop_name,
            gst: editShop.gst,
            logo: editShop.logo,
            email: editShop.email,
            phoneno: editShop.phoneno,
          }),
        }
      );

      if (response.ok) {
        console.log("Shop updated successfully!");
        getData(); // Refresh the data after updating
        setEditShowModal(false); // Close the edit modal
      } else {
        console.error("Failed to update shop");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this shop!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });

      if (shouldDelete) {
        const response = await fetch(
          `https://falconsquare.in/e_commerce/shop.php?id=${id}`,
          {
            method: "DELETE",
          }
        );

        if (response.status === 200) {
          console.log("Shop deleted successfully!");
          getData(); // Refresh the data after deleting
        } else {
          console.error("Failed to delete shop");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const filteredShops = shop.filter((item) =>
    item.shop_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const sortedFilter = [...filteredShops].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };

  return (
    <div className="main-container mt-3">
      <div className="flex-container pt-5">
        <h3 className="head-fon-mv">Shop</h3>
      </div>

      <div className="card">
        <div className="cat-left">
          <h4>Shop</h4>
          <button className="add-category-button" onClick={handleShowModal}>
            Add Shop
          </button>
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <div className="row">
            <div className="d-flex align-items-center">
              <label className="me-2">Show</label>
              <select
                className="form-select me-2"
                value={itemsPerPage}
                onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
              >
                <option value="20">20</option>
                <option value="40">40</option>
                <option value="60">60</option>
                <option value="80">80</option>
              </select>
              <label>entries</label>
            </div>
          </div>
          <div className="search-left">
            <div className="searchbar">
              <input
                type="text"
                className="search"
                placeholder="Search"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <FaSearch className="search-icon" />
            </div>
          </div>
        </div>

        <div className="table-container text-end mt-3">
          {loading ? (
            <div>
              <Lottie
                height={250}
                width={250}
                options={{
                  loop: true,
                  animationData: require("../Assets/Animation - 1716197548567.json"),
                  autoplay: true,
                }}
              />
            </div>
          ) : (
            <table id="table-to-xls" className="text-center">
              <thead>
                <tr>
                  <th>S.NO</th>
                  <th>Shop Name</th>
                  <th>GST</th>
                  <th>Logo</th>
                  <th>Email</th>
                  <th>Phone No</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentPageData.map((item, index) => (
                  <tr key={item.id}>
                    <td>{startIndex + index + 1}</td>
                    <td>{item.shop_name}</td>
                    <td>{item.gst}</td>
                    <td>
                      <img src={item.logo} alt="Logo" width={50} height={50} />
                    </td>
                    <td>{item.email}</td>
                    <td>{item.phoneno}</td>
                    <td>
                      <div className="d-flex justify-content-center">
                        <button
                          className="edit-button"
                          onClick={() =>
                            handleEditModal(
                              item.id,
                              item.shop_name,
                              item.gst,
                              item.logo,
                              item.email,
                              item.phoneno
                            )
                          }
                        >
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </button>
                        <button
                          className="del-button"
                          onClick={() => handleDelete(item.id)}
                        >
                          <FaTrash />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>

      <div className="pagination justify-content-center mt-3">
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <button className="page-link" onClick={() => setCurrentPage(1)}>
              {"<<"}
            </button>
          </li>
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            >
              {"<"}
            </button>
          </li>
          {generatePaginationNumbers().map((page, index) => (
            <li
              key={index}
              className={`page-item ${
                currentPage === page ? "active" : ""
              }`}
            >
              {page === "..." ? (
                <span className="page-link">...</span>
              ) : (
                <button
                  className="page-link"
                  onClick={() => setCurrentPage(page)}
                >
                  {page}
                </button>
              )}
            </li>
          ))}
          <li
            className={`page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button
              className="page-link"
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
            >
              {">"}
            </button>
          </li>
          <li
            className={`page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button
              className="page-link"
              onClick={() => setCurrentPage(totalPages)}
            >
              {">>"}
            </button>
          </li>
        </ul>
      </div>

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        animation={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Shop</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Shop Name:</label>
            <input
              type="text"
              className="form-control"
              ref={shopNameInputRef}
              value={newShop}
              onChange={(e) => setNewShop(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>GST:</label>
            <input
              type="text"
              className="form-control"
              value={newGst}
              onChange={(e) => setNewGst(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Logo:</label>
            <input
              type="file"
              className="form-control"
              onChange={(e) => setNewLogo(e.target.files[0])}
              required
            />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              className="form-control"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Phone No:</label>
            <input
              type="tel"
              className="form-control"
              value={newPhone}
              onChange={(e) => setNewPhone(e.target.value)}
              required
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleInsert}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={editShowModal}
        onHide={() => setEditShowModal(false)}
        animation={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Shop</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Shop Name:</label>
            <input
              type="text"
              className="form-control"
              value={editShop.shop_name}
              onChange={(e) =>
                setEditShop({ ...editShop, shop_name: e.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label>GST:</label>
            <input
              type="text"
              className="form-control"
              value={editShop.gst}
              onChange={(e) =>
                setEditShop({ ...editShop, gst: e.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label>Logo:</label>
            <input
              type="file"
              className="form-control"
              onChange={(e) =>
                setEditShop({ ...editShop, logo: e.target.files[0] })
              }
            />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              className="form-control"
              value={editShop.email}
              onChange={(e) =>
                setEditShop({ ...editShop, email: e.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label>Phone No:</label>
            <input
              type="tel"
              className="form-control"
              value={editShop.phoneno}
              onChange={(e) =>
                setEditShop({ ...editShop, phoneno: e.target.value })
              }
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setEditShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Shopmaster;
