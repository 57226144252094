import React, { useState,useRef, useEffect } from "react";
import Lottie from "react-lottie";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaSearch, FaTrash } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import swal from "sweetalert";

function Ramsize() {
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [ram_size, setCameraresolution] = useState([]);
  const [newram_size, setNewram_size] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [editRamSizeId, setEditRamSizeId] = useState("");
  const [editRamSize, setEditRamSize] = useState({
    id: "",
    ram_size: "",
  });  const ramsizeNameInputRef = useRef(null);
  const [EditshowModal, setEditShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setNewram_size("");
  };

  const Getdata = async () => {
    try {
      const response = await fetch("https://falconsquare.in/e_commerce/ramsize.php");
      const data = await response.json();
      setCameraresolution(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    Getdata()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (showModal) {
      ramsizeNameInputRef.current.focus();
    }
  }, [showModal]);

  const handleInsert = async () => {
    if (!newram_size.trim()) {
      swal("warning", "Please enter RAM size", "warning");
      return;
    }
  
    const formData = new FormData();
    formData.append("ram_size", newram_size);
  
    try {
      const response = await fetch("https://falconsquare.in/e_commerce/ramsize.php", {
        method: "POST",
        body: formData,
      });
  
      if (response.ok) {
        Getdata(); // Refresh the data
        handleCloseModal(); // Close the modal
        setNewram_size(""); // Clear the input field
      } else {
        console.error("Failed to insert data");
      }
    } catch (error) {
      console.error("Error inserting data:", error);
    }
  };
  

  const handleEdit = async (ramSizeId) => {
    try {
      const response = await fetch(
        `https://falconsquare.in/e_commerce/ramsize.php?id=${ramSizeId}`
      );
      if (response.ok) {
        const ramSizes = await response.json();
        const ramSizeDetails = ramSizes.find((item) => item.id === ramSizeId);
        setEditRamSize(ramSizeDetails.ram_size);
        setEditRamSizeId(ramSizeDetails.id);
        setEditShowModal(true);
      } else {
        console.error("Failed to fetch RAM size");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleUpdate = async () => {
    try {
      const response = await fetch(
        `https://falconsquare.in/e_commerce/ramsize.php?id=${editRamSizeId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ram_size: editRamSize }),
        }
      );
  
      if (response.ok) {
        console.log("RAM size updated successfully!");
        Getdata(); // Refresh the data
        setEditShowModal(false);
        setEditRamSize("");
        setEditRamSizeId("");
      } else {
        console.error("Failed to update RAM size");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  

  
  const handleDelete = async (id) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this ramsize!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });

      if (shouldDelete) {
        const response = await fetch(
          `https://falconsquare.in/e_commerce/ramsize.php?id=${id}`,
          {
            method: "DELETE",
          }
        );

        if (response.status === 200) {
          console.log("camera Resolution deleted successfully!");
          Getdata(); // Refresh the variants after deleting
        } else {
          console.error("Failed to delete variant");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const filteredCapacities = ram_size.filter((capacity) =>
    capacity.ram_size.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const sortedFilter = [...filteredCapacities].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };
  return (
    <div className="main-container mt-3">
      <div className="flex-container pt-5">
        <h3 className="head-fon-mv">Ram Size</h3>
      </div>

      <div className="card">
        <div className="cat-left">
          <h4>Ram Size</h4>
          <button className="add-category-button" onClick={handleShowModal}>
            Add Ram Size
          </button>
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <div className="row">
            <div className="d-flex align-items-center">
              <label className="me-2">Show</label>
              <select
                className="form-select me-2"
                value={itemsPerPage}
                onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
              >
                <option value="20">20</option>
                <option value="40">40</option>
                <option value="60">60</option>
                <option value="80">80</option>
              </select>
              <label>entries</label>
            </div>
          </div>
          <div className="search-left">
            <div className="searchbar">
              <input
                type="text"
                className="search"
                placeholder="Search"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <FaSearch className="search-icon" />
            </div>
          </div>
        </div>

        <div className="table-container text-end mt-3">
          {loading ? (
            <div>
              <Lottie
                height={250}
                width={250}
                options={{
                  loop: true,
                  animationData: require("../Assets/Animation - 1716197548567.json"),
                  autoplay: true,
                }}
              />
            </div>
          ) : (
            <table id="table-to-xls" className="text-center">
            <thead>
              <tr>
                <th>S.NO</th>
                <th>Ram Size</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentPageData.map((capacity, index) => (
                <tr key={capacity.id}>
                   <td>{startIndex + index + 1}</td>
                  <td>{capacity.ram_size}</td>
                  <td>
                    <button
                      className="btn-edit"
                      onClick={() => handleEdit(capacity.id)}
                    >
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                    <button
                      className="btn-delete"
                      onClick={() => handleDelete(capacity.id)}
                    >
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          )}
        </div>

        <Modal show={showModal} onHide={handleCloseModal}>
          <header className="modal-header">
            <h5 className="modal-title">Add Ram Size</h5>
            <button
              type="button"
              aria-label="Close"
              className="close"
              onClick={handleCloseModal}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <div>
              <label>Ram Size</label>
              <input
                className="form-control"
                placeholder="Enter Ram Size"
                value={newram_size}
                ref={ramsizeNameInputRef}
                onChange={(e) => setNewram_size(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleInsert}>
              Save
            </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={EditshowModal} onHide={() => setEditShowModal(false)}>
          <header className="modal-header">
            <h5 className="modal-title">Edit RAM Size</h5>
            <button
              type="button"
              aria-label="Close"
              className="close"
              onClick={() => setEditShowModal(false)}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <div>
              <label>RAM Size</label>
              <input
                className="form-control"
                placeholder="Enter RAM Size"
                value={editRamSize}
                onChange={(e) => setEditRamSize(e.target.value)}
              />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="success" onClick={handleUpdate}>
              Update
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setEditShowModal(false);
              }}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div
        className="dataTables_paginate paging_simple_numbers p-2"
        id="dtBasicExample_paginate"
      >
        <ul className="pagination">
          <li
            className={`paginate_button page-item ${
              currentPage === 1 ? "disabled" : ""
            }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage > 1 ? prevPage - 1 : prevPage
                )
              }
              className="page-link"
            >
              Previous
            </button>
          </li>
          {generatePaginationNumbers().map((page, index) => (
            <li
              key={index}
              className={`paginate_button page-item ${
                page === currentPage ? "active" : ""
              }`}
            >
              <button
                onClick={() => setCurrentPage(page)}
                className="page-link"
              >
                {page}
              </button>
            </li>
          ))}
          <li
            className={`paginate_button page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage < totalPages ? prevPage + 1 : prevPage
                )
              }
              className="page-link"
            >
              Next
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Ramsize;
