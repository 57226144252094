import React, { useState } from 'react'
import Modal from "react-bootstrap/Modal";
import ModalBody from 'react-bootstrap/esm/ModalBody'
import ModalFooter from 'react-bootstrap/esm/ModalFooter'
import './Addpromo.css'
import { useNavigate } from 'react-router-dom';
import Button from "react-bootstrap/Button";

function Addpromo() {
  const Navigate = useNavigate();
  const [showModal, setShowmodal] = useState(true);
  const [image, setImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    const maxSize = 100 * 1024; // 100KB in bytes

    if (selectedImage && selectedImage.size > maxSize) {
      setErrorMessage("File size exceeds 100KB limit. Please choose a smaller file.");
      e.target.value = ""; // Reset file input
    } else {
      setImage(selectedImage);
      setErrorMessage("");
    }
  };
  const handleCloseModal = () => {
    Navigate("/managepromo")
  };
  return (
    <div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <header class="modal-header">
          <h5 class="modal-title">Add Promo Code</h5>
          <button
            type="button"
            aria-label="Close"
            class="close"
            onClick={handleCloseModal}
          >
            ×
          </button>
        </header>
        <ModalBody>
          <div className='mod_head'>
            <div className='mod_h'>
              <div className='r_1'>
                <label>Promo Code</label>
                <input
                  className="form-control"
                  placeholder="Enter Promo Code"
                ></input>
              </div>
              <div className='r_1'>
                <label>Message</label>
                <input className="form-control"
                  placeholder="Enter Message"></input>
              </div>

            </div>
            <div className='mod_h'>
              <div className='r_1'>
                <label>Start Date</label>
                <input type='date' className="form-control"
                  placeholder="dd/mm/yyyy"></input>
              </div>
              <div className='r_1'>
                <label>End Date</label>
                <input type='date' className="form-control"
                  placeholder="dd/mm/yyyy"></input>
              </div>
            </div>
            <div className='mod_h'>
              <div className='r_1'>
                <label>No.of.Users</label>
                <input type='number' className="form-control"
                  placeholder="Enter no.of users"></input>
              </div>
              <div className='r_1'>
                <label>Minimum Order Amount</label>
                <input className="form-control"
                  placeholder="Enter minimum order amount"></input>
              </div>
            </div>
            <div className='mod_h'>
              <div className='r_1'>
                <label>Discount Type</label>
                <input className="form-control"
                  placeholder="Select discount type"></input>
              </div>
              <div className='r_1'>
                <label>Max Discount Amount</label>
                <input type='number' className="form-control"
                  placeholder="Enter max discount amount"></input>
              </div>
            </div>
            <div>
              <label>Repeat Usage</label>
              <select
                className="form-select"
                placeholder="Select"
              ></select>
            </div>
          </div>
          <p>Image</p>
          <input
            type="file"
            accept="image/*"
            className="form-control"
            onChange={handleImageChange}
            disabled={!!errorMessage} // Disable file input if there's an error
          />
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
          {image && <img src={URL.createObjectURL(image)} alt="Uploaded Image" />}
        </ModalBody>
        <ModalFooter>
          <Button variant="success" >
            Save
          </Button>
          <Button variant="secondary" >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default Addpromo
