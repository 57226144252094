import React from "react";
import "./Header.css";
import falcon from "../Assets/applelogo.png";
import { IoSunnyOutline } from "react-icons/io5";
import { TbWorld } from "react-icons/tb";
import { BiRefresh } from "react-icons/bi";
import { FaRegMessage } from "react-icons/fa6";
import { IoMdNotificationsOutline } from "react-icons/io";
import { RiArrowDropDownLine } from "react-icons/ri";
import { useState } from "react";

function Header() {
  const [message, setMessage] = useState();
  const [notification, setNotification] = useState();
  const loginName = localStorage.getItem("loginName");
  const handleMessage = () => {
    setMessage(!message);
  };
  const handleNotification = () => {
    setNotification(!notification);
  };

  return (
    <div className="head_head">
      <div className="head_right">
        <div className="msg">
          <FaRegMessage className="head_img_m" onClick={handleMessage} />
          {message && (
            <div className="dropdwn_msg">
              <p>Message</p>
              <p>No New Message</p>
            </div>
          )}
        </div>
        <div className="msg">
          <IoMdNotificationsOutline
            className="head_img"
            onClick={handleNotification}
          />
          {notification && (
            <div className="dropdwn_msg_1">
              <p>You have received new order</p>
              <p>Order #1789 has been Payment Pending</p>
            </div>
          )}
        </div>

        <div className="user-img d-flex align-items-center">
          <div className="flex-grow-1 text-center mt-3 m-2">
            <p>{loginName}</p>
          </div>
          <div className="avatar avatar-md">
            <img src="https://egrocer.wrteam.me/images/admin_logo.png" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
