import React, { useEffect, useState } from "react";
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Navigate, useNavigate } from "react-router-dom";

function Flatoffers() {
  const [showModal, setShowModal] = useState(false);
  const [EditshowModal, setEditShowModal] = useState(false);
  const [discount, setDiscount] = useState("");
  const [startDate, setStartDate] = useState("");
  const Navigate = useNavigate();
  const [endDate, setEndDate] = useState("");
  const [Product, setProduct] = useState([]);
  const [flatOffers, setFlatOffers] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState("");
  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleProductSelect = (e) => {
    setSelectedProductId(e.target.value);
  };
  useEffect(() => {
    fetchCatData();
  }, []);

 

  const handleDiscountChange = (e) => {
    setDiscount(e.target.value);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };
  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("product_id", selectedProductId);
    formData.append("discount", discount);
    formData.append("start_date", startDate);
    formData.append("end_date", endDate);
    
    fetch("https://falconsquare.in/e_commerce/flatoffer.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setShowModal(false);
        fetchFlatOffers();
       
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  
  const handleDelete = (offerId) => {
    fetch(`https://falconsquare.in/e_commerce/flatoffer.php?id=${offerId}`, {
      method: "DELETE",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        // Remove the deleted offer from the state
        setFlatOffers(flatOffers.filter((offer) => offer.id !== offerId));
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  
  const fetchCatData = async () => {
    try {
      const response = await fetch(
        "https://falconsquare.in/e_commerce/product.php"
      );
      const data = await response.json();
      setProduct(data);
    } catch (error) {
      console.error("Error", error);
    }
  };
  const fetchFlatOffers = () => {
    fetch("https://falconsquare.in/e_commerce/flatoffer.php")
      .then((response) => response.json())
      .then((data) => setFlatOffers(data))
      .catch((error) => console.error("Error fetching flat offers:", error));
  };
  
  useEffect(() => {
    // Initial fetch when component mounts
    fetchFlatOffers();
  }, []);
  
  // You can call fetchFlatOffers whenever you need to fetch the flat offers data again
  
  return (
    <div className="main-container mt-3">
      <div className=" ">
        <div className="flex-container pt-5">
          <h3 className="head-fon-mv">Flat Offers</h3>
          <div class="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              class="breadcrumb-header float-start float-lg-end"
            >
             <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a onClick={() => Navigate("/dashboard")}>Dashboard</a>
                </li>{" "}
                <li aria-current="page" class="breadcrumb-item active">
                Flat Offers
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div className="card ">
          <div className="cat-left">
            <h4>Flat Offers</h4>

            <button className="add-category-button" onClick={handleShowModal}>
              Add Flat Offers{" "}
            </button>
          </div>
          <div className="search-left">
            <div className="searchbar">
              <input type="text" className="search" placeholder="Search" />
              <FaSearch className="search-icon" />
            </div>
          </div>
          <div className="table-container text-end mt-3">
            <table id="table-to-xls" className="text-center">
              <thead>
                <tr>
                  <th style={{ width: "4%" }}>ID</th>
                  <th>Product Id </th>
                  <th>Discount</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {flatOffers.map((offer, index) => (
                  <tr key={index}>
                     <td>{index + 1}</td>
                    <td>{offer.product_id}</td>
                    <td>{offer.discount}</td>
                    <td>{offer.start_date}</td>
                    <td>{offer.end_date}</td>
                    <td>
                      <FaPen />
                    </td>
                    <td>
                    <FaTrash onClick={() => handleDelete(offer.id)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="p-2">
            <label>Per page</label>
            <select className="form-select">
              <option value="20">20</option>
              <option value="40">40</option>
              <option value="60">60</option>
              <option value="80">80</option>
            </select>
          </div>
          <div className="dataTables_paginate paging_simple_numbers p-3">
            <ul className="pagination justify-content-end">
              <li className="page-item">
                <button className="page-link">Previous</button>
              </li>
              <li className="page-item">
                <button className="page-link"></button>
              </li>
              <li className="page-item">
                <button className="page-link">Next</button>
              </li>
            </ul>
          </div>
        </div>
        <Modal show={showModal}>
          <header class="modal-header">
            <h5 class="modal-title">Add Flat Offers</h5>
            <button
              type="button"
              aria-label="Close"
              class="close"
              onClick={handleCloseModal}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <div>
              <label>Product Id</label>
              <input
                className="form-control"
                placeholder="Enter Product Id"
                value={selectedProductId}
                readOnly
              />
              <label>Product Name</label>
              <select
                className="form-select"
                onChange={handleProductSelect}
                value={selectedProductId}
              >
                <option value="">Select Product Name</option>
                {Product.map((Product) => (
                  <option key={Product.id} value={Product.id}>
                    {Product.product_name}
                  </option>
                ))}
              </select>
              <label>Discount</label>
              <input
                className="form-control"
                placeholder="Enter Discount"
                onChange={handleDiscountChange}
                value={discount}
              />
              <label>Start Date</label>
              <input
                type="date"
                className="form-control"
                placeholder="Enter Start Date"
                onChange={handleStartDateChange}
                value={startDate}
              />
              <label>End Date</label>
              <input
                type="date"
                className="form-control"
                placeholder="Enter End Date"
                onChange={handleEndDateChange}
                value={endDate}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleSubmit}>
              Save
            </Button>
            <Button variant="secondary">Cancel</Button>
          </Modal.Footer>
        </Modal>

        {/* editable */}
        <Modal show={EditshowModal} onHide={() => setEditShowModal(false)}>
          <header class="modal-header">
            <h5 class="modal-title">Add Flat Offers</h5>
            <button
              type="button"
              aria-label="Close"
              class="close"
              onClick={() => setEditShowModal(false)}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <div>
              <label>Product Id</label>
              <input className="form-control" placeholder="Enter tax title" />
              <label>Percentage</label>
              <input className="form-control" placeholder="Enter Percentage" />
              <label>Status</label>
              <input className="form-control" placeholder="Enter Percentage" />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success">Update</Button>
            <Button variant="secondary" onClick={() => setEditShowModal(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default Flatoffers;
