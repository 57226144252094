import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
function Systemusers() {
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [EditshowModal, setEditShowModal] = useState(false);

  const Navigate = useNavigate();
  const [confrimpassword, setConfirmpassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUserName] = useState("");
  const [email, setEmail] = useState(""); // Initialize email state
  const [role, setRole] = useState(""); // Initialize role state
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [editUser, setEditUser] = useState({
    id: "",
    username: "",
    email: "",
    role: "",
    password: "",
    confirm_password: "",
  });
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState([]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleconfirmPasswordVisibility = () => {
    setConfirmpassword(!confrimpassword);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = filter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(filter.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };
  const handleInput = (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    setLoading(true);
    GetData()
    .then(() => setLoading(false))
    .catch(() => setLoading(false));
  }, []);
  useEffect(() => {
    const result = user.filter((user) => {
      return user.username.toLowerCase().includes(query.toLowerCase());
    });
    setFilter(result);
  }, [query, user]);

  const handleInsert = () => {
    const formData = new FormData();
    formData.append("username", username);
    formData.append("email", email); // Add email to FormData
    formData.append("role", role); // Add role to FormData
    formData.append("password", password);
    formData.append("confirm_password", confirmPassword);
    fetch("https://falconsquare.in/e_commerce/systemuser.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((data) => {
        console.log(data);
        GetData();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    setShowModal(false);
  };

  const GetData = async () => {
    const data = await fetch("https://falconsquare.in/e_commerce/systemuser.php");
    const response = await data.json();
    setUser(response);
  };

  const handleDelete = async (usersId) => {
    // console.log("ygh",usersId);
    try {
      const response = await fetch(
        `https://falconsquare.in/e_commerce/systemuser.php?id=${usersId}`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        setUser(user.filter((users) => users.id !== usersId));
      } else {
        console.error("Failed to delete taxes");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Check if the input value contains only alphabets and spaces
    const newValue = value.replace(/[^a-zA-Z\s]/g, "");

    setUserName(newValue);
  };

  const handleEdit = async (usersId) => {
    console.log("loh", usersId);
    try {
      const response = await fetch(
        `https://falconsquare.in/e_commerce/systemuser.php?id=${usersId}`
      );

      if (response.ok) {
        const category = await response.json();
        const categoryDetails = category.find((item) => item.id === usersId);

        setEditUser(categoryDetails);
        setEditShowModal(true);
      } else {
        console.error("Failed to fetch category");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleupdate = async () => {
    try {
      const formData = new FormData();
      formData.append("id", editUser.id);
      formData.append("username", editUser.username);
      formData.append("email", editUser.email);
      formData.append("role", editUser.role);
      formData.append("password", editUser.password);
      formData.append("confirm_password", editUser.confirm_password);

      const response = await fetch(
        `https://falconsquare.in/e_commerce/systemuserupdate.php?id=${editUser.id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.status === 200) {
        console.log("Category updated successfully!");
        setUser(
          user.map((category) =>
            category.id === editUser.id ? editUser.id : user
          )
        );
        setEditShowModal(false);
      } else {
        console.error("Failed to update category");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    GetData();
  };

  return (
    <div className="main-container mt-3">
      <div className=" ">
        <div className="flex-container pt-5">
          <h3 className="head-fon-mv">System Users</h3>
          <div class="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              class="breadcrumb-header float-start float-lg-end"
            >
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a onClick={() => Navigate("/dashboard")}>Dashboard</a>
                </li>{" "}
                <li aria-current="page" class="breadcrumb-item active">
                  System Users
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div className="card ">
          <div className="cat-left">
            <h4>System Users</h4>

            <button className="add-category-button" onClick={handleShowModal}>
              Add Users
            </button>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="row">
              <div className="d-flex align-items-center">
                <label className="me-2">Show</label>
                <select
                  className="form-select me-2"
                  value={itemsPerPage}
                  onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
                >
                  <option value="20">20</option>
                  <option value="40">40</option>
                  <option value="60">60</option>
                  <option value="80">80</option>
                </select>
                <label>entries</label>
              </div>
            </div>
            <div className="search-left">
              <div className="searchbar">
                <input
                  type="text"
                  className="search"
                  placeholder="Search"
                  onChange={(e) => handleInput(e)}
                />
                <FaSearch className="search-icon" />
              </div>
            </div>
          </div>
          <div className="table-container text-end ">
          {loading ? (
              <div>
                <Lottie
                  height={250}
                  width={250}
                  options={{
                    loop: true,
                    animationData: require("../Assets/Animation - 1716197548567.json"),
                    autoplay: true,
                  }}
                />
              </div>
            ) : (
            <table id="table-to-xls" className="text-center">
              <thead>
                <tr>
                  <th style={{ width: "4%" }}>ID</th>
                  <th>Username</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Password</th>
                  <th>Confirm_password</th>

                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {currentPageData &&
                  currentPageData.map((users, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{users.username}</td>
                      <td>{users.email}</td>
                      <td>{users.role}</td>
                      <td>{users.password}</td>
                      <td>{users.confirm_password}</td>
                      <td>
                        <button
                          className="btn-edit"
                          onClick={() => handleEdit(users.id)}
                        >
                          <FaPen />
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn-delete"
                          onClick={() => handleDelete(users.id)}
                        >
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
               )}
          </div>
        </div>
        <div
          className="dataTables_paginate paging_simple_numbers p-2"
          id="dtBasicExample_paginate"
        >
          <ul className="pagination">
            <li
              className={`paginate_button page-item ${
                currentPage === 1 ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage > 1 ? prevPage - 1 : prevPage
                  )
                }
                className="page-link"
              >
                Previous
              </button>
            </li>
            {generatePaginationNumbers().map((page, index) => (
              <li
                key={index}
                className={`paginate_button page-item ${
                  page === currentPage ? "active" : ""
                }`}
              >
                <button
                  onClick={() => setCurrentPage(page)}
                  className="page-link"
                >
                  {page}
                </button>
              </li>
            ))}
            <li
              className={`paginate_button page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage < totalPages ? prevPage + 1 : prevPage
                  )
                }
                className="page-link"
              >
                Next
              </button>
            </li>
          </ul>
        </div>
        <Modal show={showModal} onHide={handleCloseModal}>
          <header class="modal-header">
            <h5 class="modal-title">Create System User</h5>
            <button
              type="button"
              aria-label="Close"
              class="close"
              onClick={handleCloseModal}
            >
              ×
            </button>
          </header>
          <Modal.Body>
            <div>
              <label>
                Username <i className="text-danger">*</i>
              </label>
              <input
                className="form-control"
                placeholder="Enter Username"
                value={username}
                onChange={(e) => setUserName(e.target.value)}
              />
              <label>
                Email <i className="text-danger">*</i>
              </label>
              <input
                className="form-control"
                placeholder="Enter Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label>
                Role <i className="text-danger">*</i>
              </label>
              <select
                className="form-select"
                placeholder="Enter Role"
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                {/* Add options for different roles */}
                <option value="admin">Admin</option>
                <option value="user">User</option>
              </select>
              <div className="form-group">
                <label htmlFor="password">
                  Password <span className="text-danger text-xs">*</span>
                </label>
                <div className="input-group">
                  <input
                    id="password"
                    placeholder="Password"
                    required
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button
                    type="button"
                    className="btn btn-primary font-bold"
                    onClick={togglePasswordVisibility}
                  >
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                  </button>
                </div>
              </div>{" "}
              <div className="form-group">
                <label htmlFor="confirmPassword">
                  Confirm Password{" "}
                  <span className="text-danger text-xs">*</span>
                </label>
                <div className="input-group">
                  <input
                    id="confirmPassword"
                    placeholder="Confirm Password"
                    required
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <button
                    type="button"
                    className="btn btn-primary font-bold"
                    onClick={toggleconfirmPasswordVisibility}
                  >
                    <FontAwesomeIcon
                      icon={confrimpassword ? faEyeSlash : faEye}
                    />
                  </button>
                </div>
              </div>{" "}
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="success" onClick={handleInsert}>
              Save
            </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        {/* edit modal */}
        <Modal show={EditshowModal} onHide={() => setEditShowModal(false)}>
          <header class="modal-header">
            <h5 class="modal-title">Create System User</h5>
            <button
              type="button"
              aria-label="Close"
              class="close"
              onClick={() => setEditShowModal(false)}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <div>
              <label>
                Username <i className="text-danger">*</i>
              </label>
              <input
                className="form-control"
                placeholder="Enter Username"
                value={editUser.username}
                onChange={(e) =>
                  setEditUser({
                    ...editUser,
                    username: e.target.value,
                  })
                }
              ></input>
              <label>
                Email <i className="text-danger">*</i>
              </label>
              <input
                className="form-control"
                placeholder="Enter Email"
                value={editUser.email}
                onChange={(e) =>
                  setEditUser({
                    ...editUser,
                    email: e.target.value,
                  })
                }
              ></input>
              <label>
                Role <i className="text-danger">*</i>
              </label>
              <input
                className="form-select"
                placeholder="Enter Role "
                value={editUser.role}
                onChange={(e) =>
                  setEditUser({
                    ...editUser,
                    role: e.target.value,
                  })
                }
              />
              <div className="form-group">
                <label htmlFor="password">
                  Password <span className="text-danger text-xs">*</span>
                </label>
                <div className="input-group">
                  <input
                    id="password"
                    placeholder="Password"
                    required
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    value={editUser.password}
                    onChange={(e) =>
                      setEditUser({
                        ...editUser,
                        password: e.target.value,
                      })
                    }
                  />
                  <button
                    type="button"
                    className="btn btn-primary font-bold"
                    onClick={togglePasswordVisibility}
                  >
                    {/* <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} /> */}
                  </button>
                </div>
              </div>{" "}
              <div className="form-group">
                <label htmlFor="password">
                  Confirm Password{" "}
                  <span className="text-danger text-xs">*</span>
                </label>
                <div className="input-group">
                  <input
                    id="password"
                    placeholder="Confirm Password"
                    required
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    value={editUser.confirm_password}
                    onChange={(e) =>
                      setEditUser({
                        ...editUser,
                        confirm_password: e.target.value,
                      })
                    }
                  />
                  <button
                    type="button"
                    className="btn btn-primary font-bold"
                    onClick={toggleconfirmPasswordVisibility}
                  >
                    {/* <FontAwesomeIcon
                      icon={confrimpassword ? faEyeSlash : faEye}
                    /> */}
                  </button>
                </div>
              </div>{" "}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleupdate}>
              Save
            </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default Systemusers;
