import React, { useEffect, useState } from "react";
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";

function Manageimage() {
  const [showModal, setShowModal] = useState(false);
  const [offerimage, setOfferimage] = useState("");
  const [position, setPosition] = useState("");
  const [sectionposition, setSectionPosition] = useState("");
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [editdata, setEditData] = useState({
    id: "",
    position: "",
    section_position: "",
    offer_image: ""
  });
  const Navigate = useNavigate();
  const handleShowModal = () => {
    setShowModal(true);
  };
  const [editshowModal, setEditShowModal] = useState(false);

  const [filename, setFilename] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const handleCloseModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    setLoading(true);
    GetData()
    .then(() => setLoading(false))
    .catch(() => setLoading(false));
    setFilename(editdata.offer_image)
  }, []);
  const handleInsert = () => {
    const formData = new FormData();
    formData.append('offer_image', offerimage);
    formData.append('position', position);
    formData.append('section_position', sectionposition);
    fetch("https://falconsquare.in/e_commerce/offerimage.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((data) => {
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  const handleDelete = async (imagesId) => {
    try {
      const response = await fetch(`https://falconsquare.in/e_commerce/offerimage.php?id=${imagesId}`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        setImage(
          image.filter((images) => images.id !== imagesId)
        );
      } else {
        console.error("Failed to delete taxes");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }
  const GetData = async () => {
    const data = await fetch("https://falconsquare.in/e_commerce/offerimage.php");
    const response = await data.json();
    setImage(response);

  };


  const handelEdit = async (imageid) => {

    try {
      const response = await fetch(
        `https://falconsquare.in/e_commerce/offerimage.php?id=${imageid}`
      );

      if (response.ok) {
        const category = await response.json();
        const categoryDetails = category.find((item) => item.id === imageid);
        console.log("id", categoryDetails);
        setEditData(categoryDetails)
        setEditShowModal(true)
      } else {
        console.error("Failed to fetch category");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setOfferimage(selectedFile);
  };





  const handleUpdate = async () => {
    try {
      const formData = new FormData();
      formData.append("id", editdata.id);
      formData.append("position", editdata.position);
      formData.append("section_position", editdata.section_position);
      formData.append("offer_image", imageFile);
      formData.append("current_image", editdata.offer_image);
      const response = await fetch(
        `https://falconsquare.in/e_commerce/offerimageupdate.php?id=${editdata.id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.status === 200) {
        console.log("Category updated successfully!");
        setImage(
          image.map((category) =>
            category.id === editdata.id ? editdata : image
          )
        );
        setEditShowModal(false);
      } else {
        console.error("Failed to update category");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    GetData()

  };


  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
  };
  return (
    <div className="main-container mt-3">
      <div className=" ">
        <div className="flex-container pt-5">
          <h3 className="head-fon-mv">New Offers for Customers</h3>
          <div class="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              class="breadcrumb-header float-start float-lg-end"
            >
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a onClick={() => Navigate("/dashboard")}>Dashboard</a>
                </li>{" "}
                <li aria-current="page" class="breadcrumb-item active">
                  Manage Categories
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div className="card ">
          <div className="cat-left">
            <h4>New Offers for Customers</h4>

            <button className="add-category-button" onClick={handleShowModal}>
              Add offer
            </button>
          </div>
          <div className="search-left">
            <div className="searchbar">
              <input type="text" className="search" placeholder="Search" />
              <FaSearch className="search-icon" />
            </div>
          </div>
          <div className="table-container text-end ">
          {loading ? (
              <div>
                <Lottie
                  height={250}
                  width={250}
                  options={{
                    loop: true,
                    animationData: require("../Assets/Animation - 1716197548567.json"),
                    autoplay: true,
                  }}
                />
              </div>
            ) : (
            <table id="table-to-xls" className="text-center">
              <thead>
                <tr>
                  <th style={{ width: "4%" }}>ID</th>
                  <th>Image</th>
                  <th>Position</th>
                  <th>Section Position</th>

                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {image && image.map((images, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>

                    <td>
                      <img src={`https://falconsquare.in/e_commerce/image/manageofferimage/${images.offer_image}`} width={40} height={40} />
                    </td>
                    <td>{images.position}</td>
                    <td>{images.section_position}</td>
                    <td>
                      <button className="btn-edit" onClick={() => handelEdit(images.id)} >
                        <FaPen />
                      </button>
                    </td>
                    <td>
                      <button className="btn-delete">
                        <FaTrash onClick={() => { handleDelete(images.id) }} />
                      </button>
                    </td>
                  </tr>

                ))}


              </tbody>
            </table>
              )}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="p-2">
            <label>Per page</label>
            <select className="form-select">
              <option value="20">20</option>
              <option value="40">40</option>
              <option value="60">60</option>
              <option value="80">80</option>
            </select>
          </div>
          <div className="dataTables_paginate paging_simple_numbers p-3">
            <ul className="pagination justify-content-end">
              <li className="page-item">
                <button className="page-link">Previous</button>
              </li>
              <li className="page-item">
                <button className="page-link"></button>
              </li>
              <li className="page-item">
                <button className="page-link">Next</button>
              </li>
            </ul>
          </div>
        </div>



        <Modal show={showModal} onHide={handleCloseModal}>
          <header class="modal-header">
            <h5 class="modal-title">Add New Offers Images here</h5>
            <button
              type="button"
              aria-label="Close"
              class="close"
              onClick={handleCloseModal}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <div>
              <p>
                Offer Image <i className="text-danger">*</i>
              </p>
              <input
                type="file"
                accept="image/*"
                className="form-control"
                onChange={handleFileChange}
              />

              <label>
                Position <i className="text-danger">*</i>
              </label>
              <input
                className="form-select"
                placeholder="Enter Position"
                value={position}
                onChange={(e) => setPosition(e.target.value)}
              />

              <label>
                section  Position <i className="text-danger">*</i>
              </label>
              <input
                className="form-select"
                placeholder="Enter Position"
                value={sectionposition}
                onChange={(e) => setSectionPosition(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleInsert}>
              Save
            </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>





        <Modal show={editshowModal} onHide={() => setEditShowModal(false)}>
          <header class="modal-header">
            <h5 class="modal-title">Add Home Slider Image</h5>
            <button
              type="button"
              aria-label="Close"
              class="close"
              onClick={() => editshowModal(false)}
            >
              ×
            </button>
          </header>
          <Modal.Body>
            <div>
              <label>section  Position</label>
              <input
                className="form-select"
                placeholder="Enter Name"
                value={editdata.section_position}
                onChange={(e) =>
                  setEditData({
                    ...editdata,
                    section_position: e.target.value,
                  })
                }
              />
              <label> Position</label>
              <input
                className="form-select"
                placeholder="Enter Type"
                value={editdata.position}
                onChange={(e) =>
                  setEditData({
                    ...editdata,
                    position: e.target.value,
                  })
                }
              />

              <label>Image</label>
              <p>
                Please choose square image of larger than 350px*350px & smaller
                than 550px*550px.
              </p>

              <label>Image</label>
              <input
                style={{ width: "80%" }}
                type="file"
                name="file2"
                id="myFile2"
                accept=".jpg, .jpeg, .png"
                onChange={(e) => {
                  handleFileInputChange(e)
                }}
              />

              <input value={editdata.offer_image} type=" hidden " name="current_image" />
              <span id="file-name">{filename}</span>

            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleUpdate}>
              Save
            </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default Manageimage;
