import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RiDashboard3Fill } from "react-icons/ri";
import { RiShoppingCartFill } from "react-icons/ri";
import { MdCategory } from "react-icons/md";
import { GiTwoCoins } from "react-icons/gi";
import "./Sidebar.css";
import { RiArrowDropDownLine } from "react-icons/ri";
import { AiFillPicture } from "react-icons/ai";
import { MdMan } from "react-icons/md";
import { FaPuzzlePiece } from "react-icons/fa";
import { IoNotifications } from "react-icons/io5";
import { IoGiftSharp } from "react-icons/io5";
import { IoMdSettings } from "react-icons/io";
import { IoIosPeople } from "react-icons/io";
import { MdPolicy } from "react-icons/md";
import { GiCharacter } from "react-icons/gi";
import { GiFiles } from "react-icons/gi";
import { RiListSettingsFill } from "react-icons/ri";
import falcon from "../Assets/logo.png";

function Sidebar() {
  const Navigate = useNavigate();
  const [active, setActive] = useState(
    localStorage.getItem("activeItem") || ""
  );
  const [products, setProducts] = useState(false);
  const [report, setReport] = useState(false);
  const [slider, setSlider] = useState(false);
  const [offer, setoffer] = useState(false);
  const [promo, setPromo] = useState(false);
  const [delivery, setDelivery] = useState(false);
  const [feature, setFeature] = useState(false);
  const [notify, setNotify] = useState(false);
  const [variant, setVariant] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleDropdown = () => {
    setActive(!active);
  };
  const handleProducts = () => {
    setProducts(!products);
  };
  const handleReport = () => {
    setReport(!report);
    setActive("report");
  };
  const handeloffer = () => {
    setoffer(!offer);
    setActive("offer");
  };
  const handelSlider = () => {
    setSlider(!slider);
    setActive("slider");
  };
  const handlePromo = () => {
    setPromo(!promo);
    setActive("promo");
  };
  const handleDelivery = () => {
    setDelivery(!delivery);
    setActive("delivery");
  };
  const handleFeature = () => {
    setFeature(!feature);
    setActive("feature");
  };
  const handleNotification = () => {
    setNotify(!notify);
    setActive("notify");
  };
  const handleVariant = () => {
    setVariant(!variant);
  };

  const handleItemClick = (itemName) => {
    if (active === itemName) {
      setActive("");
      localStorage.removeItem("activeItem");
    } else {
      setActive(itemName);
      localStorage.setItem("activeItem", itemName);
    }
  };

  useEffect(() => {
    const storedOption = localStorage.getItem("selectedOption");
    if (storedOption) {
      setSelectedOption(storedOption);
    }
  }, []);



  const handleOptionClick = (option, path) => {
    setSelectedOption(option);
    localStorage.setItem("selectedOption", option);
    Navigate(path);
  };

  return (
    <div className="side_head">
      <div>
        <img src={falcon} className="falcon-logo" />
      </div>
      <div
        className={`sidebar-crct ${active === "dashboard" ? "active1" : ""}`}
        onClick={() => {
          handleItemClick("dashboard");
          Navigate("/dashboard");
        }}
      >
        <h5 className="dash">
          <RiDashboard3Fill className="dash_img" />
          Dashboard
        </h5>
      </div>
      <div
        className={`sidebar-crct ${active === "orders" ? "active1" : ""}`}
        onClick={() => {
          handleItemClick("orders");
          Navigate("/orderlist");
        }}
      >
        <h5 className="dash">
          <RiShoppingCartFill className="dash_img" />
          Orders
        </h5>
      </div>

      <div
        className={`sidebar-crct ${active === "categories" ? "active1" : ""}`}
        onClick={() => {
          handleItemClick("categories");
        }}
      >
        <h5
          className="dash"
          onMouseDown={(e) => {
            if (e.detail > 2) {
              e.preventDefault(); // Prevent default behavior only for double-click
            }
          }}
        >
          <MdCategory className="dash_img" />
          Categories
        </h5>
        <div>
          <RiArrowDropDownLine className="dropdown" />
        </div>
      </div>

      {active === "categories" && (
        <div className="dropdown_content">
          <p
            onClick={() => handleOptionClick("headmaster", "/categoriemaster")}
            className={selectedOption === "headmaster" ? "selected" : ""}
          >
            Head Master
          </p>
          <p
            onClick={() => handleOptionClick("subcategories", "/categories")}
            className={selectedOption === "subcategories" ? "selected" : ""}
          >
            Sub Categories
          </p>
          <p
            onClick={() =>
              handleOptionClick("ordercategories", "/categoriesorder")
            }
            className={selectedOption === "ordercategories" ? "selected" : ""}
          >
            Order Categories
          </p>
        </div>
      )}
      <div
        className={`sidebar-crct ${active === "products" ? "active1" : ""}`}
        onClick={() => handleItemClick("products")}
      >
        <div>
          <h5 className="dash">
            <GiTwoCoins className="dash_img" />
            Products
          </h5>
        </div>
        <div>
          <RiArrowDropDownLine className="dropdown" onClick={handleProducts} />
        </div>
      </div>

      {active === "products" && (
        <div className="dropdown_content">
          <p
            onClick={() => handleOptionClick("addproduct", "/product")}
            className={selectedOption === "addproduct" ? "selected" : ""}
          >
            Add Product
          </p>
          <p
            onClick={() =>
              handleOptionClick("manageproducts", "/manageproduct")
            }
            className={selectedOption === "manageproducts" ? "selected" : ""}
          >
            Manage Products
          </p>
          <p
            onClick={() => handleOptionClick("units", "/units")}
            className={selectedOption === "units" ? "selected" : ""}
          >
            Units
          </p>
          <p
            onClick={() => handleOptionClick("bulkupload", "/bulkupload")}
            className={selectedOption === "bulkupload" ? "selected" : ""}
          >
            Bulk Upload
          </p>
          <p
            onClick={() => handleOptionClick("taxes", "/taxes")}
            className={selectedOption === "taxes" ? "selected" : ""}
          >
            Taxes
          </p>
          <p
            onClick={() => handleOptionClick("brands", "/brands")}
            className={selectedOption === "brands" ? "selected" : ""}
          >
            Brands
          </p>
          <p
            onClick={() => {
              setSelectedOption("productorder");
              localStorage.setItem("selectedOption", "productorder");
              // Handle navigation or other actions for "Product Order"
            }}
            className={selectedOption === "productorder" ? "selected" : ""}
          >
            Product Order
          </p>
        </div>
      )}

      <div
        className={`sidebar-crct ${active === "variant" ? "active1" : ""}`}
        onClick={() => {
          handleVariant();
          handleItemClick("variant");
        }}
      >
        <div>
          <h5 className="dash">
            <IoIosPeople className="dash_img" />
            Master
          </h5>
        </div>
        <div>
          <div>
            <RiArrowDropDownLine
              className="dropdown"
              onClick={handleItemClick}
            />
          </div>
        </div>
      </div>

      {active === "variant" && (
        <div className="dropdown_content">
          <p
            onClick={() => handleOptionClick("variantmaster", "/variantmaster")}
            className={selectedOption === "variantmaster" ? "selected" : ""}
          >
            Variant Master
          </p>
          <p
            onClick={() => handleOptionClick("sizemaster", "/sizemaster")}
            className={selectedOption === "sizemaster" ? "selected" : ""}
          >
            Size Master
          </p>
          <p
            onClick={() => handleOptionClick("colourmaster", "/colourmaster")}
            className={selectedOption === "colourmaster" ? "selected" : ""}
          >
            Colour Master
          </p>
          <p
            onClick={() =>
              handleOptionClick(
                "cellularmemorystoragecapacity",
                "/cellularmemorystoragecapacity"
              )
            }
            className={
              selectedOption === "cellularmemorystoragecapacity"
                ? "selected"
                : ""
            }
          >
            Cellular Memory Storage Capacity
          </p>
          <p
            onClick={() =>
              handleOptionClick("processorspeed", "/processorspeed")
            }
            className={selectedOption === "processorspeed" ? "selected" : ""}
          >
            Processor Speed
          </p>
          <p
            onClick={() =>
              handleOptionClick("batterycapacity", "/batterycapacity")
            }
            className={selectedOption === "batterycapacity" ? "selected" : ""}
          >
           Battery Capacity
          </p>
          <p
            onClick={() => handleOptionClick("gps", "/gps")}
            className={selectedOption === "gps" ? "selected" : ""}
          >
            Gps
          </p>
          <p
            onClick={() =>
              handleOptionClick("cameraresolution", "/cameraresolution")
            }
            className={selectedOption === "cameraresolution" ? "selected" : ""}
          >
            Camera Resolution
          </p>
          <p
            onClick={() =>
              handleOptionClick("phoneresolution", "/phoneresolution")
            }
            className={selectedOption === "phoneresolution" ? "selected" : ""}
          >
            Phone Resolution
          </p>
          <p
            onClick={() => handleOptionClick("refreshrate", "/refreshrate")}
            className={selectedOption === "refreshrate" ? "selected" : ""}
          >
            Refresh Rate
          </p>
          <p
            onClick={() => handleOptionClick("ramsize", "/ramsize")}
            className={selectedOption === "ramsize" ? "selected" : ""}
          >
            Ram Size
          </p>
          <p
            onClick={() =>
              handleOptionClick("sensorresolution", "/sensorresolution")
            }
            className={selectedOption === "sensorresolution" ? "selected" : ""}
          >
            Sensor Resolution
          </p>
          <p
            onClick={() => handleOptionClick("shootingmodes", "/shootingmodes")}
            className={selectedOption === "shootingmodes" ? "selected" : ""}
          >
            Shooting Modes
          </p>
          <p
            onClick={() => handleOptionClick("cellulartech", "/cellulartech")}
            className={selectedOption === "cellulartech" ? "selected" : ""}
          >
            Cellular Technology
          </p>
          <p
            onClick={() => handleOptionClick("sim", "/sim")}
            className={selectedOption === "sim" ? "selected" : ""}
          >
            Sim Card Size
          </p>
          <p
            onClick={() => handleOptionClick("os", "/os")}
            className={selectedOption === "os" ? "selected" : ""}
          >
            Operating System
          </p>
          <p
            onClick={() => handleOptionClick("humanint", "/humanint")}
            className={selectedOption === "humanint" ? "selected" : ""}
          >
            Mobile Phone Human Interface Input
          </p>
          <p
            onClick={() => handleOptionClick("cellularphone", "/cellularphone")}
            className={selectedOption === "cellularphone" ? "selected" : ""}
          >
            Cellular Phone Photo Sensor Resolution
          </p>
          <p
            onClick={() => handleOptionClick("videocapture", "/videocapture")}
            className={selectedOption === "videocapture" ? "selected" : ""}
          >
            Video Capture Resolution
          </p>
          <p
            onClick={() => handleOptionClick("item", "/item")}
            className={selectedOption === "item" ? "selected" : ""}
          >
            Item Weight
          </p>
          <p
            onClick={() => handleOptionClick("include", "/include")}
            className={selectedOption === "include" ? "selected" : ""}
          >
            Included Components
          </p>
          <p
            onClick={() => handleOptionClick("biometric", "/biometric")}
            className={selectedOption === "biometric" ? "selected" : ""}
          >
            Mobile Phone Biometric Security Feature
          </p>
          <p
            onClick={() => handleOptionClick("connector", "/connector")}
            className={selectedOption === "connector" ? "selected" : ""}
          >
            Cell Phone Connector Type
          </p>
          <p
            onClick={() => handleOptionClick("connectivity", "/connectivity")}
            className={selectedOption === "connectivity" ? "selected" : ""}
          >
            Mobile Phone Connectivity Technology
          </p>
          <p
            onClick={() => handleOptionClick("size", "/size")}
            className={selectedOption === "size" ? "selected" : ""}
          >
            Screen Size
          </p>
          <p
            onClick={() => handleOptionClick("displaytype", "/displaytype")}
            className={selectedOption === "displaytype" ? "selected" : ""}
          >
            Display Type
          </p>
          <p
            onClick={() =>
              handleOptionClick("devicefeatures", "/devicefeatures")
            }
            className={selectedOption === "devicefeatures" ? "selected" : ""}
          >
            Device Features
          </p>
          <p
            onClick={() => handleOptionClick("slotcount", "/slotcount")}
            className={selectedOption === "slotcount" ? "selected" : ""}
          >
            Sim Card Slot Count
          </p>
          <p
            onClick={() =>
              handleOptionClick("shortby", "/shortby")
            }
            className={selectedOption === "shortby" ? "selected" : ""}
          >
           Short By
          </p>
          <p
            onClick={() =>
              handleOptionClick("deliverycharge", "/deliverycharge")
            }
            className={selectedOption === "deliverycharge" ? "selected" : ""}
          >
            Delivery Charge
          </p>
          <p
            onClick={() =>
              handleOptionClick("shop", "/shop")
            }
            className={selectedOption === "shop" ? "selected" : ""}
          >
           Shop Master
          </p>
        
        </div>
      )}
      <div
        className={`sidebar-crct ${active === "Purchase" ? "active1" : ""}`}
        onClick={() => {
          handleItemClick("Purchase");
          Navigate("/Purchase");
        }}
      >
        <div>
          <h5 className="dash">
            <AiFillPicture className="dash_img" />
            Purchase
          </h5>
        </div>
      </div>

      <div
        className={`sidebar-crct ${active === "Offer" ? "active1" : ""}`}
        onClick={() => {
          handleItemClick("Offer");
          Navigate("/Offer");
        }}
      >
        <div>
          <h5 className="dash">
            <AiFillPicture className="dash_img" />
            Offer Page
          </h5>
        </div>
      </div>

      <div
        className={`sidebar-crct ${
          active === "managehomeslider" ? "active1" : ""
        }`}
        onClick={() => {
          handleItemClick("managehomeslider");
          Navigate("/managehomeslider");
        }}
      >
        <div>
          <h5 className="dash">
            <AiFillPicture className="dash_img" />
            Home Sliders
          </h5>
        </div>
      </div>

      <div
        className={`sidebar-crct ${
          active === "manageofferimage" ? "active1" : ""
        }`}
        onClick={() => {
          handleItemClick("manageofferimage");
          Navigate("/manageofferimage");
        }}
      >
        <div>
          <h5 className="dash">
            <AiFillPicture className="dash_img" />
            Offer Images
          </h5>
        </div>
      </div>

      <div
        className={`sidebar-crct ${active === "managepromo" ? "active1" : ""}`}
        onClick={() => {
          handleItemClick("managepromo");
          Navigate("/managepromo");
        }}
      >
        <div>
          <h5 className="dash">
            <AiFillPicture className="dash_img" />
            Promo Code
          </h5>
        </div>
      </div>

      <div
        className={`sidebar-crct ${active === "delivery" ? "active1" : ""}`}
        onClick={() => {
          handleItemClick("delivery");
        }}
      >
        <h5
          className="dash"
          onMouseDown={(e) => {
            if (e.detail > 2) {
              e.preventDefault(); // Prevent default behavior only for double-click
            }
          }}
        >
          <MdCategory className="dash_img" />
          Delivery Boy
        </h5>
        <div>
          <RiArrowDropDownLine className="dropdown" />
        </div>
      </div>

      {active === "delivery" && (
        <div className="dropdown_content">
          <p
            onClick={() => handleOptionClick('adddelivery', '/adddelivery')}
            className={selectedOption === 'adddelivery' ? 'selected' : ''}
          >
            Add Delivery Boy
          </p>
          <p
            onClick={() => handleOptionClick('managedelivery', '/managedelivery')}
            className={selectedOption === 'managedelivery' ? 'selected' : ''}
          >
            Manage Delivery Boys
          </p>
        </div>
      )}

      <div
        className={`sidebar-crct ${active === "feature" ? "active1" : ""}`}
        onClick={() => {
          handleItemClick("feature");
        }}
      >
        <h5
          className="dash"
          onMouseDown={(e) => {
            if (e.detail > 2) {
              e.preventDefault(); // Prevent default behavior only for double-click
            }
          }}
        >
          <MdCategory className="dash_img" />
          Featured Sections
        </h5>
        <div>
          <RiArrowDropDownLine className="dropdown" />
        </div>
      </div>

      {active === "feature" && (
        <div className="dropdown_content">
          <p
            onClick={() => {
              setSelectedOption("addsection");
              Navigate("/addsection");
            }}
            className={selectedOption === "addsection" ? "selected" : ""}
          >
            Add Section
          </p>
          <p
            onClick={() => {
              setSelectedOption("managesection");
              Navigate("/managesection");
            }}
            className={selectedOption === "managesection" ? "selected" : ""}
          >
            Manage Section
          </p>
          <p
            onClick={() => {
              setSelectedOption("ordersection");
              Navigate("/ordersection");
            }}
            className={selectedOption === "ordersection" ? "selected" : ""}
          >
            ordersection
          </p>
        </div>
      )}

      <div
        className={`sidebar-crct ${active === "web" ? "active1" : ""}`}
        onClick={() => {
          handleItemClick("web");
          Navigate("/web");
        }}
      >
        <h5 className="dash">
          <RiDashboard3Fill className="dash_img" />
          Web Settings
        </h5>
      </div>

      <div
        className={`sidebar-crct ${active === "policies" ? "active1" : ""}`}
        onClick={() => {
          handleItemClick("policies");
          Navigate("/policies");
        }}
      >
        <h5 className="dash">
          <RiDashboard3Fill className="dash_img" />
          Policies
        </h5>
      </div>
      <div
        className={`sidebar-crct ${active === "report" ? "active1" : ""}`}
        onClick={() => {
          handleItemClick("report");
        }}
      >
        <h5
          className="dash"
          onMouseDown={(e) => {
            if (e.detail > 2) {
              e.preventDefault(); // Prevent default behavior only for double-click
            }
          }}
        >
          <MdCategory className="dash_img" />
          Reports
        </h5>
        <div>
          <RiArrowDropDownLine className="dropdown" />
        </div>
      </div>

      {active === "report" && (
        <div className="dropdown_content">
          <p
            onClick={() => {
              setSelectedOption("productsalesreport");
              Navigate("/productsalesreport");
            }}
            className={selectedOption === "productsalesreport" ? "selected" : ""}
          >
            Product Sales Report
          </p>
          <p
            onClick={() => {
              setSelectedOption("salesreport");
              Navigate("/salesreport");
            }}
            className={selectedOption === "salesreport" ? "selected" : ""}
          >
            Sales Report
          </p>
        </div>
      )}

      <div
        className={`sidebar-crct ${active === "stock" ? "active1" : ""}`}
        onClick={() => {
          handleItemClick("stock");
          Navigate("/stock");
        }}
      >
        <h5 className="dash">
          <RiDashboard3Fill className="dash_img" />
          Stock
        </h5>
      </div>
      <div
        className={`sidebar-crct ${active === "notify" ? "active1" : ""}`}
        onClick={() => {
          handleItemClick("notify");
        }}
      >
        <h5
          className="dash"
          onMouseDown={(e) => {
            if (e.detail > 2) {
              e.preventDefault(); // Prevent default behavior only for double-click
            }
          }}
        >
          <MdCategory className="dash_img" />
          Notifications
        </h5>
        <div>
          <RiArrowDropDownLine className="dropdown" />
        </div>
      </div>

      {active === "notify" && (
        <div className="dropdown_content">
          <p
            onClick={() => {
              setSelectedOption("sendnotification");
              Navigate("/sendnotification");
            }}
            className={selectedOption === "sendnotification" ? "selected" : ""}
          >
            Send Notification
          </p>
          <p
            onClick={() => {
              setSelectedOption("managenotification");
              Navigate("/managenotification");
            }}
            className={
              selectedOption === "managenotification" ? "selected" : ""
            }
          >
            Manage Notification
          </p>
        </div>
      )}

      <div
        className={`sidebar-crct ${active === "system" ? "active1" : ""}`}
        onClick={() => {
          handleItemClick("system");
          Navigate("/systemusers ");
        }}
      >
        <h5 className="dash">
          <RiDashboard3Fill className="dash_img" />
          System Users
        </h5>
      </div>
      <div
        className={`sidebar-crct ${active === "mobile" ? "active1" : ""}`}
        onClick={() => {
          handleItemClick("mobile");
          Navigate("/mobileusers ");
        }}
      >
        <h5 className="dash">
          <RiDashboard3Fill className="dash_img" />
          mobileusers
        </h5>
      </div>
      <div
        className={`sidebar-crct ${active === "return" ? "active1" : ""}`}
        onClick={() => {
          handleItemClick("return");
          Navigate("/ReturnRequest ");
        }}
      >
        <h5 className="dash">
          <RiDashboard3Fill className="dash_img" />
          Return Requests
        </h5>
      </div>
      <div
        className={`sidebar-crct ${active === "withdraw" ? "active1" : ""}`}
        onClick={() => {
          handleItemClick("withdraw");
          Navigate("/Withdrawalrequest ");
        }}
      >
        <h5 className="dash">
          <RiDashboard3Fill className="dash_img" />
          Withdrawal Request
        </h5>
      </div>
      <div
        className={`sidebar-crct ${active === "role" ? "active1" : ""}`}
        onClick={() => {
          handleItemClick("role");
          Navigate("/role ");
        }}
      >
        <h5 className="dash">
          <RiDashboard3Fill className="dash_img" />
          Role
        </h5>
      </div>
      <div
        className={`sidebar-crct ${active === "Setting" ? "active1" : ""}`}
        onClick={() => {
          handleItemClick("Setting");
          Navigate("/Setting ");
        }}
      >
        <h5 className="dash">
          <RiDashboard3Fill className="dash_img" />
          Settings
        </h5>
      </div>
    </div>
  );
}

export default Sidebar;
