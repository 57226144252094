import React, { useState } from "react";
import "./ManageNotification.css";
import { LuRefreshCcw } from "react-icons/lu";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/esm/ModalBody";
import ModalFooter from "react-bootstrap/esm/ModalFooter";
import Button from "react-bootstrap/Button";
function ManageNotification() {
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState(false);
  const [notificationType, setNotificationType] = useState("Text");
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleImage = () => {
    setImage(true);
  };
  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleTypeChange = (event) => {
    setNotificationType(event.target.value); // Update notification type based on selection
    if (event.target.value === "Image") {
      setImage(true); // Show image input if image type is selected
    } else {
      setImage(false); // Hide image input if text type is selected
    }
  };

  return (
    <div className="main-container_1">
      <div className="Order-head">
        <h3>Send Notification</h3>
        <p>Dashboard / Send Notification </p>
      </div>

      <div className="card">
        <div className="od_items_69">
          <h6>Notifications</h6>
          <button className="o_btn" onClick={handleShowModal}>
            Send Notification
          </button>
        </div>
        <div className="search_1">
          <div className="input-md">
            <label>Search</label>
            <input type="text" placeholder="" className="form-control" />
          </div>
          <div>
            <LuRefreshCcw className="refresh" />
          </div>
        </div>
        <div className="table-container text-end mt-3">
          <table id="table-to-xls" className="text-center">
            <thead>
              <tr>
                <th>O.ID</th>
                <th>User</th>
                <th>Categories</th>
                <th>Mobile</th>
                <th>Total($)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Ram</td>
                <td>Ram</td>
                <td>Ram</td>
                <td>Ram</td>
                <td>Ram</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-between">
          <div className="p-2">
            <label>Per page</label>
            <select className="form-select">
              <option value="20">2</option>
              <option value="40">5</option>
              <option value="60">10</option>
              <option value="80">20</option>
              <option value="80">50</option>
            </select>
          </div>
          <div className="dataTables_paginate paging_simple_numbers p-3">
            <ul className="pagination justify-content-end">
              <li className="page-item">
                <button className="page-link">Previous</button>
              </li>
              <li className="page-item">
                <button className="page-link"></button>
              </li>
              <li className="page-item">
                <button className="page-link">Next</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <header class="modal-header">
          <h5 class="modal-title">Add Notification</h5>
          <button
            type="button"
            aria-label="Close"
            class="close"
            onClick={handleCloseModal}
          >
            ×
          </button>
        </header>

        <ModalBody>
          <div className="form_1">
            <label>Type</label>
            <select className="form-select" onChange={handleTypeChange}>
              <option value="Text">Text</option>
              <option value="Image">Image</option>
            </select>
          </div>
          {image && (
            <div>
              <input className="form-control" type="file"></input>
            </div>
          )}
          <div className="form_1">
            <label>Title</label>
            <input
              type="text"
              placeholder="Enter Title"
              className="form-control"
            />
          </div>
          <div className="form_1">
            <label>Message</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Notification Message Here!"
            />
          </div>
        
        </ModalBody>
        <ModalFooter>
          <Button variant="success">Save</Button>
          <Button variant="secondary">Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ManageNotification;
