import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Lottie from "react-lottie";
import swal from 'sweetalert';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
function Brands() {
  const [showModal, setShowModal] = useState(false);
  const Navigate = useNavigate();
  const [brand, setBrand] = useState([]);
  const [id, setId] = useState("");
  const [brandname, setBrandname] = useState("");
  const [image, setImage] = useState(null);
  const [brandimage, setBrandimage] = useState("");
  const [status, setStatus] = useState("1");
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("limited");
  const [errorMessage, setErrorMessage] = useState("");
  const [editShowModal, setEditShowModal] = useState(false);
  const [editBrand, setEditBrand] = useState({
    id: "",
    brand_name: "",
    brand_image: "",
    status: "",
  });

  const [searchQuery, setSearchQuery] = useState("");
  const [filename, setFilename] = useState("");
  const [imageFile, setImageFile] = useState(null);

  const filteredBrands = brand.filter((brand) =>
    brand.brand_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const sortedFilter = [...filteredBrands].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };

  useEffect(() => {
    setLoading(true);
    GetData()
    .then(() => setLoading(false))
    .catch(() => setLoading(false));
    setFilename(editBrand.brand_image);
  }, []);

  useEffect(() => {
    const filteredBrands = brand.filter((brand) =>
      brand.brand_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setBrand(filteredBrands);
  }, [searchQuery]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setStatus(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleInsert = () => {
    // Check if any required fields are null or empty
    if (!brandname || !brandimage || !status) {
      alert("Error: One or more required fields are null or empty.");
      return;
    }

    const formData = new FormData();
    formData.append("brand_name", brandname);
    formData.append("brand_image", brandimage);
    formData.append("status", status);

    fetch("https://falconsquare.in/e_commerce/brand.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((data) => {
        console.log(data);
        GetData();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
      setShowModal(false);
  };

  const GetData = async () => {
    const data = await fetch("https://falconsquare.in/e_commerce/brand.php");
    const response = await data.json();
    setBrand(response);
  };

  const handleEdit = async (brandsId) => {
    try {
      const response = await fetch(
        `https://falconsquare.in/e_commerce/brand.php?id=${brandsId}`
      );
      if (response.ok) {
        const brands = await response.json();
        const categoryDetails = brands.find((item) => item.id === brandsId);
        setEditBrand(categoryDetails);
        setEditShowModal(true);
      } else {
        console.error("Failed to fetch category");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };


  const handleDelete = async (brandsId) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this brand!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });
  
      if (shouldDelete) {
        const response = await fetch(
          `https://falconsquare.in/e_commerce/brand.php?id=${brandsId}`,
          {
            method: "DELETE",
          }
        );
  
        if (response.ok) {
          setBrand(brand.filter((brands) => brands.id !== brandsId));
          swal("Deleted!", "Brand has been deleted.", "success");
        } else {
          console.error("Failed to delete brand");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  

  const handleFileChange = (e) => {
    const selectedImage = e.target.files[0];
    const maxSize = 20 * 1024 * 1024; // 20MB in bytes

    if (selectedImage && selectedImage.size <= maxSize) {
      setBrandimage(selectedImage);
      setErrorMessage("");
    } else {
      setErrorMessage("Please select a file smaller than 20MB");
    }
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
  };

  const handleUpdate = async () => {
    try {
      const formData = new FormData();
      formData.append("id", editBrand.id);
      formData.append("brand_name", editBrand.brand_name);
      formData.append("brand_image", imageFile);
      formData.append("current_image", editBrand.brand_image);
      formData.append("status", editBrand.status);

      const response = await fetch(
        `https://falconsquare.in/e_commerce/brandupdate.php?id=${editBrand.id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.status === 200) {
        console.log("Category updated successfully!");
        setBrand(
          brand.map((category) =>
            category.id === editBrand.id ? editBrand : brand
          )
        );
        setEditShowModal(false);
      } else {
        console.error("Failed to update category");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    GetData();
  };

  return (
    <div className="main-container mt-3">
      <div className=" ">
        <div className="flex-container pt-5">
          <h3 className="head-fon-mv">Brands</h3>
          <div class="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              class="breadcrumb-header float-start float-lg-end"
            >
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a onClick={() => Navigate("/dashboard")}>Dashboard</a>
                </li>{" "}
                <li aria-current="page" class="breadcrumb-item active">
                  Brands
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div className="card ">
          <div className="cat-left">
            <h4>Brands</h4>

            <button
              className="add-category-button"
              onClick={() => setShowModal(true)}
            >
              Add Brands
            </button>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="row">
              <div className="d-flex align-items-center">
                <label className="me-2">Show</label>
                <select
                  className="form-select me-2"
                  value={itemsPerPage}
                  onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
                >
                  <option value="20">20</option>
                  <option value="40">40</option>
                  <option value="60">60</option>
                  <option value="80">80</option>
                </select>
                <label>entries</label>
              </div>
            </div>
            <div className="search-left">
              <div className="searchbar">
                <input
                  type="text"
                  className="search"
                  placeholder="Search"
                  onChange={handleSearchChange}
                />
                <FaSearch className="search-icon" />
              </div>
            </div>
          </div>
          <div className="table-container text-end ">
          {loading ? (
              <div>
                <Lottie
                  height={250}
                  width={250}
                  options={{
                    loop: true,
                    animationData: require("../Assets/Animation - 1716197548567.json"),
                    autoplay: true,
                  }}
                />
              </div>
            ) : (
            <table id="table-to-xls" className="text-center">
              <thead>
                <tr>
                  <th style={{ width: "4%" }}>S.No</th>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredBrands.map((brand,index) => (
                  <tr key={brand.id}>
                    <td>{startIndex + index + 1}</td>
                    <td>
                      {" "}
                      <img
                        src={`https://falconsquare.in/e_commerce/image/brands/${brand.brand_image}`}
                        alt={brand.brand_name}
                        width={40}
                        height={40}
                        style={{ margin: "10px" }}
                      />
                      {brand.brand_name}
                    </td>

                    <td>
                      {brand.status === "1" ? (
                        <span className="active-item">Activated</span>
                      ) : (
                        <span className="inactive-item">Deactivated</span>
                      )}
                    </td>
                    <td>
                    
                      <button
                          className="btn-edit"
                          onClick={() => handleEdit(brand.id)}
                        >
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </button>
                      <button
                        className="btn-delete"
                        onClick={() => handleDelete(brand.id)}
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
              )}
          </div>
        </div>
        <div
          className="dataTables_paginate paging_simple_numbers p-2"
          id="dtBasicExample_paginate"
        >
          <ul className="pagination">
            <li
              className={`paginate_button page-item ${
                currentPage === 1 ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage > 1 ? prevPage - 1 : prevPage
                  )
                }
                className="page-link"
              >
                Previous
              </button>
            </li>
            {generatePaginationNumbers().map((page, index) => (
              <li
                key={index}
                className={`paginate_button page-item ${
                  page === currentPage ? "active" : ""
                }`}
              >
                <button
                  onClick={() => setCurrentPage(page)}
                  className="page-link"
                >
                  {page}
                </button>
              </li>
            ))}
            <li
              className={`paginate_button page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage < totalPages ? prevPage + 1 : prevPage
                  )
                }
                className="page-link"
              >
                Next
              </button>
            </li>
          </ul>
        </div>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <header class="modal-header">
            <h5 class="modal-title">Add Brand</h5>
            <button
              type="button"
              aria-label="Close"
              class="close"
              onClick={() => setShowModal(false)}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <div>
              <label>Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Name"
                value={brandname}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^[a-zA-Z\s]*$/.test(inputValue)) {
                    setBrandname(inputValue);
                  }
                }}
              />

              <label>Status</label>
              <div
                role="radiogroup"
                tabIndex="-1"
                className="btn-group-toggle btn-group bv-no-focus-ring"
              >
                <label
                  className={`btn btn-outline-primary ${
                    status === "1" ? "active" : ""
                  }`}
                >
                  <input
                    type="radio"
                    value="1"
                    checked={status === "1"}
                    onChange={handleOptionChange}
                  />
                  <span>Activated</span>
                </label>
                <label
                  className={`btn btn-outline-primary ${
                    status === "0" ? "active" : ""
                  }`}
                >
                  <input
                    type="radio"
                    value="0"
                    checked={status === "0"}
                    onChange={handleOptionChange}
                  />
                  <span>Deactivated</span>
                </label>
              </div>
              <label>Image</label>
              <p>
                Please choose square image of larger than 350px*350px & smaller
                than 550px*550px.
              </p>
              <input
                type="file"
                accept="image/*"
                className="form-control"
                onChange={handleFileChange}
              />
              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
              {image && (
                <img src={URL.createObjectURL(image)} alt="Uploaded Image" />
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleInsert}>
              Save
            </Button>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={editShowModal} onHide={() => setEditShowModal(false)}>
          <header class="modal-header">
            <h5 class="modal-title">Add Brand</h5>
            <button
              type="button"
              aria-label="Close"
              class="close"
              onClick={() => setEditShowModal(false)}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <div>
              <label>Name</label>
              <input
                className="form-select"
                placeholder="Enter Name"
                value={editBrand.brand_name}
                onChange={(e) =>
                  setEditBrand({
                    ...editBrand,
                    brand_name: e.target.value,
                  })
                }
              />
              <label>Status</label>
              <div
                role="radiogroup"
                tabIndex="-1"
                className="btn-group-toggle btn-group bv-no-focus-ring"
              >
                <label
                  className={`btn btn-outline-primary ${
                    editBrand.status === "1" ? "active" : ""
                  }`}
                >
                  <input
                    type="radio"
                    value="1"
                    checked={editBrand.status === "1"}
                    onChange={(e) => {
                      setEditBrand({
                        ...editBrand,
                        status: e.target.value,
                      });
                      setSelectedOption("1");
                    }}
                  />
                  <span>Activated</span>
                </label>
                <label
                  className={`btn btn-outline-primary ${
                    editBrand.status === "0" ? "active" : ""
                  }`}
                >
                  <input
                    type="radio"
                    value="0"
                    checked={editBrand.status === "0"}
                    onChange={(e) => {
                      setEditBrand({
                        ...editBrand,
                        status: e.target.value,
                      });
                      setSelectedOption("0");
                    }}
                  />
                  <span>Deactivated</span>
                </label>
              </div>
              <label>Image</label>
              <p>
                Please choose square image of larger than 350px*350px & smaller
                than 550px*550px.
              </p>

              <label>Image</label>
              <input
                style={{ width: "80%" }}
                type="file"
                name="file2"
                id="myFile2"
                accept=".jpg, .jpeg, .png"
                onChange={(e) => {
                  handleFileInputChange(e);
                }}
              />
              <input
                value={editBrand.brand_image}
                type=" hidden "
                name="current_image"
              />
              <span id="file-name">{filename}</span>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={() => handleUpdate()}>
              Save
            </Button>
            <Button variant="secondary" onClick={() => setEditShowModal(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default Brands;
