import React from "react";
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
function ReturnRequest() {
  const Navigate = useNavigate();
  return (
    <div className="main-container mt-3">
      <div className=" ">
        <div className="flex-container pt-5">
          <h3 className="head-fon-mv">Return Requests</h3>
          <div class="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              class="breadcrumb-header float-start float-lg-end"
            >
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a onClick={() => Navigate("/dashboard")}>Dashboard</a>
                </li>{" "}
                <li aria-current="page" class="breadcrumb-item active">
                  Return Requests
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div className="card ">
          <div className="cat-left">
            <h4>Return Requests</h4>
          </div>
          <div className="search-left">
            <div className="searchbar">
              <input type="text" className="search" placeholder="Search" />
              <FaSearch className="search-icon" />
            </div>
          </div>
          <div className="table-container text-end mt-3">
            <table id="table-to-xls" className="text-center">
              <thead>
                <tr>
                  <th style={{ width: "4%" }}>ID</th>
                  <th>U.ID</th>
                  <th>Name</th>
                  <th>Product Name</th>
                  <th>Price ($)</th>
                  <th>Discounted Price ($)</th>
                  <th>Quantity</th>
                  <th>Total</th>
                  <th>Status</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>101</td>
                  <td>Category 1</td>
                  <td>Subtitle 1</td>
                  <td>Subtitle 1</td>
                  <td>Subtitle 1</td>
                  <td>Subtitle 1</td>
                  <td>
                    <img src="image1.jpg" alt="Image 1" />
                  </td>
                  <td>
                    <span className="active-item">Activated</span>
                  </td>
                  <td>
                    <button className="btn-edit">
                      <FaPen />
                    </button>
                  </td>
                  <td>
                    <button className="btn-delete">
                      <FaTrash />
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>102</td>
                  <td>Category 2</td>
                  <td>Subtitle 2</td>
                  <td>Subtitle 1</td>
                  <td>Subtitle 1</td>
                  <td>Subtitle 1</td>
                  <td>
                    <img src="image2.jpg" alt="Image 2" />
                  </td>
                  <td>
                    <span className="inactive-item">Deactivated</span>
                  </td>
                  <td>
                    <button className="btn-edit">
                      <FaPen />
                    </button>
                  </td>
                  <td>
                    <button className="btn-delete">
                      <FaTrash />
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>103</td>
                  <td>Category 3</td>
                  <td>Subtitle 3</td>
                  <td>Subtitle 1</td>
                  <td>Subtitle 1</td>
                  <td>Subtitle 1</td>
                  <td>
                    <img src="image3.jpg" alt="Image 3" />
                  </td>
                  <td>
                    <span className="active-item">Activated</span>
                  </td>
                  <td>
                    <button className="btn-edit">
                      <FaPen />
                    </button>
                  </td>
                  <td>
                    <button className="btn-delete">
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="p-2">
            <label>Per page</label>
            <select className="form-select">
              <option value="20">20</option>
              <option value="40">40</option>
              <option value="60">60</option>
              <option value="80">80</option>
            </select>
          </div>
          <div className="dataTables_paginate paging_simple_numbers p-3">
            <ul className="pagination justify-content-end">
              <li className="page-item">
                <button className="page-link">Previous</button>
              </li>
              <li className="page-item">
                <button className="page-link"></button>
              </li>
              <li className="page-item">
                <button className="page-link">Next</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReturnRequest;
