import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "./Login.css"; // Import your CSS file
import swal from "sweetalert";
const MySwal = withReactContent(Swal);

function Login() {
  const [loginName, setLoginName] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  localStorage.setItem("loginName", loginName);

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    if (!loginName.trim() || !loginPassword.trim()) {
      alert("Please enter both username and password.");
      return;
    }
  
    try {
      // Fetch expiry data
      const expiryResponse = await fetch(
        "https://falconsquare.in/e_commerce/expiry.php"
      );
      const expiryData = await expiryResponse.json();
      if (!expiryResponse.ok) {
        throw new Error("Failed to fetch expiry data");
      }
  
      // Get the first expiry object (assuming there's only one for now)
      const expiry = expiryData[0];
      const fromDate = new Date(expiry.from_date);
      const toDate = new Date(expiry.to_date);
  
      // Check if the current date is within the specified range
      const currentDate = new Date();
      if (currentDate < fromDate || currentDate > toDate) {
        swal("Update Required", "The software needs to be updated.", "warning");
        return; // Prevent further execution of login process
      }
  
      // Proceed with login process
      const response = await fetch(
        `https://falconsquare.in/e_commerce/Systemuserlogin.php?username=${loginName}&confirm_password=${loginPassword}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ loginName, loginPassword }),
        }
      );
      const data = await response.json();
      if (response.ok && data.success === 1) {
        setShowSuccessMessage(true);
        MySwal.fire({
          icon: "success",
          title: "Login successful!",
          showConfirmButton: false,
          timer: 1500, // Adjust the timer as needed
        }).then(() => {
          handleCloseSuccessMessage();
        });
      } else {
        showErrorAlert(data.message || "Login failed");
      }
    } catch (error) {
      console.error("Login error:", error);
      showErrorAlert("An error occurred. Please try again later.");
    }
  };
  

  const handleCloseSuccessMessage = () => {
    setShowSuccessMessage(false);
    navigate("/dashboard");
  };

  useEffect(() => {
    localStorage.removeItem("activeItem");
  }, []);

  const showErrorAlert = (errorMessage) => {
    MySwal.fire({
      icon: "error",
      title: "Oops...",
      text: errorMessage,
    });
  };

  return (
    <div className="main">
      <div className="container center">
        <div className="row h-100 justify-content-center align-items-center">
          <form className="col-md-9">
            <div className="AppForm shadow-lg">
              <div className="row">
                <div className="col-md-6 d-flex justify-content-center align-items-center">
                  <div className="AppFormLeft">
                    <h1>Login</h1>
                    <div className="form-group position-relative mb-4">
                      <input
                        type="text"
                        className="input-text"
                        id="login_name"
                        placeholder="Username"
                        value={loginName}
                        onChange={(e) => setLoginName(e.target.value)}
                      />
                      <i className="fa fa-user-o"></i>
                    </div>
                    <div className="form-group position-relative mb-4">
                      <input
                        type="password"
                        className="input-text"
                        id="login_password"
                        placeholder="Password"
                        value={loginPassword}
                        onChange={(e) => setLoginPassword(e.target.value)}
                      />
                      <i className="fa fa-key"></i>
                    </div>

                    <button
                      type="submit"
                      className="btn btn-success  text-uppercase"
                      onClick={handleLoginSubmit}
                    >
                      Login
                    </button>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="AppFormRight position-relative d-flex justify-content-center flex-column align-items-center text-center p-5 text-white">
                    <h2 className="position-relative px-4 pb-3 mb-4">
                      Welcome
                    </h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nam volutpat nibh vitae turpis ullamcorper, nec commodo
                      urna iaculis.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
