import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Managehomeslider.css";
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

function Managehomeslider() {
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("1");
  const [homeslider, setHomeslider] = useState([]);
  const [slidertype, setSlidertype] = useState("");
  const [slidername, setSlidername] = useState("");
  const [sliderimage, setSliderImage] = useState("");
  const [EditshowModal, setEditShowModal] = useState(false);
  const [status, setStatus] = useState("");
  const [editCategory, setEditCategory] = useState({
    id: "",
    slider_name: "",
    slider_type: "",
    slider_image: "",
    status: "",
  });
  const [filename, setFilename] = useState("");
  const Navigate = useNavigate();
  const [imageFile, setImageFile] = useState(null);
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState([]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setSliderImage(selectedFile);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setStatus(event.target.value);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    GetData();
  }, []);

  const GetData = async () => {
    const data = await fetch("https://falconsquare.in/e_commerce/homeslider.php");
    const response = await data.json();
    setHomeslider(response);
  };

  const handleInsert = () => {
    const formData = new FormData();
    formData.append("slider_type", slidertype);
    formData.append("slider_name", slidername);
    formData.append("slider_image", sliderimage);
    formData.append("status", selectedOption);
    fetch("https://falconsquare.in/e_commerce/homeslider.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleDelete = async (homeslidersId) => {
    try {
      const response = await fetch(
        `https://falconsquare.in/e_commerce/homeslider.php?id=${homeslidersId}`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        setHomeslider(
          homeslider.filter((homesliders) => homesliders.id !== homeslidersId)
        );
      } else {
        console.error("Failed to delete taxes");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handelEdit = async (homeid) => {
    try {
      const response = await fetch(
        `https://falconsquare.in/e_commerce/homeslider.php?id=${homeid}`
      );

      if (response.ok) {
        const category = await response.json();
        const categoryDetails = category.find((item) => item.id === homeid);
        setEditCategory(categoryDetails);
        setEditShowModal(true);
      } else {
        console.error("Failed to fetch category");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
  };

  const handleUpdate = async () => {
    try {
      const formData = new FormData();
      formData.append("id", editCategory.id);
      formData.append("slider_type", editCategory.slider_type);
      formData.append("slider_name", editCategory.slider_name);
      formData.append("current_image", editCategory.image);
      formData.append("status", editCategory.status);

      if (imageFile) {
        formData.append("image", imageFile);
      }

      const response = await fetch(
        `https://falconsquare.in/e_commerce/homesliderupdate.php?id=${editCategory.id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.status === 200) {
        console.log("Category updated successfully!");
        setHomeslider(
          homeslider.map((category) =>
            category.id === editCategory.id ? editCategory : homeslider
          )
        );
        setEditShowModal(false);
      } else {
        console.error("Failed to update category");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    GetData();
  };

  return (
    <div className="main-container mt-3">
      <div className=" ">
        <div className="flex-container pt-5">
          <h3 className="head-fon-mv">Manage Home Slider Images</h3>
          <div class="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              class="breadcrumb-header float-start float-lg-end"
            >
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a onClick={() => Navigate("/dashboard")}>Dashboard</a>
                </li>{" "}
                <li aria-current="page" class="breadcrumb-item active">
                  Manage Home Slider Images
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div className="card ">
          <div className="cat-left">
            <h4>Home Slider Image</h4>

            <button className="add-category-button" onClick={handleShowModal}>
              Add New
            </button>
          </div>
          <div className="search-left">
            <div className="searchbar">
              <input type="text" className="search" placeholder="Search"  onChange={(e) => setQuery(e.target.value)}/>
              <FaSearch className="search-icon" />
            </div>
          </div>
          <div className="table-container text-end mt-3">
            <table id="table-to-xls" className="text-center">
              <thead>
                <tr>
                  <th style={{ width: "4%" }}>ID</th>
                  <th>Type</th>
                  <th>Name</th>
                  <th>Image</th>
                  <th>Status</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {filter && filter.map((homesliders, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{homesliders.slider_type}</td>
                    <td>{homesliders.slider_name}</td>
                    <td>
                      <img src={`https://falconsquare.in/e_commerce/images/homeslider/${homesliders.slider_image}`} width={40} height={40} />
                    </td>
                    <td>{homesliders.status == "1" ?
                      <span className="active-item">Activated</span> :
                      <span className="inactive-item">Deactivated</span>}
                    </td>
                    <td>
                      <button className="btn-edit" >
                        <FaPen onClick={() => handelEdit(homesliders.id)} />
                      </button>
                    </td>
                    <td>
                      <button className="btn-delete">
                        <FaTrash onClick={() => handleDelete(homesliders.id)} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="p-2">
            <label>Per page</label>
            <select className="form-select">
              <option value="20">20</option>
              <option value="40">40</option>
              <option value="60">60</option>
              <option value="80">80</option>
            </select>
          </div>
          <div className="dataTables_paginate paging_simple_numbers p-3">
            <ul className="pagination justify-content-end">
              <li className="page-item">
                <button className="page-link">Previous</button>
              </li>
              <li className="page-item">
                <button className="page-link"></button>
              </li>
              <li className="page-item">
                <button className="page-link">Next</button>
              </li>
            </ul>
          </div>
        </div>

        {/* insert */}
        <Modal show={showModal} onHide={handleCloseModal}>
          <header class="modal-header">
            <h5 class="modal-title">Add Home Slider Image</h5>
            <button
              type="button"
              aria-label="Close"
              class="close"
              onClick={handleCloseModal}
            >
              ×
            </button>
          </header>
          <Modal.Body>
            <div>
              <label>Name</label>
              <input
                className="form-select"
                placeholder="Enter Name"
                value={slidername}
                onChange={(e) => setSlidername(e.target.value)}
              />
              <label>Type</label>
              <input
                className="form-select"
                placeholder="Enter Type"
                value={slidertype}
                onChange={(e) => setSlidertype(e.target.value)}
              />

              <label>Status</label>
              <div
                role="radiogroup"
                tabIndex="-1"
                className="btn-group-toggle btn-group bv-no-focus-ring"
              >
                <label
                  className={`btn btn-outline-primary ${selectedOption === "activated" ? "active" : ""
                    }`}
                >
                  <input
                    type="radio"
                    value="1"
                    checked={status === "1"}
                    onChange={handleOptionChange}
                  />
                  <span>Activated</span>
                </label>
                <label
                  className={`btn btn-outline-primary ${selectedOption === "0" ? "active" : ""
                    }`}
                >

                  <input
                    type="radio"
                    value="0"
                    checked={status === "0"}
                    onChange={handleOptionChange}
                  />
                  <span>Deactivated</span>
                </label>
              </div>
              <label>Image</label>
              <p>
                Please choose square image of larger than 350px*350px & smaller
                than 550px*550px.
              </p>
              <input
                type="file"
                accept="image/*"
                className="form-control"
                onChange={handleFileChange}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleInsert}>
              Save
            </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>



        {/* update  */}

        <Modal show={EditshowModal} onHide={() => setEditShowModal(false)}>
          <header class="modal-header">
            <h5 class="modal-title">Add Home Slider Image</h5>
            <button
              type="button"
              aria-label="Close"
              class="close"
              onClick={() => setEditShowModal(false)}
            >
              ×
            </button>
          </header>
          <Modal.Body>
            <div>
              <label>Name</label>
              <input
                className="form-select"
                placeholder="Enter Name"
                value={editCategory.slider_name}
                onChange={(e) =>
                  setEditCategory({
                    ...editCategory,
                    slider_name: e.target.value,
                  })
                }
              />
              <label>Type</label>
              <input
                className="form-select"
                placeholder="Enter Type"
                value={editCategory.slider_type}
                onChange={(e) =>
                  setEditCategory({
                    ...editCategory,
                    slider_type: e.target.value,
                  })
                }
              />

              <label>Status</label>
              <div
                role="radiogroup"
                tabIndex="-1"
                className="btn-group-toggle btn-group bv-no-focus-ring"
              >
                <label className={`btn btn-outline-primary ${editCategory.status === "1" ? "active" : ""}`}>
                  <input
                    type="radio"
                    value="1"
                    checked={editCategory.status === "1"}
                    onChange={(e) => {
                      setEditCategory({
                        ...editCategory,
                        status: e.target.value,
                      });
                      setSelectedOption("1"); // Update selectedOption here
                    }}
                  />
                  <span>Activated</span>
                </label>
                <label className={`btn btn-outline-primary ${editCategory.status === "0" ? "active" : ""}`}>
                  <input
                    type="radio"
                    value="0"
                    checked={editCategory.status === "0"}
                    onChange={(e) => {
                      setEditCategory({
                        ...editCategory,
                        status: e.target.value,
                      });
                      setSelectedOption("0"); // Update selectedOption here
                    }}
                  />
                  <span>Deactivated</span>
                </label>
              </div>
              <label>Image</label>
              <p>
                Please choose square image of larger than 350px*350px & smaller
                than 550px*550px.
              </p>

              <label>Image</label>
              <input
                style={{ width: "80%" }}
                type="file"
                name="file2"
                id="myFile2"
                accept=".jpg, .jpeg, .png"
                onChange={(e) => {
                  handleFileInputChange(e)
                }}
              />

              <input value={editCategory.slider_image} type=" hidden " name="current_image" />
              <span id="file-name">{filename}</span>

            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleUpdate}>
              Save
            </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>


      </div>
    </div>
  );
}

export default Managehomeslider;
