import React, { useEffect, useState } from "react";
import "./Orderlist.css";
import Calendar from "react-calendar";
import { FaCalendar } from "react-icons/fa";
import { LuRefreshCcw } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { Button, Modal, ModalBody, ModalTitle } from "react-bootstrap";
import Api from "../../Global/Api";
import Lottie from "react-lottie";
function Orderlist() {
  const Navigate = useNavigate();
  const [orderitems, setOrderitems] = useState();
  const [orders, setOrders] = useState(true);
  const [orderproduct, setOrderproduct] = useState({});
  const [more, setMore] = useState(true);
  const [details, setDetails] = useState(false);
  const [order, setOrder] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [orderdetails, setOrderDetails] = useState({});
  const [productdetails, setProductDetails] = useState([]);
  const [orderAddress, setOrderAddress] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const callApi = new Api();

  const handleOrders = () => {
    setOrderitems(true);
    setOrders(false);
  };
  const handleClick = () => {
    setOrders(true);
    setOrderitems(false);
  };
  const handleMore = (oid, uid, aid) => {
    console.log(oid);
    setMore(false);
    setDetails(true);
    callApi
      .adminSingleOrder({
        order_id: oid,
        user_id: uid,
        address_id: aid,
      })
      .then((order) => {
        console.log(order.product_details);
        setOrderDetails(order.order_details);
        setProductDetails(order.product_details);
        setOrderAddress();
      });
  };
  const handleCancel = () => {
    setDetails(false);
    setMore(true);
    // callApi.adminSingleOrder({

    // })
  };
  const handleCancels = () => {
    setMore(true);
    setDetails(false);
  };
  const handleSelect = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue === "New Order") {
      handleOrder();
    }
    // Handle other options if needed
  };

  useEffect(() => {
    // Fetch order list data
    callApi
      .adminOrderList({})
      .then((res) => {
        setOrderList(res);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching order list:", error);
        setLoading(false);
      });
  }, []);

  const handleOrder = () => {
    setOrder(true);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredOrders = orderList.filter((order) =>
    order.user_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Pagination logic
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = filteredOrders.slice(startIndex, endIndex);
  const totalPages = Math.ceil(filteredOrders.length / itemsPerPage);

  const handlePaginationClick = (page) => {
    setCurrentPage(page);
  };

  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      paginationNumbers.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
        >
          <Button
            variant="link"
            className="page-link"
            onClick={() => handlePaginationClick(i)}
          >
            {i}
          </Button>
        </li>
      );
    }
    return paginationNumbers;
  };

  return (
    <div className="main-container_1">
      {more && (
        <div>
          <div className="Order-head">
            <h3 className="head-fon-mv">Order List</h3>
            <div class="col-12 col-md-6 order-md-2 order-first">
              <nav
                aria-label="breadcrumb"
                class="breadcrumb-header float-start float-lg-end"
              >
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a onClick={() => Navigate("/dashboard")}>Dashboard</a>
                  </li>{" "}
                  <li aria-current="page" class="breadcrumb-item active">
                    Order List
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="card">
            <div className="latest">
              <h5>Latest Orders </h5>
            </div>
            <div className="l_3 ">
              <div className="l_3_1">
                <h6> Date</h6>

                <div className="clear_btn"></div>
              </div>

              <div className="l_3_1">
                <h6>Categories</h6>
                <select className="form-select">
                  <option>Categories</option>
                  <option>All</option>
                </select>
              </div>

              <div className="l_3_1">
                <h6>Status</h6>
                <select className="form-select" onChange={handleSelect}>
                  <option>All Status</option>
                  <option value="New Order">New Order</option>\
                  <option>Packing</option>
                  <option>Out for delivery</option>
                  <option>Delivered</option>
                  <option>Cancelled</option>
                </select>
              </div>
              <div className="l_3_1">
                <h6>Search</h6>
                <input
                  type="text"
                  placeholder="Search"
                  className="Search"
                  onChange={handleSearch}
                />
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="row">
                <div className="d-flex align-items-center">
                  <label className="me-2">Show</label>
                  <select
                    className="form-select me-2"
                    value={itemsPerPage}
                    onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
                  >
                    <option value="20">20</option>
                    <option value="40">40</option>
                    <option value="60">60</option>
                    <option value="80">80</option>
                  </select>
                  <label>entries</label>
                </div>
              </div>
            </div>
            <div className="od_items">
              <button
                className={orders ? "o_btn" : "oo_btn"}
                onClick={handleClick}
              >
                PENDING
              </button>
              <button
                className={orderitems ? "o_btn" : "oo_btn"}
                onClick={handleOrders}
              >
                DELIVERED
              </button>
            </div>

            {orders && (
              <div className="table-container text-end">
                {loading ? (
                  <div>
                    <Lottie
                      height={250}
                      width={250}
                      options={{
                        loop: true,
                        animationData: require("../Assets/Animation - 1716197548567.json"),
                        autoplay: true,
                      }}
                    />
                  </div>
                ) : (
                  <table id="table-to-xls">
                    <thead className="thead-light thead-50 text-capitalize">
                      <tr>
                        <th>S.No</th>
                        <th>Order No</th>
                        <th>Name</th>
                        <th>Mobile No</th>
                        <th>Address</th>
                        <th>Price</th>
                        <th>Status</th>
                        <th>More Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentPageData.map((order, index) => (
                        <tr key={order.order_id}>
                          <td>{startIndex + index + 1}</td>
                          <td>{order.order_id}</td>
                          <td>{order.user_name}</td>
                          <td>{order.user_phone}</td>
                          <td>{order.address_details}.</td>
                          <td>{order.total_price}</td>
                          <td>{order.order_status}</td>
                          <td>
                            <Button
                              variant="primary"
                              onClick={() =>
                                handleMore(
                                  order.order_id,
                                  order.user_id,
                                  order.address_id
                                )
                              }
                            >
                              More
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            )}
            <div className="pagination-container">
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <Button
                      variant="link"
                      className="page-link"
                      onClick={() => handlePaginationClick(currentPage - 1)}
                    >
                      Previous
                    </Button>
                  </li>
                  {generatePaginationNumbers()}
                  <li
                    className={`page-item ${
                      currentPage === totalPages ? "disabled" : ""
                    }`}
                  >
                    <Button
                      variant="link"
                      className="page-link"
                      onClick={() => handlePaginationClick(currentPage + 1)}
                    >
                      Next
                    </Button>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="table-container text-end mt-3">
              <table id="table-to-xls" className="text-center">
                <thead>
                  <th> O.ID</th>
                  <th>order_id </th>
                  <th>product_name</th>
                  <th>product_qty</th>
                  <th>product_amt</th>
                  <th>product_tax</th>
                  <th>product_discount</th>
                  <th>size</th>
                  <th>color</th>
                  <th>product_total</th>
                </thead>
                <tbody>
                  {/* <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{order.order_id}</td>
                          <td>{order.product_name}</td>
                          <td>{order.product_qty}</td>
                          <td>{order.product_amt}</td>
                          <td>{order.product_tax}</td>
                          <td>{order.product_discount}</td>
                          <td>{order.size}</td>
                          <td>{order.color}</td>
                          <td>{order.product_total}</td>
                        </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      {details && (
        <div className="order-1">
          <div className="order-10">
            <h4>Order#10</h4>

            <Button variant="secondary" onClick={handleCancels}>
              Cancel
            </Button>
          </div>

          <div>
            <div className="main-cd">
              <div className="card-dash-21">
                <div className="it-summary">
                  <h6 className="sum-per">S.No</h6>
                  <h6 className="sum-per-1">Item Id</h6>
                  <h6 className="sum-per-1">Item Name</h6>
                  <h6 className="sum-per-1">Item Image</h6>
                  <h6 className="sum-per-11">Item Summary</h6>
                  <h6 className="sum-per">QTY</h6>
                  <h6 className="sum-per">Price</h6>
                  <h6 className="sum-per">Tax</h6>
                  <h6 className="sum-per">Total Price</h6>
                </div>
                {productdetails.map((item, index) => (
                  <div className="it-summary">
                    <p className="sum-per">
                      {(index + 1).toString().padStart(2, "0")}
                    </p>
                    <p className="sum-per-1">{item.ProductId}</p>
                    <p className="sum-per-1">{item.ProductName}</p>
                    <p className="sum-per-1">ram</p>
                    <p className="sum-per-11">ram</p>
                    <p className="sum-per">{item.ProductQty}</p>
                    <p className="sum-per">{item.ProductAmt}</p>
                    <p className="sum-per">{item.Producttax}</p>
                    <p className="sum-per">
                      {parseInt(item.ProductQty) * parseInt(item.ProductAmt)}
                    </p>
                  </div>
                ))}
              </div>
              <div className="card-dash-222">
                <div className="phone-1">
                  <h6>Order Summary</h6>
                  <h6>On the Way</h6>
                </div>
                <div className="phone-1">
                  <h6>Order Created</h6>
                  <h6>On the Way</h6>
                </div>
                <div className="phone-1">
                  <h6>Order Time</h6>
                  <h6>On the Way</h6>
                </div>
                <div className="phone-1">
                  <h6>Subtotal</h6>
                  <h6>On the Way</h6>
                </div>
                <div className="phone-1">
                  <h6>Delivery Fee</h6>
                  <h6>On the Way</h6>
                </div>
                <div className="phone-1">
                  <h6>Total</h6>
                  <h6>100</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="main-cd">
            <div className="card-dash-22">
              <div className="cao">
                <h6>Customer And Order Details</h6>
              </div>
              <div className="phone">
                <h6>Cutomer Name</h6>
                <p>{orderdetails.user_name}</p>
              </div>
              <div className="phone">
                <h6>Phone Number</h6>
                <p>{orderdetails.user_phone}</p>
              </div>
              <div className="phone">
                <h6>Bag Option</h6>
                <p>ram</p>
              </div>
              <div className="phone">
                <h6>Type</h6>
                <p>ram</p>
              </div>
              <div className="phone">
                <h6>Note</h6>
                <p>ram</p>
              </div>
            </div>
            <div className="card-dash-222">
              <div className="phone-1">
                <h6>Delivery Address</h6>
              </div>
              <div className="phone-1">
                <h6>Address Line</h6>
                <h6>On the Way</h6>
              </div>
              <div className="phone-1">
                <h6>Flat /Building Name</h6>
                <h6>On the Way</h6>
              </div>
              <div className="phone-1">
                <h6>Street Name</h6>
                <h6>On the Way</h6>
              </div>
              <div className="phone-1">
                <h6>Postcode</h6>
                <h6>On the Way</h6>
              </div>
            </div>
          </div>

          {order && (
            <div className="Acc-reject">
              <Button variant="success" onClick={handleCancel}>
                Accept
              </Button>
              <Button variant="danger">Reject</Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Orderlist;
