import "./Manageproduct.css";
import React, { useEffect, useState } from "react";

import Calendar from "react-calendar";
import { FaCalendar } from "react-icons/fa";
import { LuRefreshCcw } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import Lottie from "react-lottie";
function Manageproduct() {
  const [orderitems, setOrderitems] = useState();
  const [orders, setOrders] = useState(true);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const handleOrders = () => {
    setOrderitems(true);
    setOrders(false);
  };

  useEffect(() => {
    // Fetch data from the provided link
    fetch("https://falconsquare.in/e_commerce/product.php")
      .then((response) => response.json())
      .then((data) => {
        setProducts(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);
  const handleClick = () => {
    setOrders(true);
    setOrderitems(false);
  };
  useEffect(() => {
    // Filter products based on search query
    const filtered = products.filter((product) =>
      product.product_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredProducts(filtered);
  }, [searchQuery, products]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="main-container_1">
      <div className="Order-head">
        <h3 className="head-fon-mv">Manage Products</h3>
        <div class="col-12 col-md-6 order-md-2 order-first">
          <nav
            aria-label="breadcrumb"
            class="breadcrumb-header float-start float-lg-end"
          >
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a>Dashboard / Manage Products</a>
              </li>{" "}
            </ol>
          </nav>
        </div>
      </div>
      <div className="card">
        <div className="cat-left">
          <h4>Products</h4>

          {/* <button className="add-category-button">Add Product</button> */}
        </div>
        <div className="l_3 ">
          <div className="l_3_1">
            <h6>Filter by Products Category</h6>
            <select className="form-select">
              <option>Categories</option>
              <option>All</option>
            </select>
          </div>

          <div className="l_3_1">
            <h6>Filter Products by Status</h6>
            <select className="form-select">
              <option>Select Status</option>
              <option>All</option>
            </select>
          </div>

          <div className="l_3_1">
            <h6>Search</h6>
            <input
              type="text"
              placeholder="Search"
              className="Search"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
        </div>

        {orders && (
          <div className="table-container text-end">
            {loading ? (
              <div>
                <Lottie
                  height={250}
                  width={250}
                  options={{
                    loop: true,
                    animationData: require("../../Assets/Animation - 1716197548567.json"),
                    autoplay: true,
                  }}
                />
              </div>
            ) : (
              <table id="table-to-xls" className="text-center">
                <thead>
                  <tr>
                    <th>S.no</th>
                    <th>product name</th>
                    <th>product code</th>
                    <th>product price</th>
                    <th>product stock</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredProducts.map((product, index) => (
                    <tr key={product.id}>
                      <td>{index + 1}</td>
                      <td>
                        {" "}
                        <img
                          src={`https://falconsquare.in/e_commerce/image/product/${product.product_image}`}
                          width={40}
                          height={40}
                          style={{ margin: "10px" }}
                          alt={product.category_name}
                        />
                        {product.product_name}
                      </td>
                      <td>{product.product_code}</td>
                      <td>{product.pro_price}</td>
                      <td>{product.product_stock}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}
        {orderitems && (
          <div className="table-container text-end mt-3">
            <table id="table-to-xls" className="text-center">
              <thead>
                <th> O.ID</th>
                <th>O.Item ID </th>
                <th>Commission</th>
                <th>Product</th>
                <th>Mob</th>
                <th>Total($)</th>
                <th>P.Method</th>
                <th>D.Time</th>
                <th>A.Status</th>
                <th>Actions</th>
              </thead>
              <tbody>
                <td>d</td>
                <td>d</td>
                <td>d</td>
                <td>d</td>
                <td>d</td>
                <td>d</td>
                <td>d</td>
                <td>d</td>
                <td>d</td>
                <td>d</td>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default Manageproduct;
