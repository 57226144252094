import React, { useState,useRef, useEffect } from "react";
import Lottie from "react-lottie";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaSearch, FaTrash } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import swal from "sweetalert";

function Includedcomponents() {
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [includedComponents, setIncludedComponents] = useState([]);
  const [newIncludedComponent, setNewIncludedComponent] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [editIncludedComponent, setEditIncludedComponent] = useState({
    id: "",
    included_components: "",
  });
  const [editShowModal, setEditShowModal] = useState(false);
  const includedcomponentsNameInputRef = useRef(null);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setNewIncludedComponent("");
  };

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://falconsquare.in/e_commerce/includedcomponents.php"
      );
      const data = await response.json();
      setIncludedComponents(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (showModal) {
      includedcomponentsNameInputRef.current.focus();
    }
  }, [showModal]);

  const handleInsert = async () => {
    if (!newIncludedComponent.trim()) {
      swal("warning", "Please enter included components", "warning");
      return;
    }
    const formData = new FormData();
    formData.append("included_components", newIncludedComponent);

    try {
      const response = await fetch(
        "https://falconsquare.in/e_commerce/includedcomponents.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        fetchData(); // Refresh the data
        handleCloseModal(); // Close the modal
      } else {
        console.error("Failed to insert data");
      }
    } catch (error) {
      console.error("Error inserting data:", error);
    }
  };

  const handleEdit = (componentId) => {
    const componentToEdit = includedComponents.find(
      (item) => item.id === componentId
    );
    setEditIncludedComponent(componentToEdit);
    setEditShowModal(true);
  };

  const handleUpdate = async () => {
    try {
      const response = await fetch(
        `https://falconsquare.in/e_commerce/includedcomponents.php?id=${editIncludedComponent.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(editIncludedComponent),
        }
      );

      if (response.ok) {
        fetchData(); // Refresh the data
        setEditShowModal(false);
      } else {
        console.error("Failed to update data");
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this component!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });

      if (shouldDelete) {
        const response = await fetch(
          `https://falconsquare.in/e_commerce/includedcomponents.php?id=${id}`,
          {
            method: "DELETE",
          }
        );

        if (response.ok) {
          fetchData(); // Refresh the data after deleting
        } else {
          console.error("Failed to delete component");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const filteredComponents = includedComponents.filter((component) =>
    component.included_components
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  const sortedFilter = [...filteredComponents].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };

  return (
    <div className="main-container mt-3">
      <div className="flex-container pt-5">
        <h3 className="head-fon-mv">Included Components</h3>
      </div>

      <div className="card">
        <div className="cat-left">
          <h4>Included Components</h4>
          <button className="add-category-button" onClick={handleShowModal}>
            Add Included Components
          </button>
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <div className="row">
            <div className="d-flex align-items-center">
              <label className="me-2">Show</label>
              <select
                className="form-select me-2"
                value={itemsPerPage}
                onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
              >
                <option value="20">20</option>
                <option value="40">40</option>
                <option value="60">60</option>
                <option value="80">80</option>
              </select>
              <label>entries</label>
            </div>
          </div>
          <div className="search-left">
            <div className="searchbar">
              <input
                type="text"
                className="search"
                placeholder="Search"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <FaSearch className="search-icon" />
            </div>
          </div>
        </div>

        <div className="table-container text-end mt-3">
          {loading ? (
            <div>
              <Lottie
                height={250}
                width={250}
                options={{
                  loop: true,
                  animationData: require("../Assets/Animation - 1716197548567.json"),
                  autoplay: true,
                }}
              />
            </div>
          ) : (
            <table id="table-to-xls" className="text-center">
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Included Components</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPageData.map((component, index) => (
                    <tr key={component.id}>
                       <td>{startIndex + index + 1}</td>
                      <td>{component.included_components}</td>
                      <td>
                        <button
                          className="btn-edit"
                          onClick={() => handleEdit(component.id)}
                        >
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </button>
                        <button
                          className="btn-delete"
                          onClick={() => handleDelete(component.id)}
                        >
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
          )}
        </div>

        <Modal show={showModal} onHide={handleCloseModal}>
          <header className="modal-header">
            <h5 className="modal-title">Add Included Components</h5>
            <button
              type="button"
              aria-label="Close"
              className="close"
              onClick={handleCloseModal}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <div>
              <label>Included Components</label>
              <input
                className="form-control"
                placeholder="Enter Included Components"
                value={newIncludedComponent}
                ref={includedcomponentsNameInputRef}
                onChange={(e) => setNewIncludedComponent(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleInsert}>
              Save
            </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={editShowModal} onHide={() => setEditShowModal(false)}>
          <header className="modal-header">
            <h5 className="modal-title">Edit Included Components</h5>
            <button
              type="button"
              aria-label="Close"
              className="close"
              onClick={() => setEditShowModal(false)}
            >
              ×
            </button>
          </header>
          <Modal.Body>
            <div>
              <label>Included Components</label>
              <input
                className="form-control"
                placeholder="Enter Included Components"
                value={editIncludedComponent.included_components}
                onChange={(e) =>
                  setEditIncludedComponent({
                    ...editIncludedComponent,
                    included_components: e.target.value,
                  })
                }
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
              <Button variant="success" onClick={handleUpdate}>
                Update
              </Button>
              <Button
                variant="secondary"
                onClick={() => setEditShowModal(false)}
              >
                Cancel
              </Button>
            </Modal.Footer>
        </Modal>

        
      </div>
      <div
          className="dataTables_paginate paging_simple_numbers p-2"
          id="dtBasicExample_paginate"
        >
          <ul className="pagination">
            <li
              className={`paginate_button page-item ${
                currentPage === 1 ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage > 1 ? prevPage - 1 : prevPage
                  )
                }
                className="page-link"
              >
                Previous
              </button>
            </li>
            {generatePaginationNumbers().map((page, index) => (
              <li
                key={index}
                className={`paginate_button page-item ${
                  page === currentPage ? "active" : ""
                }`}
              >
                <button
                  onClick={() => setCurrentPage(page)}
                  className="page-link"
                >
                  {page}
                </button>
              </li>
            ))}
            <li
              className={`paginate_button page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage < totalPages ? prevPage + 1 : prevPage
                  )
                }
                className="page-link"
              >
                Next
              </button>
            </li>
          </ul>
        </div>
    </div>
  );
}

export default Includedcomponents;
